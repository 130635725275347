import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import { QuizIcons } from "../../../utilities/Icons/Icons";
import "../../styles/CourseLibrary/SingleCourse.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import CustomAccordions from "../Timeline/CustomAccordions";
import { IP } from "../../../config";
import axios from "axios";

function SingleCourse() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const location = useLocation();
  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const fetchCourseCertificate = httpsCallable(
    functions,
    "fetchCourseCertificate"
  );
  // userEnrollment
  const [courseInfo, setCourseInfo] = useState(null);
  const [progress, setProgress] = useState(null);
  const [userDueDate, setUserDueDate] = useState("");
  const [certificateInfo, setCertificateInfo] = useState(null);
  const courseId = location.state?.courseId;
  //console.log(courseId);
  const { clrs } = useSelector((state) => state.createClr);
  const [courseQuiz, setCourseQuiz] = useState([]);
  const { t } = useTranslation();
  const [loadingButt, setLoadingButt] = useState(false);

  const [flagForComplitedCourse, setFlagForComplitedCourse] = useState("");
  const enrollUser = (data) => {
    //if user is already enrolled then redirect to course content page
    if (user_courses?.archived) {
      alert("The course is closed For you. Please contact your Admin!");
      return;
    }
    if (user_courses) {
      navigate(`single-course-content/${courseId}`, {
        state: {
          courseStatus: data == "Completed" ? true : false,
          certificateInfo: certificateInfo?.certificateDownloadURL,
        },
      });
      return;
    }
    //if user is not enrolled then enroll user
    if (courseInfo?.extraInfo.accessMode != "free") {
      alert("The course is closed. Please contact your Admin!");
      return;
    }

    if (data == "start") {
      navigate(`single-course-content/${courseId}`);
      return;
    }
    let dueDate = new Date(
      Number(new Date()) +
        1000 * 60 * 60 * 24 * courseInfo.extraInfo.courseExpirationDays
    );

    let CourseInfo = {
      method: "post",
      // params: { courseId: courseId },
      url: `${IP}/userEnrollment`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        startDate: new Date().toString(),
        dueDate: dueDate.toString(),
        courseId: courseId,
        courseName: courseInfo.courseName,
        trainingProvider: courseInfo.extraInfo.trainingProvider,
      }),
    };

    axios(CourseInfo);
    // userEnrollment({

    // });
    alert("Enrolled");
    navigate(`single-course-content/${courseId}`);
  };

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);

  const params = useParams();
  //console.log(params);
  async function fetchData() {
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userCourseDetails/course`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
      }),
    };

    const result = await axios(CourseInfo);

    //console.log(result.data);
    setCourseInfo(result.data);
    let dueDate = new Date(
      Number(new Date()) +
        1000 * 60 * 60 * 24 * result.data.extraInfo.courseExpirationDays
    );
    //console.log("due date", dueDate);
    setUserDueDate(dueDate.toDateString());
    if (result.data.extraInfo.quizzes) {
      result.data.extraInfo.quizzes.forEach(async (quizId) => {
        //console.log(quizId);

        let CourseInfo = {
          method: "get",
          params: { quizId: quizId },
          url: `${IP}/userCourseDetails/quiz`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "",
        };

        // const result = await axios(CourseInfo);
        const quizInfo = await axios(CourseInfo);
        setCourseQuiz((prev) => prev.concat(quizInfo.data));
      });
    }
  }
  useState(() => {
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userItemDetails/nodeProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => {
      setProgress(result.data);
      //console.log(result.data)
    });

    let CourseCertificate = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userCourse/courseCertificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseCertificate).then((result) => {
      if (result.data.error) {
        return;
      }
      setCertificateInfo(result.data);
    });
    fetchData();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState();

  useEffect(() => {
    let InternalTraining = {
      method: "get",
      params: { select: 1 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    setLoadingButt(true);
    axios(InternalTraining)
      .then((res) => {
        //console.log(res.data);
        // setUserCourses([...res.data]);
        const temp = res.data.find((course) => course.courseId == courseId);
        //console.log(temp);
        setLoadingButt(false);

        setUserCourses(temp);
      })
      .catch((err) => {
        setLoadingButt(false);

        //console.log(err);
      });
  }, []);
  // //console.log(user_courses);

  // imediate function
  const emiditaeFunc = () => {
    if (user_courses) {
      if (user_courses.archived == true) {
        return t("Closed");
      }
      let res;
      user_courses.progress.completed == 0
        ? (res = t("Start"))
        : user_courses?.progress.completed == user_courses?.progress.total
        ? (res = t("Completed"))
        : (res = t("Continue"));

      return res;
    } else if (courseInfo?.extraInfo.accessMode != "free") {
      return t("Closed");
    } else {
      return t("Enroll");
    }
  };

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("Course Overview")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("Course Overview")} />
      </Box>
      <Container sx={{ py: 2 }}>
        <Box sx={{ p: 3, background: "#fff", width: "100%" }}>
          {courseInfo ? (
            <Box sx={{ width: { xs: "100%", md: "80%" } }}>
              <Box>
                <Typography
                  sx={{ fontSize: "22px", fontWeight: 600, color: "#00495F" }}
                >
                  {courseInfo.courseName}
                </Typography>
                <Box sx={{ width: "100%", mt: "25px" }}>
                  <img
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      height: "250px",
                      objectFit: "cover",
                      boxShadow: "0px 20px 40px rgba(0, 69, 119, 0.2)",
                    }}
                    src={courseInfo.courseImageURL}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: 500,
                    mt: "20px",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  sx={{ color: "#00495F", fontWeight: 400, fontSize: "16px" }}
                >
                  {courseInfo.courseDescription}
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: "30px",
                  }}
                >
                  {user_courses?.finalStatus == "fail" ? (
                    <Button
                      sx={{
                        color: "#fff",
                        padding: { xs: "10px 20px", md: "10px 40px" },
                        background: (theme) => theme.palette.primary.btn,
                        borderRadius: "5px",
                        fontWeight: 600,
                        "&:hover": {
                          background: (theme) => theme.palette.primary.btn,
                        },
                      }}
                      onClick={() => {
                        enrollUser(emiditaeFunc(user_courses));
                      }}
                    >
                      Retry
                    </Button>
                  ) : (
                    <Button
                      disabled={loadingButt}
                      sx={{
                        color: "#fff",
                        padding: { xs: "10px 20px", md: "10px 40px" },
                        background: (theme) => theme.palette.primary.btn,
                        borderRadius: "5px",
                        fontWeight: 600,
                        "&:hover": {
                          background: (theme) => theme.palette.primary.btn,
                        },
                      }}
                      onClick={() => {
                        enrollUser(emiditaeFunc(user_courses));
                      }}
                    >
                      {/* {user_courses ? t("continue") : t("Enroll")} */}

                      {loadingButt ? "Loading..." : emiditaeFunc(user_courses)}
                    </Button>
                  )}

                  <Box sx={{ width: { xs: "50%", md: "auto" } }}>
                    <Typography sx={{ color: "#000" }}>
                      Due Date -{" "}
                      {(() => {
                        if (user_courses?.dueDate) {
                          let dueDate = new Date(
                            user_courses.dueDate._seconds * 1000
                          );
                          //console.log(user_courses.dueDate._seconds)
                          return dueDate.toDateString();
                        } else {
                          return userDueDate;
                        }
                      })()}
                    </Typography>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "250px" },
                        height: "10px",
                        border: "1px solid #003A69",
                        display: "flex",
                        alignItems: "center",
                        m: "5px 0px",
                      }}
                    >
                      <Box
                        sx={{
                          width: progress
                            ? Math.ceil(
                                (progress.completed / progress.total) * 100
                              ) + "%"
                            : "0%",
                          height: "4px",
                          background: "#003A69",
                          ml: "1 px",
                        }}
                      ></Box>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {progress?.total
                        ? Math.ceil((progress.completed / progress.total) * 100)
                        : "0"}
                      % Completed
                    </Typography>
                    {certificateInfo && (
                      <Box
                        sx={{
                          fontSize: "12px",
                          color: "#000",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          //console.log(certificateInfo.certificateDownloadURL);
                          window.open(
                            certificateInfo.certificateDownloadURL,
                            "_blank"
                          );
                        }}
                      >
                        Download Certificate
                      </Box>
                    )}

                    {user_courses?.finalStatus == "fail" && (
                      <Box
                        sx={{
                          fontSize: "12px",
                          color: "#000",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          enrollUser(emiditaeFunc(user_courses));
                        }}
                      >
                        You failed in final Quiz
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: "50px" }}>
                <Typography
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "8px 30px",
                    background: (theme) => theme.palette.primary.main,
                    borderRadius: "5px 5px 0px 0px",
                    fontWeight: 600,
                    display: "inline-block",
                  }}
                >
                  Lessons
                </Typography>
                {courseInfo.extraInfo && (
                  <CustomAccordions
                    courseId={courseId}
                    lessons={courseInfo.extraInfo.lessons}
                  />
                )}
              </Box>

              {courseQuiz &&
                courseQuiz.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #003A69",
                      p: "10px 15px",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <Box>
                      <QuizIcons />
                    </Box>
                    <Typography
                      sx={{
                        ml: "15px",
                        fontSize: "18px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      {item.quizName}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ) : (
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default SingleCourse;
