import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import { EnglishIcons } from "../../../utilities/Icons/Icons";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import axios from "axios";
import { IP } from "../../../config";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CertificatesMain() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  //===========fetch all certificates==============//
  const [certificates, setCertificates] = useState([]);
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const certificateDownloadUserNotification = httpsCallable(
    functions,
    "certificateDownloadUserNotification"
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setLoading(true);
    let certificates = {
      method: "get",
      // params: { select: 3 },
      url: `${IP}/userCourse/certificates`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(certificates)
      .then((res) => {
        //console.log("ceertificates: ", res);
        if (res.data.error) {
          setLoading(false);

          setError(true);
          return;
        }
        setError(false);

        console.log(res.data);
        setCertificates([...res.data]);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  const notiAndemail = (certificate) => {
    let certificates = {
      method: "post",
      // params: { select: 3 },
      url: `${IP}/mail/sendMailToDownloadCertificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseName: certificate.certificateName,
        downloadLink: certificate.certificateDownloadURL,
      }),
    };
    axios(certificates);
    // certificateDownloadUserNotification({
    //   certificateId: id,
    //   uid: getCookie("UID"),
    // });
  };
  //console.log(Date.now())
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("certificate")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("certificate")} />
      </Box>
      <Box sx={{ flexGrow: 1, my: 5 }}>
        {error && (
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              color: "#000",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            {" "}
            No certificate{" "}
          </Box>
        )}
        <Container>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {certificates?.map((certificate, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <Item
                    sx={{
                      boxShadow:
                        "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                      borderRadius: "10px",
                      m: "none",
                      background: "#fff",
                      borderRadius: "10px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        right: 0,
                        top: 0,
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "8px", color: "#F18040" }}>
                        {certificate.trainingType + " Training"}
                      </Typography>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#F18040",
                          ml: 1,
                        }}
                      ></Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: (theme) => theme.palette.primary.main,
                        }}
                      >
                        <EnglishIcons />
                      </Box>
                      <Box sx={{ textAlign: "left", ml: "15px" }}>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          {certificate.certificateName}
                        </Typography>

                        {new Date(
                          certificate.expiry._seconds * 1000
                        ).getTime() >= new Date().getTime() ? (
                          <Typography sx={{ color: "green", fontSize: "12px" }}>
                            {" "}
                            Active{" "}
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            Expired
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
                      <Button
                        href={certificate.certificateDownloadURL}
                        target="_blank"
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          borderRadius: "5px",
                          fontWeight: 600,
                          background: (theme) => theme.palette.primary.btn,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.btn,
                          },
                        }}
                        onClick={() => {
                          notiAndemail(certificate);
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </Container>

        {loading && (
          <Box
            container
            spacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 10, alignItems: "center" }}
          >
            <div className="admin-overdue-bottom-table-cnt-c ">
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td">
                <div className="spinner-container">
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "10px solid #f3f3f3" /* Light grey */,
                      borderTop: "10px solid #000" /* Black */,
                      borderRadius: "50%",
                      animation: "spinner 1.5s linear infinite",
                      marginBottom: "200px",
                      marginTop: "120px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CertificatesMain;
