import React, { createContext, useState } from 'react';

export const DrawerContext = createContext();

const DrawerProvider = ({ children }) => {
    const [hidepage, setHidepage] = useState(true)
    
    return (
        <DrawerContext.Provider value={{ hidepage, setHidepage }}>
            {children}
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;