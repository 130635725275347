import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import { functions } from "../../../Firebase";
import "./CertificateDetails.css";
import { setDefaultNamespace } from "i18next";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
//import axios from 'axios';

function Certifictedetails() {
  const location = useLocation();
  const { clrs } = useSelector((state) => state.createClr);

  const data = location.state;

  const fetchCertificateDetails = httpsCallable(
    functions,
    "fetchCertificateDetails"
  );
  const editCertificate = httpsCallable(functions, "editCertificate");
  const previewCertificate = httpsCallable(functions, "previewCertificate");

  const [defname, setdefname] = useState("");
  const [defcourse, setdefcourse] = useState("");
  const [defdate, setdefdate] = useState("");
  const [url, seturl] = useState(data.templateUrl);
  const [defemployee, setdefemployee] = useState("");
  const [defpass, setDefpass] = useState(0);
  const [defuserscore, setdefuserscore] = useState(0);

  const [tempvalid, setTempValid] = useState("");
  const [instructor, setInstructor] = useState("");
  const [templateName, settemplateName] = useState("");
  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const [base64Image, setBase64Image] = useState("");

  const [username, setusername] = useState({
    y: 0,
    X: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [usercourse, setusercourse] = useState({
    y: 0,
    X: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [valid, setvalid] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [instuctorFiled, setInsturctorFiled] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });
  const [date, setdate] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [passing, setPassing] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [userscore, setUserscore] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [employee, setEmployee] = useState({
    y: 0,
    x: 0,
    color: "",
    font: "",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [sign, setsign] = useState({
    y: 0,
    x: 0,
    width: 0,
    height: 0,
    aspectRatio: true,
  });

  let name, value;
  const getuserdata = (e) => {
    // name = e.target.name;
    // if (name == "y" || name == "fontsize") {
    //   value = +e.target.value;
    // } else if (name == "bold") {
    //   if (e.target.value == "true") {
    //     value = true;
    //   } else {
    //     value = false;
    //   }
    // } else {
    //   value = e.target.value;
    // }
    // //console.log(value);
    // setusername({ ...username, [name]: value });

    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setusername({ ...username, [name]: value });
  };

  const getcoursedata = (e) => {
    // name = e.target.name;
    // if (name == "y" || name == "fontsize") {
    //   value = +e.target.value;
    // } else if (name == "bold") {
    //   if (e.target.value == "true") {
    //     value = true;
    //   } else {
    //     value = false;
    //   }
    // } else {
    //   value = e.target.value;
    // }
    // setusercourse({ ...usercourse, [name]: value });

    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setusercourse({ ...usercourse, [name]: value });
  };

  const getdatedata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setdate({ ...date, [name]: value });
  };

  const getvalidyear = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setvalid({ ...valid, [name]: value });
  };

  const getInstuctor = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setInsturctorFiled({ ...instuctorFiled, [name]: value });
  };

  const getpassingdata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setPassing({ ...passing, [name]: value });
  };

  const getuserscoredata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setUserscore({ ...userscore, [name]: value });
  };
  const getEmployeeData = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setEmployee({ ...employee, [name]: value });
  };

  const getsigndata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "width" || name == "height") {
      value = +e.target.value;
    } else {
      value = e.target.value;
    }
    setsign({ ...sign, [name]: value });
  };

  const handleFileChange = (e) => {
    console.log(e);

    let filenameType = e.target.value.split(".");
    settemplateName(e.target.files[0].name.split(".pdf")[0]);
    console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "png") {
    } else {
      alert("only png  file accepted");
      e.target.value = null;

      return;
    }

    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      ////console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const previewcertificate = () => {
    //  //console.log(data.templateUrl,username,usercourse,date,sign,defname,defcourse,defdate,base64Image,defemployee,employee)

    let configCertificate = {
      method: "patch",
      // params: { userId: uid },
      url: `${IP}/certificate/trainingCertificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        tempUrl: data.templateUrl,
        tempUser: username,
        tempCourse: usercourse,
        tempDate: date,
        tempSign: sign,
        userName: defname,
        courseName: defcourse,
        completeDate: defdate,
        base64Image: base64Image,
        enrollmentNo: defemployee,

        teminstructor: instructor,
        instructor: instuctorFiled,

        tempvalid: tempvalid,
        valid: valid,

        tempuserscore: userscore,
        temppassing: passing,
        userscore: defuserscore,
        passing: defpass,
        tempEnroll: employee,
      }),
    };
    axios(configCertificate).then((res) => {
      //console.log("url :  ", res.data);
      const url = res.data;
      seturl("hfjsdjh");
      seturl(url);
    });
  };

  const savechanges = () => {
    let configCertificate = {
      method: "patch",
      // params: { userId: uid },
      url: `${IP}/certificate/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        tempuid: data.id,
        user: username,
        course: usercourse,
        date: date,
        sign: sign,
        tempEnroll: employee,
        userscore: userscore,
        passing: passing,

        instuctorName: instuctorFiled,

        valid: valid,
      }),
    };
    axios(configCertificate).then((res) => {
      //console.log(res);
      alert(res.data.msg);
    });
  };

  const fetchdata = () => {
    let configCertificate = {
      method: "get",
      params: { tempuid: data.id },
      url: `${IP}/certificate/trainingCertificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configCertificate)
      .then((res) => {
        console.log("res", res.data);
        setusername(res.data.data.username);
        setusercourse(res.data.data.course);
        setdate(res.data.data.completeDate);
        setEmployee(res.data.data.employeeNo);
        setsign(res.data.data.signImage);
        setvalid(res.data.data.valid);

        setInsturctorFiled(res.data.data.instuctorName);

        setPassing(res.data.data.passing);

        setUserscore(res.data.data.userscore);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company and Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-details-container">
              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {data.templateName}
                </h1>
              </div>

              <div className="user-data-bigger-container">Enter Users Data</div>

              {/* <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      User__Name__ :
                    </spam>
                    <input
                      type="text"
                      name="username"
                      placeholder=""
                      className="user-data-input"
                      value={defname}
                      onChange={(e) => {
                        setdefname(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Date_Of_Completion :
                    </spam>
                    <input
                      type="date"
                      name="completiondate"
                      placeholder=""
                      className="user-data-input"
                      value={defdate}
                      onChange={(e) => {
                        setdefdate(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Course_Name :
                    </spam>
                    <input
                      type="text"
                      name="coursename"
                      placeholder=""
                      className="user-data-input"
                      value={defcourse}
                      onChange={(e) => {
                        setdefcourse(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Employee Number:
                    </spam>
                    <input
                      type="text"
                      name="employeenumber"
                      placeholder=""
                      className="user-data-input"
                      value={defemployee}
                      onChange={(e) => {
                        setdefemployee(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Instruct_Sign :
                    </spam>
                    <input
                      type={"file"}
                      onChange={(e)=>{handleFileChange(e)}}
                   
                      className=""
                      style={{ marginLeft: "10px", padding: "0px 6px" }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      User__Name__ :
                    </spam>
                    <input
                      type="text"
                      name="username"
                      placeholder=""
                      className="user-data-input"
                      value={defname}
                      onChange={(e) => {
                        setdefname(e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Valid__Years__ :
                    </spam>
                    <input
                      type="text"
                      name="username"
                      placeholder=""
                      className="user-data-input"
                      value={tempvalid}
                      onChange={(e) => {
                        setTempValid(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Date_Of_Completion :
                    </spam>
                    <input
                      type="date"
                      name="completiondate"
                      placeholder=""
                      className="user-data-input"
                      value={defdate}
                      onChange={(e) => {
                        setdefdate(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Course_Name :
                    </spam>
                    <input
                      type="text"
                      name="coursename"
                      placeholder=""
                      className="user-data-input"
                      value={defcourse}
                      onChange={(e) => {
                        setdefcourse(e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Instruct_Name :
                    </spam>
                    <input
                      type="text"
                      name="instuctor"
                      placeholder=""
                      className="user-data-input"
                      value={instructor}
                      onChange={(e) => {
                        setInstructor(e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Employee Number:
                    </spam>
                    <input
                      type="text"
                      name="employeenumber"
                      placeholder=""
                      className="user-data-input"
                      value={defemployee}
                      onChange={(e) => {
                        setdefemployee(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="user-data-container">
                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Passing %:
                    </spam>
                    <input
                      type="text"
                      name="employeenumber"
                      placeholder=""
                      className="user-data-input"
                      value={defpass}
                      onChange={(e) => {
                        setDefpass(e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      User Score:
                    </spam>
                    <input
                      type="text"
                      name="employeenumber"
                      placeholder=""
                      className="user-data-input"
                      value={defuserscore}
                      onChange={(e) => {
                        setdefuserscore(e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 0px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Instruct_Sign :
                    </spam>
                    <input
                      type={"file"}
                      onChange={(e) => {
                        handleFileChange(e, "img");
                      }}
                      className=""
                      style={{ marginLeft: "10px", padding: "0px 6px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="" style={{ marginTop: "38px" }}>
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  Settings
                </h1>
              </div>

              <div className="user-data-bigger-container">Username</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.y}
                      onChange={getuserdata}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.x}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={username.color}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={username.font}
                      onChange={getuserdata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={username.fontsize}
                      onChange={getuserdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getuserdata}
                    >
                      <option selected={username.bold == false} value={false}>
                        False
                      </option>
                      <option selected={username.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Usercourse</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.y}
                      onChange={getcoursedata}
                    ></input>
                  </div>

                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.x}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.color}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.font}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.fontsize}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getcoursedata}
                    >
                      <option selected={usercourse.bold == false} value={false}>
                        False
                      </option>
                      <option selected={usercourse.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Valid Till Year</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={valid.y}
                      onChange={getvalidyear}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={valid.x}
                      onChange={getvalidyear}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={valid.color}
                      onChange={getvalidyear}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={valid.font}
                      onChange={getvalidyear}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={valid.fontsize}
                      onChange={getvalidyear}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getvalidyear}
                    >
                      <option selected={valid.bold == false} value={false}>
                        False
                      </option>
                      <option selected={valid.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Instuctor Name</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={instuctorFiled.y}
                      onChange={getInstuctor}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={instuctorFiled.x}
                      onChange={getInstuctor}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={instuctorFiled.color}
                      onChange={getInstuctor}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={instuctorFiled.font}
                      onChange={getInstuctor}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={instuctorFiled.fontsize}
                      onChange={getInstuctor}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getInstuctor}
                    >
                      <option selected={date.bold == false} value={false}>
                        False
                      </option>
                      <option selected={date.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="user-data-bigger-container">Date</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.y}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.x}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={date.color}
                      onChange={getdatedata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={date.font}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={date.fontsize}
                      onChange={getdatedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getdatedata}
                    >
                      <option selected={date.bold == false} value={false}>
                        False
                      </option>
                      <option selected={date.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container"> Passing %</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={passing.y}
                      onChange={getpassingdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={passing.x}
                      onChange={getpassingdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={passing.color}
                      onChange={getpassingdata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={passing.font}
                      onChange={getpassingdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={passing.fontsize}
                      onChange={getpassingdata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getpassingdata}
                    >
                      <option selected={passing.bold == false} value={false}>
                        False
                      </option>
                      <option selected={passing.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container"> User Score %</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={userscore.y}
                      onChange={getuserscoredata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={userscore.x}
                      onChange={getuserscoredata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={userscore.color}
                      onChange={getuserscoredata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={userscore.font}
                      onChange={getuserscoredata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={userscore.fontsize}
                      onChange={getuserscoredata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getuserscoredata}
                    >
                      <option selected={userscore.bold == false} value={false}>
                        False
                      </option>
                      <option selected={userscore.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="user-data-bigger-container">Employee Number:</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={employee.y}
                      onChange={getEmployeeData}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={employee.x}
                      onChange={getEmployeeData}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Color :
                    </spam>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={employee.color}
                      onChange={getEmployeeData}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font :
                    </spam>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={employee.font}
                      onChange={getEmployeeData}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Font_Size :
                    </spam>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={employee.fontsize}
                      onChange={getEmployeeData}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Bold :
                    </spam>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getEmployeeData}
                    >
                      <option selected={employee.bold == false} value={false}>
                        False
                      </option>
                      <option selected={employee.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="user-data-bigger-container">Signature</div>
              <div className="" style={{ border: "2px solid lightgray" }}>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Y__ :
                    </spam>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={sign.y}
                      onChange={getsigndata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      X__ :
                    </spam>
                    <input
                      type="Number"
                      name="x"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={sign.x}
                      onChange={getsigndata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Width :
                    </spam>
                    <input
                      type="Number"
                      name="width"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={sign.width}
                      onChange={getsigndata}
                    ></input>
                  </div>
                </div>
                <div className="username-container">
                  <div className="" style={{ margin: "10px 20px" }}>
                    <spam className="" style={{ fontWeight: "bold" }}>
                      Height :
                    </spam>
                    <input
                      type="Number"
                      name="height"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={sign.height}
                      onChange={getsigndata}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="preview-container">
                <div className="" style={{ margin: "5px 10px" }}>
                  {/* <a href={data.templateUrl} target="_blank" rel="noopener noreferrer"> */}
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={previewcertificate}
                  >
                    Preview
                  </Button>
                  {/* </a> */}
                </div>
              </div>
              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    marginBottom: "20px",
                  }}
                >
                  preview Certificate
                </h1>
              </div>
              <div className="">
                <embed type="" src={url} width="100%" height="500" />
              </div>

              <div className="preview-container">
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={savechanges}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Certifictedetails;
