import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function Mcqpage({ question, instruction, qno, qtot, setActiveSubmit }) {
  const [optclick, setoptClicks] = useState([]);
  useEffect(() => {
    let newarr = [];
    for (let i = 0; i < qtot; i++) newarr[i] = false;
    setoptClicks(newarr);
  }, [question]);

  //console.log(optclick);
  let cnt = 0;
  const optionclick = (num) => {
    const ans = {
      type: "mcq",
      qno: qno,
      correct: question.options[num - 1].iscorrect,
    };
    localStorage.setItem("answer", JSON.stringify(ans));
    let newarr = [];
    //console.log(question.options[num - 1].iscorrect);
    for (let i = 0; i < question.options.length; i++) {
      if (i == num - 1) {
        newarr[i] = true;
      } else {
        newarr[i] = false;
      }
    }
    setoptClicks(newarr);
    setActiveSubmit(true);
  };
  return (
    <Box sx={{ padding: "30px 30px 30px 15px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: {xs:"10px 20px", md:"10px 40px"},
              fontSize: { xs: "12px", md: "16px" },
              borderRadius: "5px",
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ my: "30px" }}>
        {question &&
          question.options.map((item, id) => {
            cnt++;
            return (
              <Box
                key={id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  py: "20px",
                  borderBottom: "1px solid #00495F80",
                  "&:last-child": { borderBottom: "none" },
                }}
              >
                <Box
                  onClick={() => optionclick(id + 1)}
                  sx={{
                    background: `${optclick[id] ? "#003A69" : "white"}`,
                    color: `${optclick[id] ? "#fff" : "#000"}`,
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    border: "1px solid #00495F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    {cnt}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    {item.option}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default Mcqpage;
