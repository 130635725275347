import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import { PhysicsIcons, PlusIcons } from "../../../utilities/Icons/Icons";
import "../../styles/Homepage/Homepage.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import CertificateCard from "./Cards/CertificateCard";
import TrainingCard from "./Cards/TrainingCard";
import { IP } from "../../../config";
import axios from "axios";
import LoadingScreen from "../loadingScreen";

function Homepage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const [internalTraining, setInternalTraining] = useState([]);
  const [externalTraining, setExternalTraining] = useState([]);
  const [internalOfflineTraining, setInternalOfflineTraining] = useState([]);
  const [previousTraining, setPreviousTraining] = useState([]);
  const [certificate, setCertificate] = useState([]);

  const [showAll, setShowAll] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let InternalTraining = {
      method: "get",
      params: { select: 1 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    setLoading(true);
    axios(InternalTraining)
      .then((result) => {
        console.log(result.data);
        setLoading(false);
        let tempArr = [];

        let en = result.data
          .filter((el) => el.completionDate == null)
          .sort((a, b) => b.startDate._seconds - a.startDate._seconds);
        console.log(en);
        let completed = result.data
          .filter((el) => el.completionDate != null)
          .sort(
            (a, b) => b.completionDate._seconds - a.completionDate._seconds
          );
        setInternalTraining([...en, ...completed]);
        // setInternalTraining([...result.data].sort(() => 0.5 - Math.random()));
      })
      .catch((err) => {
        setLoading(false);
      });

    let configLesson = {
      method: "get",
      params: { select: 0 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configLesson).then((result) => {
      // //console.log(result);
      setExternalTraining([...result.data].sort(() => 0.5 - Math.random()));
    });

    let InternalOffline = {
      method: "get",
      params: { select: 2 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(InternalOffline).then((result) => {
      // //console.log(result.data);
      setInternalOfflineTraining(
        [...result.data].sort(() => 0.5 - Math.random())
      );
    });

    let certificates = {
      method: "get",
      params: { select: 3 },
      url: `${IP}/userCourse/certificates`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(certificates).then((result) => {
      // //console.log(result);
      setCertificate([...result.data].sort(() => 0.5 - Math.random()));
      setPreviousTraining(
        [...result.data].filter((ele) => ele.trainingType == "Previous")
      );
    });
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("Home")} />
        <LoadingScreen open={loading} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("Home")} />
      </Box>
      <Box>
        <Box sx={{ py: 6 }}>
          <Container>
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} md={8}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          fontWeight: 600,
                          fontSize: { xs: "18px", md: "22px" },
                        }}
                      >
                        My Training
                      </Typography>

                      {/* <Link to="my-courses">
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.primary.btn,
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: 500,
                          }}
                        >
                          {t("ViewAll")}
                        </Typography>
                      </Link> */}

                      <Typography
                        sx={{
                          color: (theme) => theme.palette.primary.btn,
                          fontSize: { xs: "12px", md: "14px" },
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAll(!showAll);
                        }}
                      >
                        {showAll ? t("Show less") : t("Show all")}
                      </Typography>
                    </Box>
                    <Stack spacing="40px">
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: { xs: "120px", md: "220px" },
                              textAlign: "center",
                              fontWeight: 600,
                              background: (theme) =>
                                theme.palette.secondary.main,
                              padding: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                              fontSize: { xs: "8px", md: "16px" },
                            }}
                          >
                            Internal Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: (theme) =>
                                theme.palette.secondary.main,
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: (theme) =>
                                  theme.palette.secondary.main,
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                                zIndex: 1,
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {internalTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {internalTraining?.map((training, index) => {
                              const typ = "internal";
                              if (!showAll && index > 1) return;
                              return (
                                <TrainingCard
                                  key={index}
                                  training={training}
                                  typ={typ}
                                />
                              );
                            })}
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "'Readex Pro', sans-serif",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll Internal Training First
                          </div>
                        )}
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: { xs: "120px", md: "220px" },
                              textAlign: "center",
                              fontWeight: 600,
                              background: (theme) =>
                                theme.palette.secondary.second,
                              padding: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                              fontSize: { xs: "8px", md: "16px" },
                            }}
                          >
                            External Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: (theme) =>
                                theme.palette.secondary.second,
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: (theme) =>
                                  theme.palette.secondary.second,
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {externalTraining?.map((training, index) => {
                            if (index > 1) return;
                            return <TrainingCard key={index} training={training} />;
                          })}
                        </Grid> */}
                        {externalTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {externalTraining?.map((training, index) => {
                              if (!showAll && index > 1) return;

                              const typ = "external";
                              return (
                                <TrainingCard
                                  key={index}
                                  training={training}
                                  typ={typ}
                                />
                              );
                            })}
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "'Readex Pro', sans-serif",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll External Training First
                          </div>
                        )}
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: { xs: "120px", md: "220px" },
                              textAlign: "center",
                              fontWeight: 600,
                              background: (theme) =>
                                theme.palette.secondary.main,
                              padding: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                              fontSize: { xs: "8px", md: "16px" },
                            }}
                          >
                            Internal Offline Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: (theme) =>
                                theme.palette.secondary.main,
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: (theme) =>
                                  theme.palette.secondary.main,
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {internalOfflineTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {internalOfflineTraining?.map((training, index) => {
                              if (!showAll && index > 1) return;

                              const typ = "internalOffline";
                              return (
                                <TrainingCard
                                  key={index}
                                  training={training}
                                  typ={typ}
                                />
                              );
                            })}
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "'Readex Pro', sans-serif",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll Internal offline Training First
                          </div>
                        )}
                      </Box>
                    </Stack>
                  </Box>

                  <Box sx={{ width: "100%", mt: "50px", mb: "20px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#00495F",
                          fontWeight: 600,
                          fontSize: "22px",
                        }}
                      >
                        {t("Certificates")}
                      </Typography>

                      <Link to="my-certificate-view-all">
                        <Typography
                          sx={{
                            color: "#007C84",
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: 500,
                          }}
                        >
                          {t("ViewAll")}
                        </Typography>
                      </Link>
                    </Box>
                    <Box spacing="true" sx={{ mt: "20px" }}>
                      {certificate[0] ? (
                        <Grid container spacing={4}>
                          {certificate?.map((certs, index) => {
                            if (index > 1) return;
                            const typ = "certificate";
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                                typ={typ}
                              />
                            );
                          })}
                        </Grid>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                            borderRadius: "10px",
                            color: "#007848",
                            fontFamily: "'Readex Pro', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          You Dont Have Any Certificates
                        </div>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", md: "22px" },
                      fontWeight: 600,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {t("PreviousTraining")}
                  </Typography>
                  <Box
                    sx={{
                      px: 2,
                      py: 3,
                      mt: 3,
                      borderRadius: "10px",
                      background: "#fff",
                    }}
                  >
                    <Box>
                      {
                        //// implement things here
                        previousTraining &&
                          previousTraining.map((training, index) => {
                            const dueDate = Timestamp.fromMillis(
                              training?.issuedOn?._seconds * 1000
                            );
                            //console.log("training:", training);
                            if (index > 3) return;
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      color: "#007C84",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {index + 1}
                                  </Box>
                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "12px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t(training.certificateName)}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "12px",
                                        my: 1,
                                      }}
                                    >
                                      {training.trainingProvider}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "10px",
                                        fontFamily: "'Readex Pro', sans-serif",
                                      }}
                                    >
                                      {/* training start(issu) Date */}
                                      {dueDate
                                        .toDate()
                                        .toLocaleDateString("en-US", {
                                          month: "long",
                                          day: "numeric",
                                          year: "numeric",
                                        })}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: (theme) =>
                                        theme.palette.primary.main,
                                    }}
                                  >
                                    <PhysicsIcons />
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })
                      }
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <Box sx={{ textAlign: "center" }}>
                        <Link to="my-certificate-view-all">
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.primary.btn,
                              fontSize: { xs: "12px", md: "14px" },
                              fontWeight: 500,
                            }}
                          >
                            {t("ViewAll")}
                          </Typography>
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Link
                          to="add-certificates"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            textTransform: "none",
                            color: "#fff",
                            padding: "10px 30px",
                            background: "#003A69",
                            borderRadius: "5px",
                            fontWeight: 600,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontFamily: "'Readex Pro', sans-serif",
                              color: "#fff",
                            }}
                          >
                            {t("AddCertificate")}
                          </Typography>
                          <Box>
                            <PlusIcons />
                          </Box>
                        </Link>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "8px",
                          mt: 1,
                          color: (theme) => theme.palette.primary.btn,
                          textAlign: "center",
                          fontWeight: 300,
                        }}
                      >
                        Add your previous Certificates from previous company
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default Homepage;
