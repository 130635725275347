import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { getCookie } from "../../../Cookies";
import { auth } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import { IP } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ChangePassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const updateUserPassword = async () => {
    if (!newPassword || !confirmPassword) {
      alert("Please fill all fields");
      return;
    }
    if (newPassword.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }
    if (!currentPassword) {
      alert("Please enter your current password");
    }
    if (newPassword != confirmPassword) {
      alert("New password and confirm password are not match");
      return;
    }
    const user = auth.currentUser;

    // let configProfile = {
    //   method: "post",
    //   // params: { userId: uid },
    //   url: `${IP}/auth/updatePassword`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${location.state?.idToken}`,
    //   },
    //   data: JSON.stringify({
    //     current: userName,
    //     newpass: newPassword,
    //   }),
    // };

    // axios(configProfile);
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/auth/updatePassword`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        current: currentPassword,
        newpass: newPassword,
      }),
    };

    axios(configProfile)
      .then(async (userCred) => {
        alert("Password Changed");
        navigate("/user/profile");
      })
      .catch((error) => {
        //console.log(error);
        toast.error("something went wrong");
        alert(error);
      });
  };

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("change password")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("change password")} />
      </Box>
      <Box sx={{ mt: 5 }}>
        <Container>
          <form
            action=""
            style={{ padding: "20px", height: "80vh", background: "#fff" }}
          >
            <Box>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                Current Password
              </Typography>
              <Box
                sx={{
                  ...responsiveWidth,
                  display: "flex",
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #003A69",
                  outline: "none",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#000" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#000" }} />
                  )}
                </Box>
              </Box>
              {/* <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                style={{
                  ...responsiveWidth,
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #003A69",
                  outline: "none",
                }}
              /> */}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                Enter New Password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  ...responsiveWidth,
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #003A69",
                  outline: "none",
                }}
              >
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showNewPassword ? (
                    <VisibilityIcon sx={{ color: "#000" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#000" }} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                Confirm Password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  ...responsiveWidth,
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #003A69",
                  outline: "none",
                }}
              >
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon sx={{ color: "#000" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#000" }} />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "20%",
                  justifyContent: { xs: "center", md: "end" },
                  alignItems: "flex-end",
                }}
              >
                <Button
                  sx={{
                    width: "180px",
                    textTransform: "none",
                    color: "#fff",
                    padding: "5px 30px",
                    borderRadius: "4px",
                    fontWeight: 600,
                    background: (theme) => theme.palette.primary.main,
                    "&:hover": {
                      background: (theme) => theme.palette.primary.main,
                    },
                  }}
                  onClick={updateUserPassword}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </Box>
  );
}

export default ChangePassword;
