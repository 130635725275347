import { Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { setCookie } from "../../Cookies";
import { flexBetweenWithItemsCenter } from "../../utilities/commonStyles/commonStyles";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import {
  CertificatesIcons,
  CourseLibraryIcons,
  HomeIcons,
  LeftArrowIcons,
  MenuIcons,
  ProfileIcons,
  SettingsIcons,
  TrainingIcons
} from "../../utilities/Icons/Icons";
import logo from "./img/logo/logo.png";
import classes from "./Sidebar/Sidebar.module.css";
// import logo from "../../../utilities/images/AGE-logo_1-removebg-preview 1.png";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import "../Translater/Translater";

const Practice = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  const { hidepage, setHidepage } = useContext(DrawerContext);
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Box>
      <motion.div
        animate={{
          width: isOpen ? "270px" : "110px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 20,
          },
        }}
      >
        <Box className={classes.sidebar}>
          <Box
            sx={{
              height: "100vh",
              padding: "0px 16px",
              boxShadow:
                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
            }}
          >
            <AnimatePresence>
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={logo}
                    style={{
                      width: isOpen ? "200px" : "70px",
                      margin: "10px 0px",
                    }}
                    alt=""
                  />
                </Box>
              </motion.div>
            </AnimatePresence>

            <Box sx={{ position: "relative" }}>
              <AnimatePresence>
                <Typography
                  sx={{
                    color: "#007C84",
                    fontWeight: 300,
                    padding: isOpen ? "0px 15px" : "0px 5px",
                    marginTop: "10px",
                    fontSize: isOpen ? "16px" : "10px",
                  }}
                >
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  >
                    {t("DASHBOARD")}
                  </motion.div>
                </Typography>
              </AnimatePresence>
              <Box onClick={toggle} sx={{position:"absolute", top: 0, right: 0}}>
                <MenuIcons />
              </Box>
            </Box>
            <Box>
              <NavLink
                to="home"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <HomeIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Home")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="course-library"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CourseLibraryIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("CourseLibrary")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="training"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <TrainingIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Training")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="profile"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <ProfileIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Profile")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="certificates"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CertificatesIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Certificates")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                position: "absolute",
                bottom: "50px",
              }}
            >
              <NavLink to="settings">
                <Box
                  sx={{
                    background: "#F5FAFA",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcons />
                </Box>
              </NavLink>
              {isAdmin && (
                <Box>
                  <button
                    style={{
                      width: "170px",
                      background: "linear-gradient(to right, #007848, #004577)",
                      color: "#fff",
                      padding: "10px 0px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      setCookie("ROLE", "admin");
                      navigate("/admin");
                    }}
                  >
                    {t("Admin")}
                  </button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Practice;
