import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../Cookies";
import { functions } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import { ReactComponent as Delete } from "./assets/delete.svg";
import { ReactComponent as PdfIcon } from "./assets/pdfIcon.svg";
import { ReactComponent as UploadIcon } from "./assets/upload.svg";
import "./CourseList.css";
import { IP } from "../../config";
import axios from "axios";

function TrainInterOffAdd() {
  const editTraining = httpsCallable(functions, "editTraining");
  const uploadPdf = httpsCallable(functions, "uploadPdf");
  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToOffline = () => navigate("/admin/training/internalOff");
  const [templateArr, setTemplateArr] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(""); //default template id
  const [training, setTraining] = useState({
    trainingName: "",
    trainingProvider: "",
    trainingDesc: "",
  });

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    trainingName: "",
    trainingProvider: "",
    trainingDesc: "",
    selectedTemplate: "",
  });

  const [error, setError] = useState(false);
  const [trainingId, setTrainingId] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const trainingDetails = location.state?.trainingId;
  const inputRef = useRef(null);
  const dropRef = useRef(null);
  //console.log("trainingDetails", trainingDetails);
  useEffect(() => {
    let configCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configCertificate).then((res) => {
      setTemplateArr(res.data);
    });
    if (!trainingDetails) return;
    setSelectedTemplate(trainingDetails.certificateTemplate);
    setTraining(trainingDetails);
    setTrainingId(trainingDetails.trainingId);
    setFileList(trainingDetails.materials);
  }, [trainingDetails]);
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const allIsWell = () => {
    // if (
    //   training.trainingName == "" ||
    //   training.trainingProvider == "" ||
    //   training.trainingDesc == ""
    // ) {
    //   return false;
    // } else if (selectedTemplate == "") {
    //   return false;
    // }
    // return true;

    if (training.trainingName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, trainingName: "error" }));

      return false;
    } else if (training.trainingProvider == "") {
      setCheckErrorInForm((prev) => ({ ...prev, trainingProvider: "error" }));
      return false;
    } else if (selectedTemplate == "") {
      setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
      return false;
    } else if (training.trainingDesc == "") {
      setCheckErrorInForm((prev) => ({ ...prev, trainingDesc: "error" }));
      return false;
    }
    return true;
  };
  const handleInput = (e) => {
    setError(false);
    const { name, value } = e.target;
    setTraining((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      let div = dropRef.current;
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  }, []);

  const deleteNotes = (index) => {
    let arr = Array.from(fileList);
    arr.splice(index, 1);
    //console.log(arr);
    setFileList(arr);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log(e);
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log("In");
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log("out");
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log("drop", e.dataTransfer.files);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    //console.log("hola", files);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1024 / 1024 > 5) {
        alert("Material should be less than 5MB.");
        return;
      }
      setUploading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        let configpdfUpload = {
          method: "post",
          // params: { userId: uid },
          url: `${IP}/trainings/pdfUpload`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            base64Image: reader.result,
          }),
        };

        const result = await axios(configpdfUpload);
        // console.log(result.data);
        // const result = await uploadPdf({ pdf: reader.result });
        //console.log("uploading");
        setFileList((prev) =>
          prev.concat({ name: files[i].name, link: result.data })
        );
        if (i == files.length - 1) setUploading(false);
      };
      reader.readAsDataURL(files[i]);
      // upload file and get
      // add link in state
    }
  };

  const addTraining = () => {
    setError(false);
    //console.log("???");
    if (!allIsWell()) {
      setError(true);
      return;
    }

    setCheckErrorInForm({
      trainingName: "",
      trainingProvider: "",
      trainingDesc: "",
      selectedTemplate: "",
    });
    setLoading(true);
    let configCertificate = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/trainings/external`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        createdOn: new Date().toString(),
        trainingSelect: "internalOfflineTraining", // fixed val
        trainingID: trainingId,
        trainingName: training.trainingName,
        trainingProvider: training.trainingProvider,
        certificateTemplate: selectedTemplate,
        trainingDesc: training.trainingDesc,
        materials: fileList,
      }),
    };
    axios(configCertificate).then((res) => {
      setLoading(false);
      if (trainingDetails) {
        alert("Course " + training.trainingName + " updated");
      } else {
        alert("Course " + training.trainingName + " added");
      }

      goToOffline();
    });
  };

  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div
        className="courselist-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div className="courselist-main-cnt-top">
            <div className="courselist-main-cnt-top-txt">
              Add New Internal Offline Training
            </div>
          </div>
          <div className="courselist-main-cnt-bottom">
            <div className="admin-overdue-bottom">
              <div className="admin-training-main">
                <div className="admin-course-main-top">
                  <div
                    className="admin-course-main-top-coursename"
                    id="admin-course-main-top-id"
                  >
                    <div className="admin-course-main-top-inputlable">
                      Course Title *
                    </div>
                    <div className="admin-course-main-top-inputlable">
                      <input
                        type="text"
                        className="admin-course-main-top-input"
                        value={training.trainingName}
                        style={{
                          border: `${
                            checkErrorInForm.trainingName == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        name="trainingName"
                        onChange={(event) => {
                          if (!trainingDetails) {
                            handleInput(event);
                            setCheckErrorInForm((prev) => ({
                              ...prev,
                              trainingName: "",
                            }));
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setTrainingId(value + "_" + time);
                          } else {
                            handleInput(event);
                            setCheckErrorInForm((prev) => ({
                              ...prev,
                              trainingName: "",
                            }));
                          }
                        }}
                      />
                      {checkErrorInForm.trainingName == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Enter Course Title{" "}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className="admin-course-main-top-coursduration"
                    id="admin-course-main-top-id"
                  >
                    <div className="admin-course-main-top-inputlable">
                      Offline Course Provider *
                    </div>
                    <div className="admin-course-main-top-inputlable">
                      <input
                        type="text"
                        name="trainingProvider"
                        style={{
                          border: `${
                            checkErrorInForm.trainingProvider == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        value={training.trainingProvider}
                        className="admin-course-main-top-input"
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            trainingProvider: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.trainingProvider == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Enter Training Provider Name{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="admin-course-main-top-coursduration"
                    id="admin-course-main-top-id"
                  >
                    <div className="admin-course-main-top-inputlable">
                      Certificate Template *
                    </div>
                    <div className="admin-course-main-top-inputlable">
                      <select
                        className="admin-course-main-top-input"
                        value={selectedTemplate}
                        style={{
                          border: `${
                            checkErrorInForm.selectedTemplate == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        onChange={(e) => {
                          setSelectedTemplate(e.target.value);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            selectedTemplate: "",
                          }));
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select an Template
                        </option>
                        {templateArr?.map((item, id) => (
                          <option key={id} value={item.id}>
                            {item.templateName}
                          </option>
                        ))}
                      </select>
                      {checkErrorInForm.selectedTemplate == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          select Template{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="admin-course-main-top-trainingprovider"
                    id="admin-course-main-top-id"
                  >
                    <div className="admin-course-main-top-inputlable">
                      Offline Course ID
                    </div>
                    <div className="admin-course-main-top-inputlable">
                      <input
                        type="text"
                        className="admin-course-main-top-input"
                        name="trainingId"
                        value={trainingId}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div
                    className="admin-course-main-top-shortdesc"
                    id="admin-course-main-top-id"
                  >
                    <div className="admin-course-main-top-inputlable">
                      Short Description *
                    </div>
                    <div className="admin-course-main-top-inputlable">
                      <textarea
                        type="text"
                        className="admin-course-main-top-textarea"
                        name="trainingDesc"
                        value={training.trainingDesc}
                        style={{
                          border: `${
                            checkErrorInForm.trainingDesc == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            trainingDesc: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.trainingDesc == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Short Description{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="admin-upload-material">
                  <p>Upload Materials </p>
                  {!uploading ? (
                    <div className="admin-drop-file" ref={dropRef}>
                      <UploadIcon />
                      <div style={{ display: "flex", gap: "4px" }}>
                        Drag and Drop files or{" "}
                        <p
                          style={{ color: "#483EA8" }}
                          onClick={() => {
                            inputRef.current.click();
                          }}
                        >
                          <u> Browse.</u>
                        </p>
                      </div>
                      <input
                        ref={inputRef}
                        type={"file"}
                        onChange={(e) => {
                          handleFileUpload(e.target.files);
                        }}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  ) : (
                    <div className="admin-drop-file">
                      <div style={{ display: "flex", gap: "4px" }}>
                        Uploading
                      </div>
                    </div>
                  )}
                  {/* <button
                    style={{
                      background: `${
                        clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                      }`,
                      fontFamily: "'Readex Pro', sans-serif",
                      color: "#fff",
                      height: "45px",
                      marginTop: "10px",
                      border: "none",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                  >
                    UPLOAD FILES
                  </button> */}
                  {fileList.map((item, id) => (
                    <div key={id} className="fileList">
                      <PdfIcon style={{ marginRight: "3px" }} />
                      <p>
                        {item.name.length > 25
                          ? item.name.slice(0, 25 - 1) + "..."
                          : item.name}
                      </p>
                      {/* <Edit /> */}
                      <Delete
                        style={{ marginLeft: "auto" }}
                        onClick={() => deleteNotes(id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {error && (
                <div style={{ color: "red" }}>Fill all the fileds!</div>
              )}
              <div
                className="courselist-main-cnt-back-btn"
                style={{
                  display: "grid",
                  placeItems: "center",
                  marginTop: "10vw",
                }}
              >
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "13vw",
                  }}
                  onClick={addTraining}
                  disabled={loading}
                >
                  {!loading ? "Save & Update" : "saving..."}
                </button>
              </div>
            </div>
          </div>
          <div className="courselist-main-cnt-back">
            <div className="courselist-main-cnt-back-btn">
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TrainInterOffAdd;
