import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Container, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { deleteCookie, getCookie } from "../../../Cookies";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ProfilePageMain() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [logUotState, setlogUotState] = useState(false);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("profile")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("profile")} />
      </Box>
      <Box sx={{ flexGrow: 1, mt: 10 }}>
        <Container>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {/* removed because cilent said so */}
            {/* <Grid item xs={12} md={4}>
              <Item sx={{
                        boxShadow:
                          "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                        borderRadius: "10px",
                        m: "none",
                        background: "#fff",
                        borderRadius: "10px",
                        position: "relative",
                        overflow: "hidden",
                      }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      My Account
                    </Typography>
                    <Typography
                      sx={{ color: "#000", mt: 1, fontSize: "12px" }}
                    >
                      Edit Your Account
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: (theme) => theme.palette.primary.main,
                      }}
                      sx={{
                        width: "40px",
                        height: "40px",
                        bgcolor: "#000",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.6946 0.057624C10.596 0.0388023 10.4974 0.0199805 10.3989 0.00029375L9.32112 0C9.25084 0.0125506 9.18047 0.0246401 9.1101 0.0367297C8.95866 0.0627457 8.80723 0.0887624 8.65672 0.119377C7.3609 0.3832 6.31418 1.05999 5.44553 2.04215C4.03016 3.6424 3.66503 6.21414 4.5679 8.1446C5.52911 10.2 7.64668 11.7145 10.2211 11.5404C12.1817 11.4079 13.6641 10.4741 14.7355 8.86987C15.542 7.66256 15.7498 6.3128 15.5614 4.88463C15.2752 2.71506 13.4598 0.687056 11.3343 0.188931C11.1224 0.139278 10.9085 0.0984511 10.6946 0.057624ZM4.28566 23.994H10.0016C11.8523 23.9967 13.7029 23.996 15.5534 23.992C15.8494 23.9914 16.1464 23.9762 16.4411 23.9482C18.494 23.7528 19.9718 22.1699 19.9985 20.1067C20.0074 19.3888 19.9767 18.6709 19.9065 17.9564C19.7725 16.5898 19.4712 15.2638 18.799 14.0455C18.1186 12.8123 17.1317 11.9838 15.7305 11.6885C15.0095 11.5365 14.3246 11.5407 13.712 12.0368C13.5431 12.1737 13.3546 12.2871 13.1667 12.4002C13.1132 12.4324 13.0598 12.4645 13.0069 12.4972C11.0065 13.7304 9.00609 13.7316 7.00565 12.5008C6.87161 12.4183 6.73989 12.3318 6.60821 12.2453C6.39398 12.1045 6.17984 11.9638 5.95593 11.8407C5.7424 11.7233 5.49478 11.6059 5.26001 11.6008C4.49941 11.582 3.74694 11.7608 3.07612 12.1198C1.97434 12.7044 1.3043 13.663 0.857536 14.7776C0.113255 16.6349 -0.11729 18.5817 0.0534466 20.566C0.149116 21.6772 0.69167 22.5758 1.6088 23.2294C2.40966 23.8003 3.32057 23.994 4.28566 23.994Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="my-account">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                          width: "100px",
                        }}
                      >
                        Edit
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid> */}

            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      Change Password
                    </Typography>
                    <Typography sx={{ color: "#000", mt: 1, fontSize: "12px" }}>
                      Change Your Password
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        background: (theme) => theme.palette.primary.main,
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9998 10.7999H16.7998V7.1999C16.7998 4.5599 14.6398 2.3999 11.9998 2.3999C9.3598 2.3999 7.1998 4.5599 7.1998 7.1999V10.7999H5.9998C5.3998 10.7999 4.7998 11.3999 4.7998 11.9999V20.3999C4.7998 20.9999 5.3998 21.5999 5.9998 21.5999H17.9998C18.5998 21.5999 19.1998 20.9999 19.1998 20.3999V11.9999C19.1998 11.3999 18.5998 10.7999 17.9998 10.7999ZM13.1998 19.1999H10.7998L11.2798 16.5599C10.6798 16.3199 10.1998 15.5999 10.1998 14.9999C10.1998 14.0399 11.0398 13.1999 11.9998 13.1999C12.9598 13.1999 13.7998 14.0399 13.7998 14.9999C13.7998 15.7199 13.4398 16.3199 12.7198 16.5599L13.1998 19.1999ZM14.3998 10.7999H9.5998V7.1999C9.5998 5.8799 10.6798 4.7999 11.9998 4.7999C13.3198 4.7999 14.3998 5.8799 14.3998 7.1999V10.7999Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="change-password">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                          width: "100px",
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                        }}
                      >
                        Change
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    {/* <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      Change Password
                    </Typography> */}
                    <Typography
                      sx={{
                        color: "#000",
                        mt: 1,
                        fontSize: "20px",
                        fontWeight: 800,
                        textAlign: "center",
                      }}
                    >
                      Logout
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        bgcolor: "#000",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM15 14L20 10L15 6V9H7V11H15V14Z"
                          fill="white"
                        />
                      </svg>
                    </Box>

                    <Button
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        padding: "5px 30px",
                        borderRadius: "15px",
                        fontWeight: 600,
                        mt: "20px",
                        width: "100px",
                        background: (theme) => theme.palette.primary.main,
                        "&:hover": {
                          background: (theme) => theme.palette.primary.main,
                        },
                      }}
                      onClick={() => {
                        //  setlogUotState(true)
                        /// alert("datata")
                        if (
                          window.confirm("are you sure you want to Logout ?")
                        ) {
                          deleteCookie("UID");
                          window.location.href = "/";
                        } else {
                        }
                      }}
                    >
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default ProfilePageMain;
