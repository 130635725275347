import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import {
  AccordionDownArrowIcons,
  AccordionUpArrowIcons,
  ContentCourseAccodionHeaderIcons,
  ContentCourseAccordionQuizIcons,
  ContentCoursePdfIcons,
  ContentCourseVideoIcons,
  ContentCourseWebIcons,
  TickCompleted,
} from "../../../../../utilities/Icons/Icons";
import TopicTimeLine from "./TopicTimeLine";
import ItemsTimeLine from "./ItemsTimeLine";
import "./SingleCoursesTimeline.css";
import QuizTimeLine from "./QuizTimeLine";
import { ItemContext } from "../Context/ItemContext";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";
import { IP } from "../../../../../config";

const LessonTimeline = ({ first, lessonId }) => {
  const [accordion, setAccordion] = useState(null);
  const [lessonInfo, setLessonInfo] = useState(null);
  const [completed, setCompleted] = useState(false);
  const getLessonInfo = httpsCallable(functions, "getLessonInfo");
  const { courseId } = useContext(ItemContext);

  const getLessonProgress = httpsCallable(functions, "getLessonProgress");

  // userCourseDetails;
  useEffect(() => {
    let CourseInfo = {
      method: "get",
      params: { lessonId: lessonId },
      url: `${IP}/userCourseDetails/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((response) => setLessonInfo(response.data));

    let lessonProgress = {
      method: "get",
      params: { courseId: courseId, lessonId: lessonId },
      url: `${IP}/userProgress/lessons`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(lessonProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log("lessonProgress ", result);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Timeline className={"timeline"}>
        <Box>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={"timeline_dot_header"}>
                <ContentCourseAccodionHeaderIcons />
              </TimelineDot>
              {accordion && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box
                onClick={() => setAccordion(!accordion)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "2px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Lato",
                      color: "#007C84",
                      fontWeight: 700,
                    }}
                  >
                    {lessonInfo?.lessonName}
                  </Typography>
                </Box>
                {completed && (
                  <TickCompleted
                    style={{ marginLeft: "auto", marginRight: "30px" }}
                  />
                )}
                <Box>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: "0px",
                    }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    {accordion ? (
                      <AccordionDownArrowIcons />
                    ) : (
                      <AccordionUpArrowIcons />
                    )}
                  </button>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          {accordion && (
            <Box>
              {lessonInfo?.extraInfo.items?.map((itemId, index, arr) => (
                <ItemsTimeLine
                  key={index}
                  first={Boolean(first && index === 0)}
                  itemId={itemId}
                  connect={
                    index != arr.length - 1 ||
                    lessonInfo.extraInfo.topics ||
                    lessonInfo.extraInfo.quizzes
                  }
                />
              ))}

              {/* topics */}

              {lessonInfo?.extraInfo.topics && (
                <TimelineItem>
                  <TimelineSeparator className={"separator_content_padding"}>
                    <TimelineDot className={"timeline_dot"} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        pt: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Lato",
                          color: "#007C84",
                          fontWeight: 700,
                        }}
                      >
                        {"Topics"}
                      </Typography>
                    </Box>
                    <Box>
                      {lessonInfo?.extraInfo.topics?.map((topicId, index) => (
                        <TopicTimeLine key={index} topicId={topicId} />
                      ))}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              )}

              {/* quiz */}
              {lessonInfo?.extraInfo.quizzes?.map((quizId, index, arr) => (
                <QuizTimeLine
                  quizId={quizId}
                  key={index}
                  connect={index != arr.length - 1}
                />
              ))}
            </Box>
          )}
        </Box>
      </Timeline>
    </Box>
  );
};

export default LessonTimeline;

{
  /* <Box sx={{ mt: "20px" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between", paddingY: "10px", alignItems: "center" }}>
        <Box sx={{ width: "80%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.58255 0.000217367H14.7649C16.1741 0.000217367 17.1552 0.983411 17.1553 2.39537C17.1556 4.99217 17.1556 7.58898 17.1553 10.1858C17.1553 11.5972 16.1736 12.5804 14.7642 12.5806C10.6367 12.5808 6.50922 12.5808 2.38166 12.5806C0.986991 12.5806 0.000405845 11.5913 0.000262606 10.194C-8.75352e-05 7.59125 -8.75352e-05 4.98846 0.000262606 2.38568C0.000262606 0.990286 0.988614 0.000598558 2.38233 0.000312079C4.44909 -6.98927e-05 6.51582 -0.000100943 8.58255 0.000217367ZM5.72253 2.85961C5.04396 2.85884 4.3804 3.0593 3.81577 3.43565C3.25113 3.81201 2.81077 4.34735 2.55039 4.97397C2.29001 5.60059 2.22129 6.29036 2.35293 6.95604C2.48456 7.62172 2.81064 8.23341 3.28993 8.71377C3.76922 9.19412 4.38018 9.52156 5.04557 9.65468C5.71095 9.7878 6.40087 9.72062 7.02807 9.46163C7.65527 9.20264 8.19159 8.76348 8.5692 8.19968C8.94681 7.63589 9.14875 6.97277 9.14949 6.2942C9.14902 5.38475 8.78806 4.51257 8.14569 3.86877C7.50332 3.22498 6.63194 2.86209 5.72249 2.85961H5.72253ZM14.8596 3.44341H10.3077V4.56545H14.8596V3.44341ZM14.864 5.72974H10.3067V6.84773H14.8644L14.864 5.72974ZM14.8598 8.01489H10.3046V9.13693H14.8602L14.8598 8.01489ZM4.58255 16.0001V14.875H6.85285V13.737H10.2906V14.8628H12.567V16.0001H4.58255ZM7.61751 7.56497C7.86972 7.18925 8.0048 6.74716 8.00566 6.29465C8.00521 5.68931 7.76542 5.1087 7.3386 4.67945C6.91177 4.2502 6.33253 4.00713 5.7272 4.00324C5.27469 4.00154 4.83184 4.13412 4.4547 4.3842C4.07757 4.63428 3.78309 4.99061 3.60854 5.40811C3.434 5.82561 3.38723 6.28551 3.47415 6.7296C3.56107 7.17369 3.77778 7.58201 4.09685 7.90289C4.41592 8.22377 4.82301 8.44279 5.2666 8.53223C5.71019 8.62166 6.17034 8.57749 6.58882 8.40531C7.0073 8.23313 7.3653 7.94068 7.61751 7.56497ZM4.87316 4.65861L7.32203 6.29044L4.87316 7.92251V4.65861Z" fill="#9D9D9D" />
                </svg>
                <Typography sx={{ fontSize: "10px" }}>Lesson 1. Lorem ipsum dolor sit amet.</Typography>
                <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.66667L3.52525 7.19191C3.60335 7.27002 3.72998 7.27002 3.80809 7.19191L10 1" stroke="#007848" stroke-width="2" stroke-linecap="round" />
                </svg>
            </Box>
        </Box>
        <Box sx={{ width: "auto" }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#007C84" />
                <path d="M11.0231 6.27374C11.1296 6.15472 11.3057 6.09521 11.5515 6.09521C11.7154 6.09521 11.8628 6.12497 11.9939 6.18448C12.125 6.23973 12.1905 6.30774 12.1905 6.3885C12.1905 6.41825 12.17 6.45863 12.1291 6.50964L8.66364 10.4499C8.60629 10.5179 8.51618 10.571 8.39329 10.6093C8.2704 10.6475 8.13932 10.6666 8.00005 10.6666C7.86077 10.6666 7.7256 10.6475 7.59452 10.6093C7.47163 10.571 7.38151 10.5179 7.32416 10.4499L3.87101 6.50964C3.83005 6.45863 3.80957 6.41825 3.80957 6.3885C3.80957 6.30349 3.87511 6.23336 4.00619 6.1781C4.14546 6.12284 4.29703 6.09521 4.46088 6.09521C4.71484 6.09521 4.89508 6.15472 5.00158 6.27374L8.01234 9.77404L11.0231 6.27374Z" fill="white" />
            </svg>
        </Box>
    </Box>
</Box> */
}
