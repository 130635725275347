import { Box, Container, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../../../Firebase";
import {
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Navbar/Navbar.css";
// import { useTranslation } from "react-i18next";
import "../../Translater/Translater";
import pp from "../img/navbar/profilePicture.svg";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import ResponsiveSidebar from "../Sidebar/ResponsiveSidebar";
import NotificationIcon from "./NotificationIcon";
import { IP } from "../../../config";
import { deleteCookie, getCookie } from "../../../Cookies";
import axios from "axios";

const ResponsiveNavbar = ({ page }) => {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();
  const fetchNotification = httpsCallable(functions, "fetchNotification");
  const [showPopUp, setShowPopUp] = useState(false);
  const [notifications, setNotifications] = useState(null);
  //================user details====================//

  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const [imgshow, setImgshow] = useState(false);
  const [name, setName] = useState("");
  const [empId, setempId] = useState("");
  const [notificationsCount, setNotificationsCount] = useState("");
  useEffect(() => {
    setName(localStorage.getItem("NAME")); //setting name of user
    setempId(localStorage.getItem("EMPID"));
    // fetchUserProfile({})
    //   .then((res) => {
    //     setImgshow(res.data?.photoURL); //setting url of image
    //     setName(res.data?.name); //setting name of user
    //     setempId(res.data?.employeeId); //setting empId of user
    //     // //console.log(res.data);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    let configCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile/userNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configCertificate).then((res) => {
      if (res.error) {
        return;
      }
      setNotifications(
        res.data.sort((a, b) => b?.timestamp?._seconds - a?.timestamp?._seconds)
      );
      const temparr = res.data.filter((ele) => ele?.read === false);

      // console.log(temparr);

      setNotificationsCount(temparr.length > 0 ? temparr.length : "");
    });
  }, []);

  useEffect(() => {
    if (
      notifications &&
      notifications[0]?.title == "You are archived from the portal"
    ) {
      deleteCookie();

      window.open(`${window.location.origin}`, "_self");
    }
  }, [notifications]);
  return (
    <Box
      sx={{
        py: "15px",
        background: "#FFFFFF",
        boxShadow:
          "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
        borderRadius: "2px",
      }}
    >
      <Container sx={flexBetweenWithItemsCenter}>
        <Box sx={{ ...itemsCenter, gap: "20px" }}>
          <ResponsiveSidebar />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#00495F",
              textTransform: "capitalize",
            }}
          >
            {page}
          </Typography>
        </Box>
        <Box sx={{ ...itemsCenter, gap: "25px" }}>
          <Box sx={{ width: "40px", height: "40px" }}>
            {/* <img src={pp} alt="loading" /> */}
            <img
              src={imgshow ? imgshow : pp}
              alt="loading"
              style={{ width: "100%", height: "100%", borderRadius: "20%" }}
            />
          </Box>

          <Box>
            <NotificationPopup
              notifications={notifications}
              notificationsCount={notificationsCount}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ResponsiveNavbar;
