import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createRef,
  useCallback,
} from "react";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as Play } from "./icons/play.svg";
import { ReactComponent as Pause } from "./icons/pause.svg";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../Firebase";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";

const VideoPlayer = ({ videoURL, itemId, setCanNavigate }) => {
  // const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [duration, setDuration] = useState(null);
  const { courseId, isLinear, sample } = useContext(ItemContext);
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const setVideoProgress = httpsCallable(functions, "setVideoProgress");
  const [progressFetched, setProgressFetched] = useState(false);
  const [progress, setProgress] = useState();
  const [percent, setPercent] = useState(0);
  const playerRef = useRef();
  const progressRef = createRef();
  progressRef.current = progress;

  const handleTabChange = (event) => {
    if (document.visibilityState == "visible") {
      // setIsPlaying(true);
      //console.log("tab is active");
    } else {
      setIsPlaying(false);
      //console.log("tab is inactive");
    }
  };
  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleProgress = (event) => {
    // //console.log(event.playedSeconds);
    if (event.playedSeconds > 0) setProgress(event.playedSeconds);
    let currentProgress = Math.floor((event.playedSeconds / duration) * 100);
    setPercent(currentProgress);
  };

  const handleOnReady = () => {
    //console.log("Time start", progress);
    playerRef.current.seekTo(progress, "seconds");
  };

  const resetProgress = (secs) => {
    playerRef.current.seekTo(secs);
    // setProgress(Math.floor((secs / duration) * 100));
  };

  useEffect(() => {
    const COMPLETE_PERCENT = 20;
    if (percent > 0 && percent % 10 == 0 && !sample) {
      // setVideoProgress({});

      let itemProgress = {
        method: "post",
        // params: { courseId: courseId, itemId: itemId },
        url: `${IP}/userCourseContent/video`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
          lastDuration: progress,
          completed: Boolean(percent >= COMPLETE_PERCENT),
        }),
      };
      axios(itemProgress);
      if (percent >= COMPLETE_PERCENT) setCompleted(true);
    }
  }, [percent]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleTabChange);

    // getItemProgress({ courseId: courseId, itemId: itemId })

    let itemProgress = {
      method: "get",
      params: { courseId: courseId, itemId: itemId },
      url: `${IP}/userProgress/items`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(itemProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      if (result.data.lastDuration != undefined) {
        //console.log(result.data.lastDuration);
        setProgress(result.data.lastDuration);

        //console.log("seek video");
      }
      setProgressFetched(true);
    });

    // very important to remove event listner when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
    };
  }, []);

  useEffect(() => {
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  return (
    <div className="videoPlayer-container">
      {progressFetched && (
        <ReactPlayer
          ref={playerRef}
          url={videoURL}
          playing={isPlaying}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          controls={false}
          onDuration={(num) => setDuration(num)}
          onReady={handleOnReady}
          width={"100%"}
        />
      )}
      <div className="videoPlayer-controls">
        {isPlaying ? (
          <Pause onClick={handlePlayPause} className="videoPlayer-playPause" />
        ) : (
          <Play onClick={handlePlayPause} className="videoPlayer-playPause" />
        )}
        <button
          onClick={() => resetProgress(0)}
          className="videoPlayer-resetProgress"
        >
          Reset Progress
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
