import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import jsPDF from "jspdf";
import goss from "./goss.png";
import moraikh from "./moraikh.png";
import alghat from "./alghat.png";
import ocasco from "./ocasco.png";

// import { functions } from "../../../../Firebase";
import { CSVLink } from "react-csv";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import LoadingScreen from "../Builder/loading";
import GenerateReportConfirmationPopUp from "./reportConfirmationPopUp";
const GenerateReportForLocal = (props) => {
  const {
    onClose,
    open,
    reportId,
    data,
    type,
    selectAllEmployee,
    currentUrl,
    trainingType,
    reportTypeBasedOnAllAndCurrent,
    filterStates,
  } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [dataTemp, setdataTemp] = useState([]);
  const [conformationPopUp, setConformationPopUp] = useState(false);
  const [departmentObj, setDepartmentObj] = useState({});
  const [companyObj, setCompanyObj] = useState({});
  const CSVRef = React.useRef();

  let navigater = useNavigate();

  useEffect(async () => {
    console.log("useEffect", selectAllEmployee);
    setdataTemp(data);

    if (reportTypeBasedOnAllAndCurrent == "allpage") {
      if (open) {
        if (type != "employee") {
          // setConformationPopUp(selectAllEmployee);
          getCompanyList();
          getDepartmentList();
        }

        await generateReportsFunction();
      }
    }
    // if (selectAllEmployee) {
    //   if (open) {
    //     if (type != "employee") {
    //       setConformationPopUp(selectAllEmployee);
    //       getCompanyList();
    //       getDepartmentList();
    //     }

    //     // await generateReportsFunction();
    //   }
    // }
  }, [data, selectAllEmployee]);

  const getCompanyList = () => {};

  const getDepartmentList = () => {};
  const generateReportsFunction = async () => {
    // console.log("ndsmfb");
    // let tempreportDataArr = details.filter((e) => e.checked == true);
    //console.log(tempreportDataArr)
    setLoading(true);

    let configCom = {
      method: "get",
      // params: query,
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    let companyres = await axios(configCom);
    let comObj = {};
    companyres.data.map((el) => {
      comObj[el.companyId] = el.companyName;
    });
    let configDeart = {
      method: "get",
      // params: query,
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    let depart = await axios(configDeart);
    // .then((res) => {
    //   res.data.map((el) => {
    //     setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
    //   });
    // });
    let departObj = {};
    depart.data.map((el) => {
      departObj[el.departmentId] = el.name;
    });

    console.log(IP);
    let url = `${IP}/reports`;
    if (type == "group") {
      url = `${IP}/reports/group`;
    }
    if (type == "employee") {
      // url = url + "/employee";
      url = currentUrl;
    }
    let query = {};
    if (type == "company") {
      query["courseId"] = filterStates.courseName;
    }
    let configLesson = {
      method: "get",
      params: {
        all: true,
        trainingType: trainingType,
        ...filterStates,
        ...query,
      },
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    let tempreportDataArr = await axios(configLesson);
    let reportsDataArr = [];
    tempreportDataArr.data.data.map((e) => {
      let milisecon = e?.joiningDate?._seconds * 1000;
      let year = new Date(milisecon).getFullYear();
      let months = new Date(milisecon).getMonth();
      let days = new Date(milisecon).getDate();
      let fulldate = "";
      if (months < 9) {
        months = Number(months) + 1;
        months = "0" + months;
      } else {
        months = Number(months) + 1;
      }
      if (days <= 9) {
        days = "0" + days;
      } else {
        days = Number(days);
      }
      fulldate = `${year}-${months}-${days}`;
      // //console.log(fulldate,selestedDate)

      //console.log(e)

      let tempObj = {
        joiningDate: fulldate,
        email: e.email,
        role: e.role,
        name: e.name,
        jobTitle: e.jobTitle ? e.jobTitle : "",
        company: comObj[e.companyId],
        department: departObj[e.departmentId],

        courseName: e.courseName ? e.courseName : "",

        certificate_issuedOn: e.issued
          ? e.issued
          : // ? new Date(e.issuedOn?._seconds * 1000).toISOString().split("T")[0]
            "NaN",
        certificate_expiry: e.expiry
          ? e.expiry
          : // ? new Date(e.expiry?._seconds * 1000).toISOString().split("T")[0]
            "NaN",

        course_dueDate: e.dueDate
          ? new Date(e.dueDate?._seconds * 1000).toISOString().split("T")[0]
          : "NaN",
        status: e.status,
      };
      reportsDataArr.push(tempObj);
    });
    setLoading(false);
    setdataTemp(reportsDataArr);
    return reportsDataArr;
    //console.log(reportsDataArr)
    // "data":{},
    // "reportType": "",
    // "id": ""
  };

  const runGenerateCertificateTraining = async () => {
    // console.log(data);const

    let tempData = dataTemp;

    const name = type;

    const pdfName =
      name +
      " Report" +
      "   Date (dd/mm/yyyy):" +
      new Date().toLocaleDateString();
    const pdf = new jsPDF("p", "pt", "a2");

    const columns = Object.keys(tempData[0]);
    var rows = [];
    //   console.log({ rows });
    tempData.forEach((e, index) => {
      const createDate = new Date(e.createdAt).toLocaleDateString();
      const updatedDate = new Date(e.updatedAt).toLocaleDateString();
      const status = e.status ? "Active" : "InActive";
      var temp = Object.values(e);

      rows.push(temp);
    });
    pdf.text(500, 30, pdfName);
    // var img = new Image();
    // img.src = img;
    // console.log(img.src);
    pdf.addImage(goss, "png", 50, 50, 300, 100);
    pdf.addImage(moraikh, "png", 350, 50, 250, 100);
    pdf.addImage(ocasco, "png", 600, 50, 250, 100);
    pdf.addImage(alghat, "png", 850, 50, 250, 100);

    // pdf.addImage(

    //   400,
    //   40
    // );
    pdf.autoTable(columns, rows, {
      startY: 180,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 10,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("dataTempuristring"));
    let rses = pdf.save(name + Date.now());

    console.log(pdf.output("bloburl"));
    // console.log(rows);
    // console.log(master);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "250px",
          padding: "16px",
        },
      }}
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <GenerateReportConfirmationPopUp
        open={conformationPopUp}
        onClose={() => {
          setConformationPopUp(false);
        }}
        generateReportsFunction={async () => {
          // console.log("jbhnm");
          setLoading(true);
          await generateReportsFunction();
        }}
      />
      <LoadingScreen open={loading} />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Download </p>

        <button
          onClick={() => {
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>

      <div style={{ margin: "20px" }}>Download report in any format</div>
      <Box
        sx={{
          padding: "16px 24px",
          display: "flex",
          gap: "20px",
        }}
      >
        {/* <div
          style={{
            alignItems: "center",
          }}
        >
          <button>PDF</button>
          <button>EXEL</button>
          <button>CSV</button>
        </div> */}
        <CSVLink ref={CSVRef} filename={type + Date.now()} data={dataTemp}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: "1em",
              color: "#fff",
              width: "100px",
              background: "#11047A",
              borderRadius: "70px",
            }}
          >
            CSV
          </Button>
        </CSVLink>
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={() => {
            const fileName = type;
            const exportType = "xls";
            const test = exportFromJSON({
              data: dataTemp,
              fileName,
              exportType,
            });

            console.log(test);
          }}
        >
          EXCEL
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          PDF
        </Button>
      </Box>
    </Dialog>
  );
};

export default GenerateReportForLocal;
