import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import "../../styles/CourseLibrary/CourseLibraryMain.css";
import "../../styles/UserReports/UserReports.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";

function UserReports() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return (<></>);
    }
  })
  const { t } = useTranslation();

  return (
    <div className="reports">
      <Navbar page={t("UserReports")} />
      <div>
        
      </div>
    </div>
  );
}

export default UserReports;
