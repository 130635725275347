//import * as functions from 'firebase-functions';
import React, { useState, useEffect, useRef } from "react";
import styledComponents from "styled-components";
import "../style/User.css";
import { collection, addDoc } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import {
  isSignInWithEmailLink,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
} from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../Cookies";
import { auth, db, functions } from "../../Firebase";
import { t } from "i18next";
import Sidebar from "../Component/Sidebar";
import Navbar from "../Component/NavbarTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { display } from "@mui/system";
import { IP } from "../../config";
import axios from "axios";

const AddNewUser = ({ handleLogout }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const spanStyle = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "-0.2vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const spanStyleINS = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "0.4vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const location = useLocation();
  const editUserState = location.state;
  ////console.log(editUserState);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [isUSER, setIsUSER] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [departmentList, setDepartmentList] = useState(null);
  const [isINSTRUCTOR, setIsINSTRUCTOR] = useState(false);
  const [isADMIN, setIsADMIN] = useState(false);
  //const [disabledButt,setDisabledButt]=useState(false)
  let disabledButt = false;
  const createUser = httpsCallable(functions, "createUser");

  const updateUser = httpsCallable(functions, "updateUser");
  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchCompany = httpsCallable(functions, "fetchCompany");

  if (editUserState) {
    //setDisabledButt(true)
    disabledButt = true;
  }

  ////console.log(location.state);
  const handleMouseOver = (data) => {
    if (data == "USER") {
      setIsUSER(true);
    } else if (data == "ADMIN") {
      setIsADMIN(true);
    } else if (data == "INSTRUCTOR") {
      setIsINSTRUCTOR(true);
    }
  };

  const handleMouseOut = (data) => {
    if (data == "USER") {
      setIsUSER(false);
    } else if (data == "ADMIN") {
      setIsADMIN(false);
    } else if (data == "INSTRUCTOR") {
      setIsINSTRUCTOR(false);
    }
  };
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  }, []);
  const goToUser = () => navigate("/admin/users");

  const [fullname, setFullName] = useState("");
  const [company, setCompany] = useState("default");
  const [companyDetails, setCompanyDetails] = useState(null);
  const [department, setDepartment] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [joiningDate, setJoiningDate] = useState([]);
  const [email, setEmail] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [role, setRole] = useState([]);
  const [error, setError] = useState();
  const [signData, setSignData] = useState({ base64: "", url: "" });
  const [toggelpage, settogalpage] = useState(true);
  const inputRef = useRef(null);
  const onImageClick = () => {
    inputRef.current.click();
  };

  const imgValidater = (e) => {
    var selectedFile = e.target.files[0];
    var reader = new FileReader();

    // Read the first 8 bytes of the file as an ArrayBuffer
    reader.readAsArrayBuffer(selectedFile.slice(0, 8));
    let retval = "";
    reader.onloadend = function () {
      var arr = new Uint8Array(reader.result);

      // Check if the file has the PNG magic number
      if (
        arr[0] === 137 &&
        arr[1] === 80 &&
        arr[2] === 78 &&
        arr[3] === 71 &&
        arr[4] === 13 &&
        arr[5] === 10 &&
        arr[6] === 26 &&
        arr[7] === 10
      ) {
        // alert("File is a PNG image.");
        handleImageFinal(e);
        return true;
      } else {
        alert("File is not a PNG image. Please select a PNG file.");
        return false;
      }
    };
  };

  const handleImageFinal = (e) => {
    // //console.log("aaa", e);
    let reader = new FileReader();

    let filenameType = e.target.value.split(".");
    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "png") {
    } else {
      alert("only png  file accepted");
      e.target.value = null;

      return;
    }
    reader.onload = () => {
      if (reader.result.length > 1000000) {
        // alert if size > 1 MB
        alert("Image Too large, Update and try again.");
        return;
      }
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        // //console.log(Number(width / height));
        // //console.log(Number(width / height) != 1.5);
        if (Number(width / height) <= 1.4 || Number(width / height) >= 1.6) {
          alert(`Aspect Ratio must be 3:2. Update and try again.`);
          return false;
        }
        // alert("Uploaded image has valid Height and Width.");

        setSignData({
          base64: reader.result,
          url: URL.createObjectURL(e.target.files[0]),
        });
        return true;
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleImage = (e) => {
    // //console.log("aaa", e);
    imgValidater(e);
  };

  useEffect(() => {
    if (editUserState) {
      setFullName(editUserState.name);
      setCompany(editUserState.companyId);
      setCompanyDetails(null);
      setDepartment(editUserState.departmentId);
      setJobTitle(editUserState.jobTitle);
      setJoiningDate(seconedTohumanDate);
      setEmail(editUserState.email);
      setEmployeeId(editUserState.employeeId);
      setRole(editUserState.role);
      setSignData({ base64: "", url: editUserState.signUrl });
    }
  }, []);

  var seconedTohumanDate;
  if (location.state) {
    let milisecond = Number(location.state.joiningDate._seconds) * 1000;
    let datamethod = new Date(milisecond).getDate();
    let getmounth = new Date(milisecond).getMonth();
    let getyerr = new Date(milisecond).getFullYear();

    if (getmounth < 9) {
      getmounth = `0${getmounth + 1}`;
      // seconedTohumanDate = `${getyerr}-0${getmounth + 1}-${datamethod}`;
    } else {
      getmounth = getmounth + 1;
      // seconedTohumanDate = `${getyerr}-${getmounth + 1}-${datamethod}`;
    }
    if (datamethod <= 9) {
      datamethod = `0${datamethod}`;
    }
    seconedTohumanDate = `${getyerr}-${getmounth}-${datamethod}`;
    ////console.log(seconedTohumanDate);
  }

  const allIsWell = () => {
    setError("");
    if (fullname == "") {
      setError("Full Name");
      return false;
    } else if (company == "default") {
      setError("Company");
      return false;
    } else if (department == "") {
      setError("Department");
      return false;
    } else if (jobTitle == "") {
      setError("Job Title");
      return false;
    } else if (joiningDate == "") {
      setError("Joining Date");
      return false;
    } else if (email == "") {
      setError("Email");
      return false;
    } else if (employeeId == "") {
      setError("Employee ID");
      return false;
    } else if (role == "") {
      setError("Role");
      return false;
    } else if (role != "USER" && signData.url == "") {
      setError("Signature");
      return false;
    }

    return true;
  };

  const handleCreateUser = () => {
    // buttendesiblefunction()

    if (!allIsWell()) return;

    setButtonDisable(false);
    if (editUserState) {
      let configLesson = {
        method: "patch",
        // params: { uid: item.userId },
        url: `${IP}/adminUserProfile`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          uid: editUserState.userId,
          name: fullname,
          email: email.toLowerCase().trim(),
          companyId: company,
          role: role,
          joiningDate: joiningDate,
          employeeId: employeeId,

          instructorSignature: signData.base64,
          jobTitle: jobTitle,
          departmentId: department,
        }),
      };
      axios(configLesson)
        .then((res) => {
          // //console.log(res);
          setButtonDisable(true);
          if (res.data.message == "success") {
            alert("user updation successfully");
            goToUser();
          } else {
            setError("User Updation Failed!!");
          }
        })
        .catch((err) => {
          ////console.log(err);
          setButtonDisable(true);
        });
    } else {
      let configLesson = {
        method: "post",
        // params: { uid: item.userId },
        url: `${IP}/adminUserProfile`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          joiningDate: joiningDate,
          name: fullname,
          companyId: company,
          email: email.trim(),
          role: role,
          employeeId: employeeId,
          jobTitle: jobTitle,
          departmentId: department,
          instructorSignature: signData.base64,
        }),
      };
      axios(configLesson)
        .then((res) => {
          // //console.log(res);
          setButtonDisable(true);
          if (res.data.message == "Success") {
            setError("Success");
            // sendPasswordResetEmail(auth, email);

            // mail/sendMailAddedtoPortal

            // let sendMailAddedtoPortal = {
            //   method: "post",
            //   // params: { uid: item.userId },
            //   url: `${IP}/mail/sendMailAddedtoPortal`,

            //   headers: {
            //     "Content-Type": "application/json",
            //     Authorization: `Bearer ${getCookie("bearer")}`,
            //   },
            //   data: JSON.stringify({
            //     name: fullname,
            //     company: companyDetails.filet(
            //       (el) => el.companyId == company
            //     )[0].companyName,
            //     email: email.trim(),
            //     role: role,
            //     employeeId: employeeId,
            //     jobtitle: jobTitle,
            //     department: departmentList.filet(
            //       (el) => el.departmentId == department
            //     )[0].name,
            //   }),
            // };
            // console.log("sjhshj");
            // axios(sendMailAddedtoPortal)
            //   .then((re) => {
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            goToUser();

            alert("user created successfully");
          } else {
            setError("User Creation Failed!!");
          }
        })
        .catch((err) => {
          setError(err.response?.data?.message);

          setButtonDisable(true);
        });
    }
  };

  useEffect(() => {
    let configLesson = {
      method: "get",
      // params: { uid: item.userId },
      url: `${IP}/common/department`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configLesson).then((res) => {
      if (res.data.error) {
        return;
      }
      setDepartmentList(res.data);
    });
    let configCompany = {
      method: "get",
      // params: { uid: item.userId },
      url: `${IP}/common/company`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configCompany).then((res) => {
      if (res.data.error) {
        return;
      }
      setCompanyDetails(res.data);
    });
    // db.collection("company")
    //   .get()
    //   .then((snapshot) => {
    //     var comp = [];
    //     snapshot.forEach((company) => {
    //       let temp = {
    //         name: company.data().name,
    //         id: company.id,
    //       };
    //       comp.push(temp);
    //     });
    //     setCompanyDetails(comp);
    //   });
  }, []);
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("User")} />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />

        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("AddNewUser/EditUser")}
            </div>
          </div>
          <div
            className="admin-course-main-top"
            style={{ margin: "2vw", padding: "1vw 2vw 4vw 2vw" }}
          >
            <div>
              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("FullName")} *</p>
                <input
                  type="text"
                  id="name"
                  className="admin-course-main-top-input"
                  value={fullname}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                />
                {/* <span style={{color:"red", display:"inline"}} > {error && <span>{error} Not Filled</span>}</span> */}
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Company")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="company"
                  value={company}
                  onChange={(event) => {
                    setCompany(event.target.value);
                  }}
                >
                  <option value={"default"}>Select Company</option>
                  {companyDetails?.map((value, index) => (
                    <option key={index} value={value.companyId}>
                      {value.companyName}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Department")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="company"
                  value={department}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                >
                  <option value={"default"}>Select Department</option>
                  {departmentList?.map((value, index) => (
                    <option key={index} value={value.departmentId}>
                      {value.name}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  id="department"
                  className="admin-course-main-top-input"
                  value={department}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                /> */}
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("JobTitle")} *</p>
                <input
                  type="text"
                  id="jobtitle"
                  className="admin-course-main-top-input"
                  value={jobTitle}
                  onChange={(event) => {
                    setJobTitle(event.target.value);
                    // setJobTitle(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("JoiningDate")} *</p>
                <input
                  type="date"
                  id="joiningdate"
                  value={joiningDate}
                  // disabled={disabledButt}
                  className="admin-course-main-top-input"
                  onChange={(event) => {
                    setJoiningDate(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("Email")} *</p>
                <input
                  type="text"
                  id="email"
                  value={email}
                  className="admin-course-main-top-input"
                  onChange={(event) => {
                    // location.state.email?setEmail(location.state.email):
                    setEmail(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("EmployeeID")}</p>
                <input
                  type="text"
                  id="employeeid"
                  className="admin-course-main-top-input"
                  // disabled={disabledButt}
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <div
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  className="label"
                >
                  {t("Role")} *
                </div>
                <div style={{ fontSize: "0.9vw" }}>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="ADMIN"
                      name="role"
                      checked={role == "ADMIN" ? true : false}
                    />
                    {t("ADMIN")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("ADMIN");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("ADMIN");
                      }}
                      style={{
                        background: isADMIN ? "black" : "white",
                        marginLeft: "3vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isADMIN && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS} className="spanData">
                          {t("ADMINIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="USER"
                      name="role"
                      checked={role == "USER" ? true : false}
                    />{" "}
                    {t("USER")}{" "}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("USER");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("USER");
                      }}
                      style={{
                        background: isUSER ? "black" : "white",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isUSER && (
                      <>
                        <span style={{ fontSize: "20px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyle} className="spanData">
                          {t("USERIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="INSTRUCTOR"
                      name="role"
                      checked={role == "INSTRUCTOR" ? true : false}
                    />{" "}
                    {t("INSTRUCTOR")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("INSTRUCTOR");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("INSTRUCTOR");
                      }}
                      style={{
                        background: isINSTRUCTOR ? "black" : "white",
                        marginLeft: "2vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isINSTRUCTOR && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS}>{t("INSTRUCTORIns")}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {role != "USER" && (
                <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                  <p>{t("Signature")}*</p>
                  {!signData.url ? (
                    <div
                      style={{
                        width: "20vw",
                        height: "8em",
                        background: "#d5d5d5",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                    >
                      Click to upload Image
                    </div>
                  ) : (
                    <img
                      style={{
                        width: "20vw",
                        height: "8em",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                      src={signData.url}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleImage}
                    ref={inputRef}
                    accept="image/png"
                    style={{ display: "none" }}
                  />
                </div>
              )}

              {/* <div className="btn">
                <button onClick={sendmail}> Save </button>

                <button> Cancel </button>
              </div> */}
              {error && <div style={{ color: "red" }}>{error} </div>}
              <div
                style={{ justifyContent: "center" }}
                className="admin-dashboard-main-mid-item-last-filter-notify"
              >
                <div className="admin-dashboard-main-top-last-filter-notify">
                  {buttonDisable ? (
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      onClick={handleCreateUser}
                    >
                      <div>{t("Save")} </div>
                    </button>
                  ) : (
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      disabled={true}
                    >
                      <div>{t("Loading")} </div>
                    </button>
                  )}

                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <div>{t("Cancel")}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              onClick={() => navigate(-1)}
            >
              {t("Back")}
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
