import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import { ReactComponent as DownloadIcon } from "./downloadIcon.svg";
import { ReactComponent as FileIcon } from "./fileIcon.svg";

import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import { IP } from "../../../config";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function OfflineViewMaterials() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const fetchCourseCertificate = httpsCallable(
    functions,
    "fetchCourseCertificate"
  );
  const getNotesAndMaterials = httpsCallable(functions, "getNotesAndMaterials");
  const location = useLocation();
  const courseId = location.state.courseId;
  const CourseName = location.state.courseName;
  /////======= fetch user courses
  const [notesAndMaterials, setNotesAndMaterials] = useState();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // fetchCourseCertificate({ courseId: courseId })

    let cirtiCOnfig = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userCourse/courseCertificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(cirtiCOnfig).then((res) => {
      //console.log("Cert", res);
      if (res.data.error) return;
      setCertificate(res.data);
    });
    setLoading(true);
    getNotesAndMaterials({
      select: 2,
      courseId: courseId,
    })
      .then((res) => {
        //console.log(res.data);
        setNotesAndMaterials(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  });
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t(`Training > Internal offline training > Materials`)} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar
          page={t(`Training > Internal offline training > Materials`)}
        />
      </Box>
      <Box sx={{ flexGrow: 1, my: 5 }}>
        <Container>
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{
                fontFamily: "'Readex Pro', sans-serif",
                fontWeight: 600,
                fontSize: "22px",
                color: "#00495F",
              }}
            >
              {CourseName}
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{
                fontFamily: "'Readex Pro', sans-serif",
                fontWeight: 600,
                fontSize: "22px",
                color: "#00495F",
              }}
            >
              Materials
            </Typography>
          </Box>
          {!loading && (
            <Grid container spacing={4}>
              {(() => {
                if (
                  notesAndMaterials?.materials &&
                  notesAndMaterials?.materials.length != 0
                ) {
                  return notesAndMaterials?.materials?.map((item, id) => {
                    return (
                      <Grid item md={6} xs={12} key={id}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            padding: "1em",
                            background: "#FFFF",
                            borderRadius: "5px",
                            boxShadow:
                              "4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
                          }}
                        >
                          <FileIcon />

                          <Typography
                            sx={{
                              fontFamily: "'Readex Pro', sans-serif",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "1em",
                              margin: "1em",
                              lineHeight: "33px",
                              /* identical to box height */

                              color: " #00495F",
                            }}
                          >
                            {" "}
                            {item.name}
                          </Typography>
                          <DownloadIcon
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                              window.open(
                                item.link,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  });
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        p: "1em",
                        height: "100px",
                        marginRight: "3em",
                      }}
                    >
                      <Typography>No Materials Found!</Typography>
                    </Box>
                  );
                }
              })()}
            </Grid>
          )}
        </Container>

        <Container sx={{ mt: 3 }}>
          <Box>
            <Typography
              sx={{
                fontFamily: "'Readex Pro', sans-serif",
                fontWeight: 600,
                fontSize: "22px",
                color: "#00495F",
                mb: 2,
              }}
            >
              Notes
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {(() => {
              if (
                notesAndMaterials?.notes &&
                notesAndMaterials?.notes.length != 0
              ) {
                return notesAndMaterials?.notes?.map((item, id) => {
                  return (
                    <Grid item md={6} xs={12}>
                      <Box
                        key={id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          padding: "1em",
                          backGround: "#FFFF",
                          borderRadius: "5px",
                          boxShadow:
                            "4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
                        }}
                      >
                        <FileIcon />

                        <Typography
                          sx={{
                            fontFamily: "'Readex Pro', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "1em",
                            margin: "1em",
                            lineHeight: "33px",
                            /* identical to box height */
                            color: " #00495F",
                          }}
                        >
                          {" "}
                          {item.fileName}
                        </Typography>
                        <DownloadIcon
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            window.open(
                              item.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                  );
                });
              } else {
                return (
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography>No Materials Found!</Typography>
                  </Box>
                );
              }
            })()}
          </Grid>
        </Container>
        {loading && (
          <Box
            container
            spacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 10, alignItems: "center" }}
          >
            <div className="admin-overdue-bottom-table-cnt-c ">
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td">
                <div className="spinner-container">
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "10px solid #f3f3f3" /* Light grey */,
                      borderTop: "10px solid #007C84" /* Black */,
                      borderRadius: "50%",
                      animation: "spinner 1.5s linear infinite",
                      marginBottom: "200px",
                      marginTop: "120px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </Box>
        )}
        {certificate && (
          <Container sx={{ mt: 4, textAlign: "center" }}>
            <Button
              sx={{
                fontFamily: "'Readex Pro', sans-serif",
                fontWeight: 300,
                fontSize: "16px",
                padding: "5px 10px",
                color: "#FFF",
                cursor: "pointer",
                borderRadius: "5px",
                background: (theme) => theme.palette.primary.main,
                "&:hover": {
                  background: (theme) => theme.palette.primary.main,
                },
              }}
              onClick={() =>
                window.open(certificate.certificateDownloadURL, "_blank")
              }
            >
              Download Certificate
            </Button>
          </Container>
        )}
      </Box>
    </Box>
  );
}

export default OfflineViewMaterials;
