// export const defultGreen = {
//   SidebariconClr: "#188775",
//   SidebarcntClr: "#E6FCF5",
//   SidebartxtClr: "#188775",
//   EnrolBtnClr: "#007848",

// };

// export const blue = {
//   SidebariconClr: "rgb(36 24 135)",
//   SidebarcntClr: "hwb(224deg 78% 3%)",
//   SidebartxtClr: "rgb(1 5 16)",
//   EnrolBtnClr: "rgb(36 24 135)",
// };

export const defultGreen = {
  SidebariconClr: "#188775",
  SidebarcntClr: "#E6FCF5",
  SidebartxtClr: "#188775",
  EnrolBtnClr: "#007848",
  NavbarTxtClr: "black",
  DefSidebariconClr: "#D7D7D7",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#717579",
};

export const darkblue = {
  SidebariconClr: "rgba(0, 69, 119, 1)",
  SidebarcntClr: "rgba(0, 69, 119, 0.2)",
  SidebartxtClr: "rgba(0, 69, 119, 1)",
  EnrolBtnClr: "#007848",
  NavbarTxtClr: "black",
  DefSidebariconClr: "#D7D7D7",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#717579",
};

export const blue = {
  SidebariconClr: "rgb(36 24 135)",
  SidebarcntClr: "hwb(224deg 78% 3%)",
  SidebartxtClr: "rgb(1 5 16)",
  EnrolBtnClr: "rgb(36 24 135)",
  NavbarTxtClr: "rgba(0, 73, 95, 1)",
  CourseListBtn: "rgb(36 24 135)",
  DefSidebariconClr: "#D7D7D7",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#717579",
};

export const GradiantGreen = {
  SidebariconClr: "white",
  SidebarcntClr: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
  SidebartxtClr: "white",
  NavbarTxtClr: "rgba(0, 73, 95, 1)",
  CourseListBtn: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
  DefSidebariconClr: "#007C84",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#007C84",
};

export const newGreen = {
  SidebariconClr: "white",
  SidebarcntClr: "#007C84",
  SidebartxtClr: "white",
  NavbarTxtClr: "#00495F",
  CourseListBtn: "#007C84",
  DefSidebariconClr: "#007C84",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#007C84",
};
