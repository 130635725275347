import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import { Button } from "@mui/material";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useLocation, useNavigate } from "react-router";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IP } from "../../../../config";
import { getCookie } from "../../../../Cookies";
import axios from "axios";

export const ViewCompany = () => {
  const navigate = useNavigate();
  const [color, setColor] = useColor("hex", "#121212");
  const location = useLocation();
  const data = location.state;
  //console.log(data);

  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const showimg = image.url || data.logoURL;
  const [base64Image, setBase64Image] = useState("");

  const createCompany = httpsCallable(functions, "createCompany");

  const [details, setDetails] = useState({
    name: "",
    companyTemplate: "",
    brandColor: {
      bgColor: "",
      headingTextColor: "",
      dashboardColor: "",
      bodyTextColor: "",
      primaryBtnColor: "",
      secondaryBtnColor: "",
    },
    companyId: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setDetails({
      ...details,
      brandColor: { ...details.brandColor, [name]: e.target.value },
    });
  };

  // logo change

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      setDetails({ ...details, logo: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    let configCompany = {
      method: "get",
      params: { id: data.companyId },
      url: `${IP}/company/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(details),
    };
    axios(configCompany).then((res) => {
      let data = res.data;
      setDetails({
        name: data.name,
        companyTemplate: data.companyTemplate,
        brandColor: {
          bgColor: data.brandColor.bgColor,
          headingTextColor: data.brandColor.headingTextColor,
          dashboardColor: data.brandColor.dashboardColor,
          bodyTextColor: data.brandColor.bodyTextColor,
          primaryBtnColor: data.brandColor.primaryBtnColor,
          secondaryBtnColor: data.brandColor.secondaryBtnColor,
        },
        companyId: data.companyId,
      });
    });
  }, []);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company And Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                color="success"
                style={{ marginBottom: "20px" }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "100px",
                }}
              >
                <div>
                  <h3>Company Name</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.name}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        name: e.target.value,
                        companyId:
                          e.target.value.split(" ").join("_") +
                          "_" +
                          new Date().getTime().toString().split(" ").join("_"),
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>

                <div>
                  <h3>Company Cirtificate Template</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.companyTemplate}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        name: e.target.value,
                        companyId:
                          e.target.value.split(" ").join("_") +
                          "_" +
                          new Date().getTime().toString().split(" ").join("_"),
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>

                {showimg && (
                  <div style={{ width: "150px", height: "150px" }}>
                    <img
                      src={showimg}
                      style={{ width: "100%", height: "100%" }}
                    />
                    <h3>Company Logo</h3>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <h1 className="bigger-container-header">Brand Details</h1>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h3>Background Color</h3>
                  <input
                    name="bgColor"
                    value={details.brandColor.bgColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Heading Text Color</h3>
                  <input
                    name="headingTextColor"
                    value={details.brandColor.headingTextColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Dashboard Color</h3>
                  <input
                    name="dashboardColor"
                    value={details.brandColor.dashboardColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h3>Body Text Color</h3>
                  <input
                    name="bodyTextColor"
                    value={details.brandColor.bodyTextColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Primary Button Color</h3>
                  <input
                    name="primaryBtnColor"
                    value={details.brandColor.primaryBtnColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Secondary Color</h3>
                  <input
                    name="secondaryBtnColor"
                    value={details.brandColor.secondaryBtnColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorPicker
                  width={456}
                  height={228}
                  color={color}
                  onChange={setColor}
                  hideHSV
                  dark
                />
                ;
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
