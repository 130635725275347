import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TrainingMain() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("training")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("training")} />
      </Box>

      <Box sx={{ flexGrow: 1, mt: 10 }}>
        <Container>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      Internal Training
                    </Typography>
                    <Typography sx={{ color: "#000", mt: 1, fontSize: "12px" }}>
                      Your latest Training
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: (theme) => theme.palette.primary.main,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 0H0V4H20V0ZM4 3H2V1H4V3ZM18.6 10.8C18.4092 10.5564 18.1667 10.3583 17.89 10.22C17.6133 10.0816 17.3093 10.0065 17 10H9.998C9.73331 10 9.47947 10.1051 9.29231 10.2923C9.10515 10.4795 9 10.7333 9 10.998V17.002C9 17.2667 9.10515 17.5205 9.29231 17.7077C9.47947 17.8949 9.73331 18 9.998 18H17C17.3131 18.0161 17.625 17.9509 17.9053 17.8107C18.1857 17.6705 18.425 17.4601 18.6 17.2L21 14L18.6 10.8ZM9.998 8H17C17.5694 8.0092 18.1306 8.13765 18.6473 8.37706C19.164 8.61648 19.6248 8.96154 20 9.39V6H0V10H7.184C7.38909 9.41685 7.76971 8.9115 8.27358 8.55339C8.77744 8.19527 9.37984 8.00196 9.998 8ZM2 9V7H4V9H2ZM7 12H0V16H7V12ZM2 15V13H4V15H2Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="internal-training">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  overflow: "hidden",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      External Training
                    </Typography>
                    <Typography sx={{ color: "#000", mt: 1, fontSize: "12px" }}>
                      Your latest Training
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: (theme) => theme.palette.primary.main,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6667 10.4167V4.16667C16.6667 3.94097 16.5842 3.74566 16.4193 3.58073C16.2543 3.4158 16.059 3.33333 15.8333 3.33333H9.58333C9.21875 3.33333 8.96267 3.5026 8.8151 3.84115C8.66753 4.19705 8.7283 4.50087 8.9974 4.7526L10.8724 6.6276L3.91927 13.5807C3.75434 13.7457 3.67188 13.941 3.67188 14.1667C3.67188 14.3924 3.75434 14.5877 3.91927 14.7526L5.2474 16.0807C5.41233 16.2457 5.60764 16.3281 5.83333 16.3281C6.05903 16.3281 6.25434 16.2457 6.41927 16.0807L13.3724 9.1276L15.2474 11.0026C15.4036 11.1675 15.599 11.25 15.8333 11.25C15.9375 11.25 16.046 11.2283 16.1589 11.1849C16.4974 11.0373 16.6667 10.7812 16.6667 10.4167ZM20 3.75V16.25C20 17.283 19.6332 18.1662 18.8997 18.8997C18.1662 19.6332 17.283 20 16.25 20H3.75C2.71701 20 1.83377 19.6332 1.10026 18.8997C0.366753 18.1662 0 17.283 0 16.25V3.75C0 2.71701 0.366753 1.83377 1.10026 1.10026C1.83377 0.366753 2.71701 0 3.75 0H16.25C17.283 0 18.1662 0.366753 18.8997 1.10026C19.6332 1.83377 20 2.71701 20 3.75Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="external-training">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  overflow: "hidden",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      Internal Offline Training
                    </Typography>
                    <Typography sx={{ color: "#000", mt: 1, fontSize: "12px" }}>
                      Your latest Training
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: (theme) => theme.palette.primary.main,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.81818 18H15.4545C15.9368 18 16.3992 17.7893 16.7402 17.4142C17.0812 17.0391 17.2727 16.5304 17.2727 16V10H13.6364V12L10 9L13.6364 6V8H17.2727V2C17.2727 1.46957 17.0812 0.960859 16.7402 0.585786C16.3992 0.210714 15.9368 0 15.4545 0H1.81818C1.33597 0 0.873508 0.210714 0.532533 0.585786C0.191558 0.960859 0 1.46957 0 2V16C0 16.5304 0.191558 17.0391 0.532533 17.4142C0.873508 17.7893 1.33597 18 1.81818 18ZM1.81818 12H5.45455V14H1.81818V12ZM1.81818 8H8.18182V10H1.81818V8ZM1.81818 4H8.18182V6H1.81818V4ZM17.2727 8H20V10H17.2727V8Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="internal-offline-training">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{
                  boxShadow:
                    "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                  borderRadius: "10px",
                  m: "none",
                  background: "#fff",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "15px",
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#000",
                      }}
                    >
                      Previous Training
                    </Typography>
                    <Typography sx={{ color: "#000", mt: 1, fontSize: "12px" }}>
                      Your latest Training
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: (theme) => theme.palette.primary.main,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 0H0V4H20V0ZM4 3H2V1H4V3ZM18.6 10.8C18.4092 10.5564 18.1667 10.3583 17.89 10.22C17.6133 10.0816 17.3093 10.0065 17 10H9.998C9.73331 10 9.47947 10.1051 9.29231 10.2923C9.10515 10.4795 9 10.7333 9 10.998V17.002C9 17.2667 9.10515 17.5205 9.29231 17.7077C9.47947 17.8949 9.73331 18 9.998 18H17C17.3131 18.0161 17.625 17.9509 17.9053 17.8107C18.1857 17.6705 18.425 17.4601 18.6 17.2L21 14L18.6 10.8ZM9.998 8H17C17.5694 8.0092 18.1306 8.13765 18.6473 8.37706C19.164 8.61648 19.6248 8.96154 20 9.39V6H0V10H7.184C7.38909 9.41685 7.76971 8.9115 8.27358 8.55339C8.77744 8.19527 9.37984 8.00196 9.998 8ZM2 9V7H4V9H2ZM7 12H0V16H7V12ZM2 15V13H4V15H2Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                    <Link to="previous-training">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.main,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                          borderRadius: "15px",
                          fontWeight: 600,
                          mt: "20px",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default TrainingMain;
