import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import jsPDF from "jspdf";
import goss from "./goss.png";
import moraikh from "./moraikh.png";
import alghat from "./alghat.png";
import ocasco from "./ocasco.png";

// import { functions } from "../../../../Firebase";
import { CSVLink } from "react-csv";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import LoadingScreen from "../Builder/loading";
const GenerateReportConfirmationPopUp = (props) => {
  const { onClose, open, generateReportsFunction } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [dataTemp, setdataTemp] = useState([]);
  const [departmentObj, setDepartmentObj] = useState({});
  const [companyObj, setCompanyObj] = useState({});
  const CSVRef = React.useRef();

  let navigater = useNavigate();

  const runGenerateCertificateTraining = (tye) => {
    if (tye == "all") {
      console.log("nbbmmn");
      generateReportsFunction("all");
    }
    handleClose();
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "250px",
          padding: "16px",
        },
      }}
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      {/* <LoadingScreen open={loading} /> */}
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Download </p>

        <button
          onClick={() => {
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>

      <div style={{ margin: "20px" }}>Download report in any format</div>
      <Box
        sx={{
          padding: "16px 24px",
          display: "flex",
          gap: "20px",
        }}
      >
        {/* <div
          style={{
            alignItems: "center",
          }}
        >
          <button>PDF</button>
          <button>EXEL</button>
          <button>CSV</button>
        </div> */}
        {/* <CSVLink ref={CSVRef} filename={type + Date.now()} data={dataTemp}> */}
        {/* <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            width: "100px",
            background: "#11047A",
            borderRadius: "70px",
          }}
        >
          CSV
        </Button> */}
        {/* </CSVLink> */}
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={() => {
            runGenerateCertificateTraining("one");
          }}
        >
          Current page
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={() => {
            runGenerateCertificateTraining("all");
          }}
        >
          All page
        </Button>
      </Box>
    </Dialog>
  );
};

export default GenerateReportConfirmationPopUp;
