import React, { useEffect } from "react";
import { useState } from "react";
import CreateQuiz from "./CreateQuiz";
import $, { hasData } from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Item from "./Item";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { DeleteSVg, EditSvg, ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const CreateLesson = ({
  quizData,
  itemsData,
  topicsData,
  courseId,
  lessonId,
}) => {
  const storage = getStorage();
  //Topic
  const createTopic = httpsCallable(functions, "createTopic");
  const addTopicExtraInfo = httpsCallable(functions, "addTopicExtraInfo");
  const addLessonTopicOrder = httpsCallable(functions, "addLessonTopicOrder");
  const getTopicInfo = httpsCallable(functions, "getTopicInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteTopic = httpsCallable(functions, "deleteTopic");
  const [editQuizArr, setEditQuizArr] = useState([]);
  // deleteTopic
  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [daysAfterTopic, setDaysAfterTopic] = useState(0);
  const [startDateTopic, setStartDateTopic] = useState("");
  const [topicId, setTopicId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckedFailed] = useState(false);
  const [list, setList] = useState([]);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteTopicId, setDeleteTopicId] = useState("");
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    topicName: "",
    topicDescription: "",
  });

  useEffect(() => {
    console.log(topicsData);
    if (quizData) {
      setEditQuizArr(quizData);
    }

    if (!topicsData) return;
    (async () => {
      for (let i = 0; i < topicsData.length; i++) {
        let configCourse = {
          method: "get",
          params: { topicId: topicsData[i] },
          url: `${IP}/userCourseDetails/topic`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          // data: JSON.stringify({
          //   itemId: item.id,
          // }),
        };

        let topicInfo = await axios(configCourse);
        //console.log(topicInfo);
        setList((prev) =>
          prev.concat({
            id: topicInfo.data.topicId,
            name: topicInfo.data.topicName,
            description: topicInfo.data.topicDescription,
            sampleTopic: topicInfo.data.extraInfo.sample,
            hasQuiz: topicInfo.data.extraInfo.hasQuiz,
            schedule: topicInfo.data.extraInfo.schedule,
            start: topicInfo.data.extraInfo.start,
            created: Timestamp.fromMillis(
              topicInfo.data.created._seconds * 1000
            ),
            itemsArr: topicInfo.data.extraInfo.items,
            quizArr: topicInfo.data.extraInfo.quizzes,
          })
        );
      }
    })();
  }, []);

  function checkForm() {
    if (topicName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, topicName: "error" }));
      return "topicName";
    }
    if (topicDescription == "") {
      setCheckErrorInForm((prev) => ({ ...prev, topicDescription: "error" }));
      return "topicDescription";
    }
    return "allIsWell";
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    let configCourse = {
      method: "post",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/addLessonTopicOrder`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        lessonId: lessonId,
        topics: orderList,
        edit: true,
      }),
    };

    let topicInfo = await axios(configCourse);
  }
  var docURL = [];
  var files = [];
  const upload = (pdf, i, link, referDoc) => {
    const metadata = {
      contentType: pdf.type,
    };
    const fileName = pdf.name;
    const storageRef = ref(
      storage,
      link + i + fileName.substring(fileName.lastIndexOf("."))
    );
    const uploadTask = uploadBytesResumable(storageRef, pdf, metadata);
    files.push(topicId + fileName.substring(fileName.lastIndexOf(".")));
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            //console.log("Upload is paused");
            break;
          case "running":
            //console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);
          docURL.push(downloadURL);
          referDoc
            .collection("extraInfo")
            .doc("infoDoc")
            .update({ documentURL: docURL });
        });
      }
    );
  };

  const addTopic = async () => {
    let check = checkForm();

    let nsn = topicName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }

    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }
    setCheckErrorInForm({
      topicName: "",
      topicDescription: "",
    });
    setCheckedFailed(false);
    const schedule = true;

    const newList = list.concat({
      id: topicId,
      name: topicName,
      description: topicDescription,
      sampleTopic: false,
      hasQuiz: true,
      schedule: schedule,
      start: "null",
      created: Timestamp.now(),
    });
    toggle();
    setList(newList);
    saveOrder(newList);
    docURL = [];
    files = [];
    alert("Topic Added");

    let configCourse = {
      method: "post",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        created: new Date().toString(),
        lessonAssociated: lessonId,
        topicDescription: topicDescription,
        topicName: topicName,
        topicId: topicId,
      }),
    };
    await axios(configCourse);

    let configTopicExtra = {
      method: "patch",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        topicId: topicId,
        hasQuiz: true,
        sample: false,
        schedule: schedule,
        start: "today",
      }),
    };
    await axios(configTopicExtra);

    setTopicName("");
    setTopicDescription("");
    setDaysAfterTopic("");
    setStartDateTopic("");
  };

  const toggle = (e) => {
    resetForm();
    document.getElementById(lessonId + "topicForm").style.display =
      document.getElementById(lessonId + "topicForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  function edit(topic) {
    setEditable(true);
    document.getElementById(lessonId + "topicForm").style.display = "";
    setTopicName(topic.name);
    setTopicId(topic.id);
    setTopicDescription(topic.description);
    document.getElementById(lessonId + "topicName").value = topic.name;
    document.getElementById(lessonId + "topicId").value = topic.id;
    document.getElementById(lessonId + "topicDescription").value =
      topic.description;
    document.getElementById(lessonId + "sampleTopic").value = topic.sampleLesson
      ? "yes"
      : "no";
    document.getElementById(lessonId + "hasTopicQuiz").value = topic.hasQuiz
      ? "yes"
      : "no";
  }
  function resetForm() {
    // setEditable(true);
    // document.getElementById(lessonId + "topicForm").style.display = "";
    setTopicName("");
    setTopicId("");
    setTopicDescription("");
    // document.getElementById(lessonId + "topicName").value = topic.name;
    // document.getElementById(lessonId + "topicId").value = topic.id;
    // document.getElementById(lessonId + "topicDescription").value = topic.description;
    // document.getElementById(lessonId + "sampleTopic").value = topic.sampleLesson ? "yes" : "no";
    // document.getElementById(lessonId + "hasTopicQuiz").value = topic.hasQuiz ? "yes" : "no";
    // document.getElementById(lessonId + "releaseSchedule").value = topic.schedule;
    // if (topic.schedule == "enrolment") {
    //   document.getElementById(lessonId + "daysAfter").value = topic.start;
    // } else if (topic.schedule == "specific") {
    //   document.getElementById(lessonId + "startDate").value = topic.start;
    // }
  }

  async function updateTopic() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }
    setCheckedFailed(false);

    const schedule = "date is not entered";

    let newArr = Array.from(list);
    for (let i = 0; i < newArr.length; i++) {
      if (topicId === newArr[i].id) {
        newArr[i] = {
          id: topicId,
          name: topicName,
          description: topicDescription,
          sampleTopic: false,
          hasQuiz: true,

          created: Timestamp.now(),
        };
      }
    }
    setList(newArr);

    docURL = [];
    files = [];
    alert("Topic Added");

    setEditable(false);
    document.getElementById(lessonId + "topicForm").style.display = "none";

    let configCourse = {
      method: "post",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        created: new Date().toString(),
        lessonAssociated: lessonId,
        topicDescription: topicDescription,
        topicName: topicName,
        topicId: topicId,
      }),
    };
    await axios(configCourse);

    let configTopicExtra = {
      method: "patch",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        topicId: topicId,
        hasQuiz: true,
        sample: false,
        schedule: schedule,
        start: "today",
      }),
    };
    await axios(configTopicExtra);

    saveOrder(newArr);
  }

  async function del(topic) {
    setopenLessonPopUp(true);
    setDeleteTopicId(topic);
    // deleteTopic({ topicId: topic.id });
    // alert("Deleted " + topic.name);
    // const item = [];
    // for (let i = 0; i < list.length; i++) {
    //   if (topic.id !== list[i].id) {
    //     item.push(list[i]);
    //   }
    // }
    // setList(item);
    // saveOrder(item);
  }
  const delfunction = async (topic) => {
    // deleteTopic({ topicId: topic.id });

    let configTopicExtra = {
      method: "delete",
      // params: { topicId: topicsData[i] },
      url: `${IP}/courseBuilder/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        topicId: topic.id,
      }),
    };
    await axios(configTopicExtra);
    alert("Deleted " + topic.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (topic.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  return (
    <>
      <br></br>
      <Item itemsData={itemsData} courseId={courseId} lessonId={lessonId} />
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteTopicId}
        itemName={"Topic"}
      />
      <br></br>
      <section
        className="section"
        style={{ marginBlockStart: "0px", width: "100%" }}
      >
        <button
          className="addItem-btn"
          onClick={() => {
            setEditable(false);
            toggle();
          }}
        >
          Add Topic
        </button>
        <div
          className="addItem-container"
          id={lessonId + "topicForm"}
          style={{ display: "none" }}
        >
          <div className="addItem-heading">Topic</div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Topic Name *</div>
            <input
              type="text"
              className="addItem-inputBar"
              value={topicName}
              style={{
                border: `${
                  checkErrorInForm.topicName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              id={lessonId + "topicName"}
              onChange={(event) => {
                setTopicName(event.target.value);
                setCheckErrorInForm((prev) => ({ ...prev, topicName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setTopicId(value + "_" + time);
                }
              }}
            />
            {checkErrorInForm.topicName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Topic Name
              </span>
            )}
          </div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Short Description *</div>
            <textarea
              type="text"
              className="addItem-inputBar"
              value={topicDescription}
              style={{
                border: `${
                  checkErrorInForm.topicDescription == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              id={lessonId + "topicDescription"}
              onChange={(event) => {
                setTopicDescription(event.target.value);
                setCheckErrorInForm((prev) => ({
                  ...prev,
                  topicDescription: "",
                }));
              }}
            />
            {checkErrorInForm.topicDescription == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Topic Description
              </span>
            )}
          </div>
          {/* <div className="addItem-row">
            <div className="addItem-inputLabel" style={{ marginTop: "2vw" }}>
              Sample Topic
            </div>
            <select className="addItem-inputSelect" id={lessonId + "sampleTopic"}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}
          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">Quiz</div>
            <select className="addItem-inputSelect" id={lessonId + "hasTopicQuiz"}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}
          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">Topic Release Schedule</div>
            <select className="addItem-inputSelect" id={lessonId + "topicReleaseSchedule"}>
              <option value="immediate">Immediate</option>
              <option value="enrolment">Enrolment</option>
              <option value="specific">Specific Date</option>
            </select>
          </div> */}
          <div
            className="addItem-row"
            style={{ display: "none" }}
            id={lessonId + "daysAfterBlockTopic"}
          >
            <div className="addItem-inputLabel">Days After Enrolment</div>
            <input
              type="number"
              className="addItem-inputBar"
              value={daysAfterTopic}
              id={lessonId + "daysAfterTopic"}
              onChange={(event) => {
                setDaysAfterTopic(event.target.value);
              }}
            />
          </div>
          <div
            className="addItem-row"
            style={{ display: "none" }}
            id={lessonId + "startDateBlockTopic"}
          >
            <div className="addItem-inputLabel">Start Date</div>
            <input
              type="date"
              className="addItem-inputBar"
              value={startDateTopic}
              id={lessonId + "startDateTopic"}
              onChange={(event) => {
                setStartDateTopic(event.target.value);
              }}
            />
          </div>
          <br></br>
          {checkFailed && <p>Fill all the necessary fields. </p>}
          <div className="addItem-row">
            <button
              className="addItem-btn"
              style={{ marginLeft: "auto" }}
              onClick={!editable ? addTopic : updateTopic}
            >
              Save Topic
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="topicList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="draggableTopic-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#7783F4",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="lessonList-draggable-summary">
                              <p>Id- {item.id}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            {/* <MdDelete
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => del(item)}
                            />
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            /> */}
                            <p>{item.description}</p>
                            <p>Associated Lesson- {lessonId}</p>
                            <br></br>
                            <Item itemsData={item.itemsArr} topicId={item.id} />
                            <br></br>
                            <CreateQuiz
                              editQuizArr={item.quizArr}
                              lessonId={lessonId}
                              courseId={courseId}
                              topicId={item.id}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      <CreateQuiz
        editQuizArr={editQuizArr}
        courseId={courseId}
        lessonId={lessonId}
      />
    </>
  );
};

export default CreateLesson;
