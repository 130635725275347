import { Button, Container } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import certificate from "../../../utilities/images/image 1.png";
import "../../styles/Certificates/Certificates.css";
import "../../styles/CourseLibrary/CourseLibraryMain.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";

function DownloadCertificates() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  return (
    <Box>
      <Navbar page={t("My Courses")} />
      <Box>
        <Container sx={{ py: 4 }}>
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Box>
                <img style={{ width: "100%" }} src={certificate} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  style={{
                    background: "linear-gradient(to right, #007848, #004577)",
                    color: "#fff",
                  }}
                  sx={{
                    textTransform: "none",
                    width: "150px",
                    py: 1,
                    borderRadius: "10px",
                    fontSize: "12px",
                    fontFamily: "'Readex Pro', sans-serif",
                    fontWeight: 700,
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default DownloadCertificates;
