import { Box, Container, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { functions } from "../../../Firebase";
import "../../styles/Navbar/Navbar.css";
// import { useTranslation } from "react-i18next";
import "../../Translater/Translater";
import pp from "../img/navbar/profilePicture.svg";
import settings from "../img/navbar/settings.svg";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import { deleteCookie, getCookie } from "../../../Cookies";
import { IP } from "../../../config";
import axios from "axios";

function Navbar({ page }) {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();
  const fetchNotification = httpsCallable(functions, "fetchNotification");
  const [notifications, setNotifications] = useState(null);
  //================user details====================//
  const removeDuplicates = (arr, prop) => {
    const uniqueArray = [];
    const seenValues = new Set();

    for (const obj of arr) {
      const value = obj[prop];

      if (!seenValues.has(value)) {
        seenValues.add(value);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  };
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const [imgshow, setImgshow] = useState(false);
  const [name, setName] = useState("");
  const [empId, setempId] = useState("");
  const [notificationsCount, setNotificationsCount] = useState("");
  useEffect(() => {
    // fetchUserProfile({})
    //   .then((res) => {
    //     setImgshow(res.data?.photoURL); //setting url of image
    //     setName(res.data?.name); //setting name of user
    //     setempId(res.data?.employeeId); //setting empId of user
    //     // //console.log(res.data);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });

    //setting url of image
    setName(getCookie("NAME")); //setting name of user
    setempId(getCookie("EMPID")); //setting empId of user
    let configCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile/userNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configCertificate).then((res) => {
      if (res.error) {
        return;
      }
      const tempArrInitial = res.data;
      const withoutDuplicateArray = removeDuplicates(tempArrInitial, "title");

      setNotifications(
        withoutDuplicateArray.sort(
          (a, b) => b.timestamp._seconds - a.timestamp._seconds
        )
        // res.data.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)
      );
      console.log("withoutDuplicateArray", withoutDuplicateArray);
      const temparr = withoutDuplicateArray.filter((ele) => ele.read === false);

      // setNotifications(
      //   res.data.sort((a, b) => b?.timestamp?._seconds - a?.timestamp?._seconds)
      // );
      // const temparr = res.data.filter((ele) => ele.read === false);
      // console.log(temparr);

      setNotificationsCount(temparr.length > 0 ? temparr.length : "");
    });
  }, []);
  useEffect(() => {
    if (
      notifications &&
      notifications[0]?.title == "You are archived from the portal"
    ) {
      deleteCookie();

      window.open(`${window.location.origin}`, "_self");
    }
  }, [notifications]);

  return (
    <Box
      sx={{
        height: "80px",
        py: 1,
        background: "#fff",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "inline",
                fontSize: "22px",
                fontWeight: "600",
                color: (theme) => theme.palette.primary.main,
                textTransform: "capitalize",
              }}
            >
              {page}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box sx={{ width: "40px", height: "40px" }}>
                {/* <img src={pp} alt="loading" /> */}
                <img
                  src={imgshow ? imgshow : pp}
                  alt="loading"
                  style={{ width: "100%", height: "100%", borderRadius: "20%" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {/* {getCookie("NAME")} */}
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: (theme) => theme.palette.primary.btn,
                  }}
                >
                  {/* {getCookie("EMPID")} */}
                  {empId}
                </Typography>
              </Box>
            </Box>
            <Box>
              <NotificationPopup
                notifications={notifications}
                notificationsCount={notificationsCount}
              />
            </Box>
            <Box className="nav-cnt-right-settings">
              <Link to="/user/settings">
                <img src={settings} alt="loading" />{" "}
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Navbar;
