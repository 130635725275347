import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcons } from "../../../../../../utilities/Icons/Icons";

function Matchpair({ question, instruction, qno, qtot, setActiveSubmit }) {
  const [answerArr, setAnswerArr] = useState(new Array(question.lhs.length));
  const [answerBucket, setAnswerBucket] = useState(Array.from(question.rhs));
  //console.log(question);
  const { lhs } = question;
  const checkAnswer = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].match != i + 1) return false;
    }

    return true;
  };
  const handelDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (destination === null) return; // if droped at invalid dropzone
    if (destination.droppableId === source.droppableId) return; // if droped at the same zome
    if (destination.droppableId === "answer-bucket") {
      // from options to bucket
      const sIndex = parseInt(source.droppableId.split("-")[1]) - 1;
      const { index } = destination;
      const item = answerArr[sIndex];
      const sArr = answerArr;
      sArr[sIndex] = null;
      setAnswerArr(sArr);

      const newArr = Array.from(answerBucket);
      newArr.splice(index, 0, item); // adds item to arr[index]
      setAnswerBucket(newArr);
    } else if (destination.droppableId.split("-")[0] === "droppableOption") {
      // from anywhere to options
      if (source.droppableId === "answer-bucket") {
        // bucket to opt
        let sIndex = source.index;
        let sArr = answerBucket;
        let item = sArr[sIndex];
        let dIndex = parseInt(destination.droppableId.split("-")[1]) - 1;
        //console.log(dIndex);
        if (answerArr[dIndex] == null) {
          let dArr = answerArr;
          dArr[dIndex] = item;
          setAnswerArr(dArr);
          sArr.splice(sIndex, 1);
        } else {
          let dArr = answerArr;
          sArr[sIndex] = dArr[dIndex];
          dArr[dIndex] = item;
          setAnswerArr(dArr);
        }

        setAnswerBucket(sArr);
      } else {
        // opt to opt
        let sIndex = parseInt(source.droppableId.split("-")[1]) - 1;
        let dIndex = parseInt(destination.droppableId.split("-")[1]) - 1;
        let ansArr = answerArr;
        let temp = ansArr[sIndex];
        ansArr[sIndex] = ansArr[dIndex];
        ansArr[dIndex] = temp;
        setAnswerArr(ansArr);
        //console.log("b");
      }
    }
    let flag = true;
    for (let i = 0; i < answerArr.length; i++) {
      if (answerArr[i] == null) flag = false;
      //console.log(answerArr[i]);
    }

    if (flag) {
      let ans = {
        type: "mathcPair",
        qno: qno,
        correct: checkAnswer(answerArr),
      };
      localStorage.setItem("answer", JSON.stringify(ans));
    }
    setActiveSubmit(flag);
    //console.log(result);
  };

  return (
    <Box sx={{ padding: { xs: "15px", md: "30px 30px 30px 15px" } }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box style={{}} sx={{ my: "30px" }}>
        <DragDropContext onDragEnd={handelDragEnd}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {lhs &&
                lhs.map((item, id) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                      key={"key" + id}
                    >
                      <Box sx={{ position: "relative", overflow: "hidden" }}>
                        <Box
                          sx={{
                            width: { xs: "100px", md: "170px" },
                            height: "40px",
                            background: "#003A69",
                            borderRadius: "8px 0px 0px 8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <DragIcons />
                          <Typography sx={{ color: "#fff" }}>{item}</Typography>
                        </Box>
                        <Box
                          className={"quizShape"}
                          sx={{
                            width: { xs: "100px", md: "170px" },
                            height: "40px",
                            background: "#fff",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                        ></Box>
                      </Box>
                      <Droppable
                        key={"Dkey" + id}
                        droppableId={"droppableOption-" + (id + 1)}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              width: { xs: "100px", md: "170px" },
                              height: "40px",
                              background: "#fff",
                              marginLeft: "-3%",
                            }}
                          >
                            {answerArr[id] && (
                              <DragItemOptions
                                id={"option" + id}
                                index={id}
                                txt={answerArr[id].text}
                              />
                            )}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </Box>
                  );
                })}
            </Box>
            {/* Droppables  */}

            {/* options bucket */}

            <Droppable
              droppableId={"answer-bucket"}
              style={{
                minWidth: "25vw",
              }}
            >
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {answerBucket &&
                    answerBucket.map((item, id) => (
                      <DragItem
                        txt={item.text}
                        id={"bucket" + id}
                        index={id}
                        key={"key" + id}
                      />
                    ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
      </Box>
    </Box>
  );
}

const DragItem = (props) => {
  const { url, txt, id, index, stl } = props;
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Box
          style={stl}
          sx={{ marginBottom: "25px" }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box sx={{ position: "relative", overflow: "hidden" }}>
            <Box
              className={"quizShape2"}
              sx={{
                width: { xs: "100px", md: "170px" },
                height: "40px",
                background: "#003A69",
                position: "absolute",
                top: "0px",
                left: "0px",
              }}
            ></Box>
            <Box
              className={"quizShape3"}
              sx={{
                width: { xs: "100px", md: "170px" },
                height: "40px",
                background: "#003A69",
                borderRadius: "0px 8px 8px 0px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <DragIcons />
              <Typography sx={{ color: "#fff" }}>{txt}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

const DragItemOptions = (props) => {
  const { url, txt, id, index, stl } = props;
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box sx={{ position: "relative", overflow: "hidden" }}>
            <Box
              className={"quizShape2"}
              sx={{
                width: { xs: "100px", md: "170px" },
                height: "40px",
                background: "#003A69",
                position: "absolute",
                top: "0px",
                left: "0px",
              }}
            ></Box>
            <Box
              className={"quizShape3"}
              sx={{
                width: { xs: "100px", md: "170px" },
                height: "40px",
                background: "#003A69",
                borderRadius: "0px 8px 8px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ paddingLeft: "10px" }}>
                <DragIcons />
              </Box>
              <Typography sx={{ color: "#fff" }}>{txt}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default Matchpair;
