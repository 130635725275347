import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { functions } from "../../../Firebase";

import "../StyleAdminComponents/GroupList.css";
import Notify from "../Dashboard/Notify";
import "../Dashboard/OverdueEnrollments.css";
import GenerateReport from "../Dashboard/popUpForReports";
import { create } from "sortablejs";
import GenerateReportForLocal from "../Dashboard/popUpForLocalReport";

import "jspdf-autotable";
import jsPDF from "jspdf";
import goss from "../goss.png";
import moraikh from "../moraikh.png";
import alghat from "../alghat.png";
import ocasco from "../ocasco.png";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import { toast } from "react-toastify";
const storage = getStorage();
function GenerateReportCompany({ reportType, setdownloadReportType }) {
  const { clrs } = useSelector((state) => state.createClr);
  // const location = useLocation();
  // const overDueInfo = location.state;
  // //console.log(overDueInfo);
  const overdueData = httpsCallable(functions, "overdueData");
  const uploadReports = httpsCallable(functions, "uploadReports");
  const fetchReports = httpsCallable(functions, "fetchReports");
  const fetchCompany = httpsCallable(functions, "fetchCompany");
  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);
  const [showLoading, setLoading] = useState(false);

  const [details, setDetails] = useState(null);
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayNotify, setDisplayNotify] = useState(false);

  const [selectDefault, setselectDefault] = useState("default");

  const [selectAllEmployee, setSelectAllEmployee] = useState(false);

  const [currentUrl, setCurrentUrl] = useState("");

  const [currentDataUrl, setCurrentDataUrl] = useState({});

  const [documentCourseType, setDocumentCourseType] = useState("");
  const [reportIdFromReports, setReportIdFromReports] = useState();
  ///////Company DAta///////////

  const [companyDataName, setCompanyDataName] = useState([]);
  const [departmentDataName, setDepartmentDataName] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedComapnyName, setSelectedCompanyName] = useState("");
  const [selectedTrainingType, setSelectedTrainingType] = useState("");
  const [courseNameData, setCourseNameData] = useState([]);

  const [courseType, setCourseType] = useState("");
  const [statusDataSetForStatus, setStatusDataSetForStatus] = useState("");
  const [tempDataArrAfterCourseFilter, setTempDataArrAfterCourseFilter] =
    useState([]);
  const [filteredDataFromStatusFilter, setFilteredDataFromStatusFilter] =
    useState([]);
  const [selectFromDate, setSelectFromDate] = useState("");
  const [selectToDate, setSelectToDate] = useState("");

  const [extraArrForFilter, setExtraArrForFilter] = useState([]);
  const [departmentObj, setDepartmentObj] = useState({});
  const [companyObj, setCompanyObj] = useState({});
  const [filterStates, setFiltersStates] = useState({
    courseName: "",
    companyId: "",
    departmentId: "",
    toDate: "",
    fromDate: "",
  });

  const [reportLocalState, setReportLocalState] = useState(false);
  const [localreportdata, setlocalreportdata] = useState([]);
  //console.log("overDueArray", overDueArray);

  const [reporttype, setreportType] = useState("");
  const [reportlink, setReportLink] = useState("");
  const [reportData, setReportData] = useState({});

  const [loadings, setLoadings] = useState(false);
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  useEffect(() => {
    console.log(reportType);
    if (reportType == "server") {
      generateReportsFunction("server");
    }
    if (reportType == "local") {
      generateReportsFunction("local");
    }
    if (reportType == "currentpage") {
      generateReportsFunction("local");
    }
    if (reportType == "allpage") {
      generateReportsFunction("local");
    }
  }, [reportType]);
  const runGenerateCertificateTrainingPdf = async (type, data) => {
    // console.log(data);const
    const name = type;

    const pdfName =
      name +
      " Report" +
      "   Date (dd/mm/yyyy):" +
      new Date().toLocaleDateString();
    const pdf = new jsPDF("p", "pt", "a2");

    const columns = Object.keys(data[0]);
    var rows = [];
    //   console.log({ rows });
    data.forEach((e, index) => {
      const createDate = new Date(e.createdAt).toLocaleDateString();
      const updatedDate = new Date(e.updatedAt).toLocaleDateString();
      const status = e.status ? "Active" : "InActive";
      var temp = Object.values(e);

      rows.push(temp);
    });
    pdf.text(500, 30, pdfName);
    // var img = new Image();
    // img.src = img;
    // console.log(img.src);
    pdf.addImage(goss, "png", 50, 50, 300, 100);
    pdf.addImage(moraikh, "png", 350, 50, 250, 100);
    pdf.addImage(ocasco, "png", 600, 50, 250, 100);
    pdf.addImage(alghat, "png", 850, 50, 250, 100);

    // pdf.addImage(

    //   400,
    //   40
    // );
    pdf.autoTable(columns, rows, {
      startY: 180,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 10,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    // console.log(pdf.output("datauristring"));
    //let rses = pdf.save(name + Date.now());

    return pdf.output("bloburl");
    // console.log(rows);
    // console.log(master);
  };
  const runGenerateCertificateTraining = async (type, data) => {
    setReportLink("");
    let reportId = `${selectDefault}_${Timestamp.fromDate(new Date()).seconds}`;
    setreportType(type);
    let file = await runGenerateCertificateTrainingPdf(reportId, data);
    // let resdata = await blobToBase64(file);

    // console.log(resdata);
    // function blobToBase64(blob) {
    //   return new Promise((resolve, _) => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => resolve(reader.result);
    //     reader.readAsDataURL(blob);
    //   });
    // }

    // Abe Oye Ise kbhi galti se bhi nhi chhuna nhi to fir apna rayta khud saf krna thik he

    // Never ever fucking touch the blow code unless and until you have optimized version

    fetch(file)
      .then((res) => {
        console.log({ res });
        return res.blob();
      })
      .then((resu) => {
        // console.log(resu);
        let dat = new FileReader();

        dat.onload = async () => {
          const metaData = {
            contentType: "application/pdf",
          };

          const storageRef = ref(storage, "report/" + reportId + ".pdf");
          uploadString(storageRef, dat.result, "data_url", metaData)
            .then(async (snep) => {
              //  console.log(snep);
              const downUrl = await getDownloadURL(storageRef);

              let httpsremove = downUrl.split("//")[1];
              setLoadings(true);
              uploadReports({
                data: data,
                reportType: type,
                id: reportId,
                downUrl: httpsremove,
              })
                .then((res) => {
                  setReportData(res.data);

                  setOpenEnrollment(false);
                  setReportLoading(true);
                })
                .catch((err) => {
                  setLoadings(false);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        };
        dat.readAsDataURL(resu);
      });

    // console.log(file);
    // var filetes = new File(file, "name");
    // console.log(filetes);
  };

  const generateReportsFunction = (type) => {
    let tempreportDataArr = details?.filter((e) => e.checked == true);
    //console.log(tempreportDataArr)
    let reportsDataArr = [];
    tempreportDataArr?.map((e) => {
      let milisecon = e?.joiningDate?._seconds * 1000;
      let year = new Date(milisecon).getFullYear();
      let months = new Date(milisecon).getMonth();
      let days = new Date(milisecon).getDate();
      let fulldate = "";
      if (months < 9) {
        months = Number(months) + 1;
        months = "0" + months;
      } else {
        months = Number(months) + 1;
      }
      if (days <= 9) {
        days = "0" + days;
      } else {
        days = Number(days);
      }
      fulldate = `${year}-${months}-${days}`;
      // //console.log(fulldate,selestedDate)

      //console.log(e)

      let tempObj = {
        joiningDate: fulldate,
        email: e.email,
        role: e.role,
        name: e.name,
        jobTitle: e.jobTitle ? e.jobTitle : "",
        company: companyObj[e.companyId],
        department: departmentObj[e.departmentId],

        courseName: e.courseName ? e.courseName : "",

        certificate_issuedOn: e?.issued
          ? e?.issued
          : // ? new Date(e.issuedOn?._seconds * 1000).toISOString().split("T")[0]
            "NaN",
        certificate_expiry: e.expiry
          ? e.expiry
          : // ? new Date(e.expiry?._seconds * 1000).toISOString().split("T")[0]
            "NaN",

        course_dueDate: e.dueDate
          ? new Date(e.dueDate?._seconds * 1000).toISOString().split("T")[0]
          : "NaN",
        status: e.status,
      };
      reportsDataArr.push(tempObj);
    });
    //console.log(reportsDataArr)
    // "data":{},
    // "reportType": "",
    // "id": ""
    let reportId = `${selectDefault}_${Timestamp.fromDate(new Date()).seconds}`;
    setReportIdFromReports(reportId);
    //console.log(reportId)
    if (reportsDataArr.length > 0) {
      if (type == "server") {
        setReportLoading(false);
        setOpenEnrollment(true);

        runGenerateCertificateTraining(selectDefault, reportsDataArr);
        setlocalreportdata(reportsDataArr);

        // uploadReports({
        //   data: reportsDataArr,
        //   reportType: selectDefault,
        //   id: reportId,
        // })
        //   .then((res) => {

        //   })
        //   .catch((err) => {});
      } else {
        setReportLocalState(true);
        setlocalreportdata(reportsDataArr);
      }
    } else {
      setReportLocalState(false);

      setdownloadReportType("");
      alert("Select any user");
      return;
    }
  };

  const statusFilterCompany = (e) => {
    setStatusDataSetForStatus(e);

    //console.log(e)

    if (e == "all") {
      setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter);
      setDetails(tempDataArrAfterCourseFilter);
    } else if (e == "complete") {
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "complete") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "overdue") {
      //console.log(Timestamp.fromDate(new Date()).seconds)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        //console.log(item)
        if (item.status == "overdue") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "expired") {
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "expired") {
          return item;
        }
      });
      //console.log(dataTemp)
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "renew") {
      // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

      //    return item
      //  }

      // }
      //  )
      //  //console.log(dataTemp)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "renew") {
          return item;
        }
      });
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    } else if (e == "failed") {
      // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

      //    return item
      //  }

      // }
      //  )
      //  //console.log(dataTemp)
      let dataTemp = tempDataArrAfterCourseFilter.filter((item) => {
        if (item.status == "fail") {
          return item;
        }
      });
      setArrLength(dataTemp.length);
      setDetails(dataTemp);
      setFilteredDataFromStatusFilter(dataTemp);
    }

    // if(details.length>0&&e!="all"){
    //   //console.log(e)
    //   // let temparr=courseDataName.filter((item)=>item.courseName==e)
    //   //     setDetails(courseDataName.filter((item)=>item.courseName==e))
    //   //     //console.log(temparr)
    //   //     setArrLength(temparr.length)
    //   setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter)

    //    }else{
    //     setFilteredDataFromStatusFilter(tempDataArrAfterCourseFilter)
    //    }
  };

  useEffect(() => {
    setLoading(true);
    let configLesson = {
      method: "get",
      // params: { name: employeeName.toLowerCase() },
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson)
      .then((res) => {
        setCompanyDataName(res.data);
        res.data.map((el) => {
          setCompanyObj((prev) => ({
            ...prev,
            [el.companyId]: el.companyName,
          }));
        });
        setLoading(false);
        //console.log(res.data)
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
      });
    let configDepartment = {
      method: "get",
      // params: { name: employeeName.toLowerCase() },
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configDepartment).then((res) => {
      setDepartmentDataName(res.data);
      res.data.map((el) => {
        setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
      });
      //console.log(res.data)
    });
  }, [selectDefault]);

  const getCourseListBasedOnType = (type) => {
    let configLesson = {
      method: "get",
      // params: { name: employeeName.toLowerCase() },
      url: `${IP}/common/course/${type}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setCourseNameData(res.data);
    });
  };
  const hendelChecked = (id, type) => {
    let newTempData = [];
    //console.log(type)
    if (type === "All") {
      setSelectAllEmployee(!selectAllEmployee);
      details.map((e) => {
        //console.log(e.checked)
        e.checked = !selectAllEmployee;
        //console.log(e.checked)
        newTempData.push(e);
      });
    } else if (type == "one") {
      details.map((e, index) => {
        //console.log(id)
        if (index === id) {
          //console.log(e.checked)
          e.checked = e.checked ? false : true;
          //console.log(e.checked)
          newTempData.push(e);
        } else {
          newTempData.push(e);
        }
      });
    }
    //console.log(newTempData)
    setDetails(newTempData);
  };

  const selectedDateTohumanDate = (type) => {
    // //console.log(result.data)
    //  setDetails(newEmployeeArray);

    if (type == "clear") {
      // setCompanyDataName([]);
      // setDepartmentDataName([]);

      setSelectedDepartment("");
      setSelectedCompanyName("");
      setSelectedTrainingType("");
      // setCourseDatadName([]);
      setCourseType("");
      setCurrentDataUrl({
        c_page: 0,
        t_count: 0,
        t_page: 0,
      });
      setStatusDataSetForStatus("");
      setFilteredDataFromStatusFilter([]);
      setTempDataArrAfterCourseFilter([]);
      setSelectFromDate("");
      setSelectToDate("");

      setFiltersStates({
        companyId: "",
        courseName: "",
        departmentId: "",
        toDate: "",
        fromDate: "",
      });

      setDetails([]);
      setArrLength(0);
      setPageSize(10);
      setCurrentPage(0);
    }
    // //console.log(newarr)
  };

  const getReportData = (
    type,
    company,
    department,
    course,
    toDate,
    fromDate
  ) => {
    setLoading(true);

    let query = {};
    if (company) {
      query["companyId"] = company;
    }
    if (course) {
      query["courseId"] = course;
    }
    if (department) {
      query["departmentId"] = department;
    }
    if (toDate) {
      query["toDate"] = toDate;
    }
    if (fromDate) {
      query["fromDate"] = fromDate;
    }
    if (type) {
      query["trainingType"] = type;
    }
    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/reports?limit=${25}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    axios(configLesson)
      .then((res) => {
        let data = res.data.data;
        setCurrentUrl(`${IP}/reports?trainingType=${type}`);
        setLoading(false);

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);
        setDetails(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const clickhandler = (url) => {
    setLoading(true);
    setCurrentUrl(`${url}`);

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${url}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setCurrentUrl(`${url}`);
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);
        setDetails(data);
      })
      .catch((err) => {
        setLoading(false);
        toast.warning("something went wrong");
      });
  };

  const changePageLimit = (pageLimit) => {
    setLoading(true);

    // let URL = `${IP}/reports`;

    // if (URL.split("?")[1]) {
    //   URL = `${URL}&limit=${pageLimit}`;
    // } else {
    //   URL = `${URL}?limit=${pageLimit}`;
    // }
    // let URL = currentUrl;
    console.log(currentUrl);
    // filterStates

    let query = {};
    if (filterStates.companyId) {
      query["companyId"] = filterStates.companyId;
    }
    if (filterStates.courseName) {
      query["courseId"] = filterStates.courseName;
    }
    if (filterStates.departmentId) {
      query["departmentId"] = filterStates.departmentId;
    }
    if (filterStates.toDate) {
      query["toDate"] = filterStates.toDate;
    }
    if (filterStates.fromDate) {
      query["fromDate"] = filterStates.fromDate;
    }
    // if (type) {
    //   query["trainingType"] = type;
    // }
    //   let URL = currentUrl.replace(/stUid=[^&]+&/, "");
    //   URL = URL.replace(/count=[^&]+&/, "");
    //   URL = URL.replace(/isNext=[^&]+&/, "");
    //  courseType
    //   if (currentUrl.split("?")[1]) {
    //     URL = `${URL}&limit=${pageLimit}`;
    //   } else {
    //     URL = `${URL}?limit=${pageLimit}`;
    //   }
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",
      params: query,
      url: `${IP}/reports?limit=${pageLimit}&trainingType=${courseType}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);
        setDetails(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {displayNotify && <Notify NotifyClick={NotifyClick} />}

      <div className="admin-overdue">
        <GenerateReportForLocal
          open={reportLocalState}
          onClose={() => {
            setReportLocalState(false);

            setdownloadReportType("");
          }}
          reportId={reportIdFromReports}
          data={localreportdata}
          type={"company"}
          selectAllEmployee={selectAllEmployee}
          currentUrl={currentUrl}
          trainingType={courseType}
          filterStates={filterStates}
          reportTypeBasedOnAllAndCurrent={reportType}
        />

        <div className="admin-overdue-mid" style={{}}>
          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">
              {t("Type of training")}
            </div>
            <div className="admin-overdue-mid-left-input">
              <select
                name="trainingType"
                id="company"
                value={
                  courseType == "internalLMSTrainingInfo"
                    ? "course"
                    : courseType == "externalTrainingInfo"
                    ? "externalTraining"
                    : courseType == "internalOfflineTrainingInfo"
                    ? "internalOfflineTraining"
                    : ""
                }
                onChange={(e) => {
                  // setCourseType(e.target.value);
                  getCourseListBasedOnType(e.target.value);

                  let type = e.target.value;
                  if (type == "course") {
                    type = "internalLMSTrainingInfo";
                  } else if (type == "externalTraining") {
                    type = "externalTrainingInfo";
                  } else if (type == "internalOfflineTraining") {
                    type = "internalOfflineTrainingInfo";
                  }
                  setCourseType(type);
                  setDocumentCourseType(type);
                  getReportData(type, "", "");
                }}
              >
                <option value="">Select a course type</option>
                <option value={"course"}>{"Internal LMS"}</option>
                <option value={"externalTraining"}>
                  {"External Training"}
                </option>
                <option value={"internalOfflineTraining"}>
                  {"Internal Offline Training"}
                </option>
              </select>
            </div>
          </div>

          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">
              {t("Company Name")}
            </div>
            <div className="admin-overdue-mid-left-input">
              {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
              <select
                name="group"
                id="group"
                value={filterStates?.companyId}
                onChange={(e) => {
                  getReportData(
                    documentCourseType,
                    e.target.value,
                    filterStates.departmentId,
                    filterStates.courseName,
                    filterStates.toDate,
                    filterStates.fromDate
                  );
                  setFiltersStates((prev) => ({
                    ...prev,
                    companyId: e.target.value,
                  }));
                  // CompanyDataFilterFunction(e.target.value, "company");
                }}
              >
                <option value="">Select a company</option>
                {companyDataName?.map((item) => (
                  <option value={item.companyId}>{item.companyName}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("Department")}</div>
            <div className="admin-overdue-mid-left-input">
              {/* <input type="text" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}}  /> */}
              <select
                name="groupenroll"
                id="group"
                value={filterStates.departmentId}
                onChange={(e) => {
                  getReportData(
                    documentCourseType,
                    filterStates.companyId,
                    e.target.value,
                    filterStates.courseName,
                    filterStates.toDate,
                    filterStates.fromDate
                  );

                  setFiltersStates((prev) => ({
                    ...prev,
                    departmentId: e.target.value,
                  }));

                  // CompanyDataFilterFunction(e.target.value, "department");
                }}
              >
                <option value="">select department</option>
                {departmentDataName?.map((item) => (
                  <option value={item.departmentId}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("Course Name")}</div>
            <div className="admin-overdue-mid-left-input">
              <select
                name="CourseName"
                id="company"
                value={filterStates.courseName}
                onChange={(e) => {
                  getReportData(
                    documentCourseType,
                    filterStates.companyId,
                    filterStates.departmentId,
                    e.target.value,
                    filterStates.toDate,
                    filterStates.fromDate
                  );
                  setFiltersStates((prev) => ({
                    ...prev,
                    courseName: e.target.value,
                  }));

                  // CompanyDataFilterFunction(e.target.value, "courseName");
                }}
              >
                <option value="">Select course</option>
                {courseNameData?.map((item) => (
                  <option value={item.courseId}>{item.courseName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="admin-overdue-mid" style={{}}>
          {/* <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("Status")}</div>
            <div className="admin-overdue-mid-left-input">
              <select
                name="Status"
                id="company"
                onChange={(e) => {
                  statusFilterCompany(e.target.value);
                }}
              >
                <option value="">Select a status</option>
                <option value="all">All</option>
                {selectedTrainingType == "1" && (
                  <>
                    <option value="complete">Complete</option>
                    <option value="overdue">Overdue</option>
                    <option value="failed">Failed</option>
                    <option value="expired">Expired</option>
                    <option value="renew">Renew</option>
                  </>
                )}

                {selectedTrainingType == "0" && (
                  <>
                    <option value="complete">Complete</option>
                    <option value="overdue">Overdue</option>

                    <option value="expired">Expired</option>
                    <option value="renew">Renew</option>
                  </>
                )}
                {selectedTrainingType == "2" && (
                  <>
                    <option value="complete">Complete</option>
                    <option value="overdue">Overdue</option>

                    <option value="expired">Expired</option>
                    <option value="renew">Renew</option>
                  </>
                )}
                {/* <option value="complete">Complete</option>
                 <option value="overdue">Overdue</option>
                 <option value="failed">Failed</option>
                 <option value="Expired">Expired</option>
                 <option value="renew">Renew</option> */}
          {/* </select>
            </div>
          </div>  */}

          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("From Date")}</div>
            <div className="admin-overdue-mid-left-input">
              <input
                type="date"
                disabled={statusDataSetForStatus == "all" ? true : false}
                value={filterStates.fromDate}
                onChange={(e) => {
                  getReportData(
                    documentCourseType,
                    filterStates.companyId,
                    filterStates.departmentId,
                    filterStates.courseName,
                    filterStates.toDate,
                    e.target.value
                  );
                  setFiltersStates((prev) => ({
                    ...prev,
                    fromDate: e.target.value,
                  }));
                  // setSelectFromDate(e.target.value);
                }}
              />

              {/* if status is all then need to disabled the date */}
              {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
            </div>
          </div>
          <div className="admin-overdue-mid-left">
            <div className="admin-overdue-mid-left-txt">{t("To Date")}</div>
            <div className="admin-overdue-mid-left-input">
              <input
                type="date"
                value={filterStates.toDate}
                disabled={statusDataSetForStatus == "all" ? true : false}
                onChange={(e) => {
                  getReportData(
                    documentCourseType,
                    filterStates.companyId,
                    filterStates.departmentId,
                    filterStates.courseName,
                    e.target.value,
                    filterStates.fromDate
                  );
                  setFiltersStates((prev) => ({
                    ...prev,
                    toDate: e.target.value,
                  }));
                  // setSelectToDate(e.target.value);
                }}
              />
              {/*  <select name="company" id="company">
                 <option value="demo">Select Course</option>\
                 </select> */}
            </div>
          </div>
          <div style={{ marginTop: "3vw" }} className="admin-overdue-mid-right">
            {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
            <div className="admin-overdue-mid-left-input-right">
              {/*     <input type="date" placeholder="Select Date" /> */}
              <div className="admin-dashboard-main-mid-item-last-filter">
                {" "}
                <div className="admin-dashboard-main-top-last-filter">
                  <button
                    onClick={() => {
                      // DateFilterDataSelected("date");
                    }}
                    style={{
                      background: `${
                        statusDataSetForStatus == "all"
                          ? "rgb(215,215,215)"
                          : clrs.CourseListBtn
                      }`,
                    }}
                    disabled={statusDataSetForStatus == "all" ? true : false}
                  >
                    <div>
                      <svg
                        width="auto"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-mid-right">
            {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
            <div className="admin-overdue-mid-left-input-right">
              {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
              <div
                className="admin-dashboard-main-mid-item-last-filter"
                style={{ marginTop: "5vh" }}
              >
                {" "}
                <div className="admin-dashboard-main-top-last-filter">
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      width: "8vw",
                    }}
                    onClick={() => {
                      selectedDateTohumanDate("clear");
                    }}
                  >
                    <div>
                      <svg
                        width="auto"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>{t("Clear")}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GenerateReport
          open={openEnrollment}
          onClose={() => setOpenEnrollment(false)}
          reportId={reportIdFromReports}
          data={localreportdata}
          type={selectDefault}
        />
        {/* <div className="admin-overdue-bottom">
          You Have selected all the users
        </div> */}

        <div className="admin-overdue-bottom">
          <div className="admin-overdue-bottom-table">
            {selectDefault != "Employe" && (
              <>
                <div
                  className="admin-overdue-bottom-table-cnt"
                  style={{
                    gridTemplateColumns:
                      "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  }}
                >
                  {/*     <div style={{width:"1vw"}} className="admin-overdue-bottom-table-head"></div> */}
                  <div className="admin-overdue-bottom-table-head">
                    {" "}
                    <input
                      style={{
                        height: "1vw",
                        width: "2vw",
                        marginRight: "2vw",
                        marginLeft: "-3vw",
                      }}
                      type="checkbox"
                      onClick={() => {
                        hendelChecked("tets", "All");
                      }}
                      checked={selectAllEmployee}
                    />
                    {t("SrNo")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Name")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("employeeId")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Company")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Email")}
                  </div>

                  <div className="admin-overdue-bottom-table-head">
                    {t("Role")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Course")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t(" Certificate expiry")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Certificate issued date")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Course dueDate")}
                  </div>
                  <div className="admin-overdue-bottom-table-head">
                    {t("Status")}
                  </div>
                  {/* <div className="admin-overdue-bottom-table-head">{t("Action")}</div> */}
                </div>
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}
                {details?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;

                  let dueDate = item.dueDate
                    ? new Date(item.dueDate?._seconds * 1000)
                        .toISOString()
                        .split("T")[0]
                    : "No Date";
                  let issuedOn = item.issued ? item.issued : "No Date";
                  let expiry = item.expiry ? item.expiry : "No Date";
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt"
                      key={id}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <input
                          style={{
                            height: "1vw",
                            width: "2vw",
                            marginRight: "2vw",
                            marginLeft: "-3vw",
                          }}
                          type="checkbox"
                          onClick={() => {
                            hendelChecked(id, "one");
                          }}
                          checked={item.checked}
                        />
                        {pageSize * (currentDataUrl.c_page - 1) + id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.employeeId}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {companyObj[item.companyId]}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.email}
                      </div>

                      <div className="admin-overdue-bottom-table-td">
                        {item.role}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.courseName}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {expiry}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {issuedOn}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {dueDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.status}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="admin-overdue-bottom-pagination">
            <div className="admin-overdue-bottom-pagination-cnt">
              <div className="admin-overdue-bottom-pagination-cnt-item">
                <svg
                  onClick={() => {
                    if (currentDataUrl.prev) {
                      clickhandler(currentDataUrl.prev);
                    }
                  }}
                  width="auto"
                  height="22"
                  viewBox="0 0 14 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                {currentDataUrl?.c_page} of {currentDataUrl?.t_page}
              </div>
              <div
                style={{ marginRight: "19vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <svg
                  onClick={() => {
                    if (currentDataUrl.next) {
                      clickhandler(currentDataUrl.next);
                    }
                  }}
                  width="auto"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div
                style={{ display: "flex", marginRight: "-30vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <label className="admin-row">{t("Show")}</label>
                <select
                  style={{
                    width: "4vw",
                    margin: "0.5vw",
                    marginBottom: "1.5vw",
                    height: "1.5vw",
                  }}
                  value={pageSize}
                  onChange={(e) => {
                    // changePageLimit(e.target.value);

                    setPageSize(e.target.value);
                  }}
                >
                  {/* <option value={10}>10</option> */}
                  <option value={25}>25</option>
                  {/* <option value={50}>50</option> */}
                </select>
                <label className="admin-row"> {t("PagePerItem")}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
      ></div>
      {/* <div className="courselist-main-cnt-back">
        <div className="courselist-main-cnt-back-btn">
          <Link to={"/admin/course-reports"}>
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
            >
              {t("Back")}
            </button>{" "}
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default GenerateReportCompany;
