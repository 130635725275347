import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import FillInTheBlackquestion from "./fillInTheblank";
import Choosethecorrectsequence from "./Choosethecorrectsequence";
import Matchthepair from "./Matchthepair";
import TrueAndFalse from "./TrueAndFalse";
import PictureMCQ from "./PictureMCQ";
import Matchthepairspicturebased from "./Matchthepairspicturebased";
import Choosethecorrectimage from "./Choosethecorrectimage";
import LoadingScreen from "./loading";
import { IP } from "../../../config";
import axios from "axios";
import { getCookie } from "../../../Cookies";
const CreateQuestion = ({ questionData, quizId }) => {
  const storage = getStorage();

  const [questionDataFromApi, setQuestionDataFromApi] = useState({});

  const [fetchDataFromApi, setfetchDataFromApi] = useState(0);
  const [editQuestion, setEditQuestion] = useState(false);
  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  //addQuestionSetOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [openLessonPopUpLoading, setopenLessonPopUpLoading] = useState(false);

  const [flagForSeconstEdit, setflagForSeconstEdit] = useState(0);
  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const [trueFalse, setTrueFalse] = useState(false);

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setPicMCQ((prev) => ({ ...prev,  }));
  }

  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */
  const handleRearrange = (e, order) => {
    //console.log("onChange");
    if (!canEditRearrange) return;
    setRearrangeOptions((prev) => {
      let item = {
        text: e.target.value,
        position: order,
      };
      let res = Array.from(prev);
      res[order - 1] = item;
      return res;
    });
  };
  const handleScramble = () => {
    setCanEditRearrange(false);
    setRearrangeOptions((prev) => {
      let res = scrambleArr(prev);
      //console.log(res);
      return res;
    });
  };
  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };
  const handleImageChange = (e, index) => {
    let { files } = e.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageFiles((prev) => {
        let newArr = Array.from(prev);
        let item = {
          match: index + 1,
          url: URL.createObjectURL(files[0]),
          base64: reader.result,
        };
        newArr[index] = item;
        return newArr;
      });
      //console.log("Image converted ", index);
    };
    reader.readAsDataURL(files[0]);
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  async function uplaodMatchImage(file) {
    if (!file) return;
    let resURL = "";
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${time + "_" + file.name}`);
    const uploadTask = await uploadBytes(storageref, file);
    resURL = await getDownloadURL(storageref);
    //console.log(resURL);
    return resURL;
  }

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  const handleImageMcqInput = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageMcq((prev) => {
        let newArr = Array.from(prev);
        newArr[index].base64 = reader.result;
        newArr[index].blobUrl = url;
        return newArr;
      });
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setImageMcq((prev) => {
    //   let newArr = Array.from(prev);
    //   return newArr;
    // });
  };

  //

  useEffect(async () => {
    if (!questionData) return;
    let temparr = [];
    for (let i = 0; i < questionData.length; i++) {
      temparr.push({
        data: questionData[i].data,
        id: questionData[i].questionId,
        name: questionData[i].questionName,
        type: questionData[i].questionType,
      });

      if (questionData.length - 1 == i) {
        setList(temparr);
      }
    }

    // let questionSetInfo = await getQuestionSetInfo({ questionSetId: quizId});
  }, []);

  useEffect(async () => {
    if (fetchDataFromApi > 0) {
      setopenLessonPopUpLoading(true);
      let temparr = [];

      let configCreateExtraInfo = {
        method: "get",
        params: { questionSetId: quizId },
        url: `${IP}/userCourseDetails/questionSet`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "",
      };
      axios(configCreateExtraInfo)
        .then((res) => {
          let questionData = res.data.questionData;
          for (let i = 0; i < questionData.length; i++) {
            temparr.push({
              data: questionData[i].data,
              id: questionData[i].questionId,
              name: questionData[i].questionName,
              type: questionData[i].questionType,
            });

            if (questionData.length - 1 == i) {
              setopenLessonPopUpLoading(false);
              setList(temparr);
            }
          }
        })
        .catch((e) => {
          setopenLessonPopUpLoading(false);
        });

      //toggleQuestionForm()
    }
  }, [fetchDataFromApi]);

  // console.log(list)
  const formCheckFunction = () => {
    if (questionName == "") return "Question Name";
    if (questionId == "") return "Question id";
    if (qus == "") return "Question";
    if (qusInstruction == "") return "Instruction";
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("MCQ");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionSetId: quizId,
        questions: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);
  }

  const toggleQuestionForm = (e) => {
    // resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    // console.log(lesson)
    setqustype(lesson.type);
    setEditQuestion(true);
    setflagForSeconstEdit(flagForSeconstEdit + 1);
    setQuestionDataFromApi(lesson);
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("MCQ");
    setqus("");
    setqusInstruction("");
    setRearrangeOptions([
      {
        text: "First",
        position: 1,
      },
      {
        text: "Second",
        position: 2,
      },
      {
        text: "Third",
        position: 3,
      },
      {
        text: "Fourth",
        position: 4,
      },
    ]);
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
    setLeftOptions(new Array(4).fill(""));
    setCanEditMatchPair(true);
    setRightOptions([
      {
        text: "",
        match: 1,
      },
      {
        text: "",
        match: 2,
      },
      {
        text: "",
        match: 3,
      },
      {
        text: "",
        match: 4,
      },
    ]);
    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
    setPicMCQ({
      url: "",
      base64: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: 0,
    });
  }
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <section className="quizForm-container">
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <LoadingScreen
        open={openLessonPopUpLoading}
        onClose={() => setopenLessonPopUpLoading(false)}
      />
      <button
        className="quizFrom-button"
        onClick={() => {
          toggleQuestionForm();
          setEditQuestion(false);
        }}
      >
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={quizId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={quizId + "question-form"} onSubmit={submit}>
          <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">
              Type of question :{" "}
            </label>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
            >
              <option value="MCQ">Multiple Choice Question</option>
              <option value="trueAndFalse">True and False</option>
              <option value="Choose the correct sequence">
                Choose the correct sequence
              </option>
              <option value="Match the pair">Match the pair</option>
              <option value="Fill in the blank">Fill in the blank</option>
              <option value="Picture MCQ">Picture MCQ</option>
              <option value="Match the pairs picture based">
                Pairing (word to images)
              </option>
              <option value="Choose the correct image">
                Choose the correct image
              </option>
            </select>
          </div>

          {qustype == "MCQ" ? (
            <CreateMcqQuestion
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Choose the correct sequence" ? (
            <Choosethecorrectsequence
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Fill in the blank" ? (
            <FillInTheBlackquestion
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Match the pair" ? (
            <Matchthepair
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "trueAndFalse" ? (
            <TrueAndFalse
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}

          {qustype == "Picture MCQ" ? (
            <PictureMCQ
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Match the pairs picture based" ? (
            <Matchthepairspicturebased
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Choose the correct image" ? (
            <Choosethecorrectimage
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
        </form>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#0B2D2D",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MdDelete
                            style={{ float: "right", margin: "20px" }}
                            size={28}
                            onClick={() => del(item)}
                          />
                          <div onClick={() => edit(item)}>
                            {" "}
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                            />{" "}
                          </div>
                          <p>{item.type}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuestion;
