import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {} from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { functions } from "../../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
} from "../../../../utilities/commonStyles/commonStyles";
import { LessonLeftArrowIcons } from "../../../../utilities/Icons/Icons";
import Navbar from "../../Navbar/Navbar";
import ResponsiveNavbar from "../../Navbar/ResponsiveNavbar";
import { ItemContext } from "./Context/ItemContext";
import CourseContent from "./CoureContentMain/CourseContent";
import { CourseItem } from "./CoureContentMain/CourseItem";
import { usePrompt } from "./hooks/usePromptHook";
import "./SingleCourseContentMain.css";
import { IP } from "../../../../config";
import axios from "axios";
import { getCookie } from "../../../../Cookies";

function SingleCourseContentMain() {
  const { t } = useTranslation();
  const location = useLocation();
  console.log("SingleCourseContentMain");
  //console.log(location.state)
  const sampleLessonId = location.state?.sampleLessonId;
  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const getLastItemVisited = httpsCallable(functions, "getLastItemVisited");
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(false);

  const [showTab, setShowTab] = useState(1);
  const [itemsArray, setItemsArray] = useState([]); // quiz is also treated as item
  const [progressIndex, setProgressIndex] = useState(null);
  const [lastItemVisited, setLastItemVisited] = useState("");
  // //console.log(showTab);

  const toggleTab = (index) => {
    setShowTab(index);
    //console.log(index);
  };

  const { courseId } = useParams();
  //console.log(courseId);
  // if courseId not defined send somewhere else

  // const [courseTree, setCourseTree] = useState();
  const [courseData, setCourseData] = useState(null);
  const [isLinear, setIsLinear] = useState(true);
  const [lessonsArray, setLessonsArray] = useState([]);
  async function fetchData() {
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userCourseDetails/course`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
      }),
    };

    const response = await axios(CourseInfo);
    //console.log("cource data:", response.data);
    setCourseData(response.data);
    if (response.data.extraInfo.courseProgress == "linear") {
      if (sampleLessonId) return;
      setIsLinear(true);
    } else {
      setIsLinear(false);
    }
    setLessonsArray(response.data.extraInfo?.lessons);
  }

  //======================================================//

  const [loading, setLoading] = useState(true);

  usePrompt("Do you want to leave ?", true);
  useEffect(() => {
    setLoading(true);
    if (sampleLessonId) {
      setIsLinear(false);
      // getItemsArray({ lessonId: sampleLessonId })

      let ItemsArray = {
        method: "get",
        params: { lessonId: sampleLessonId },
        url: `${IP}/userItemDetails`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
        }),
      };
      axios(ItemsArray).then((result) => {
        setLoading(false);
        const itemsArray = result.data.filter((ele) => ele.type != "node");
        //console.log(itemsArray);
        setItemsArray(itemsArray);
        if (progressIndex == null) setProgressIndex(0);
      });
      return;
    }

    let ItemsArray = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userItemDetails`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
      }),
    };
    axios(ItemsArray).then(async (result) => {
      let LastItemVisited = {
        method: "get",
        params: { courseId: courseId },
        url: `${IP}/userProgress/lastVisited`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
        }),
      };

      const lastItem = await axios(LastItemVisited);

      // const lastItem = await getLastItemVisited({ courseId: courseId });
      setLoading(false);
      const itemsArray = result.data;
      //console.log(itemsArray);
      if (lastItem.data?.itemId) {
        setItemsArray(itemsArray);
        if (lastItem.data.itemType === "video") setLastItemVisited("Video");
        if (lastItem.data.itemType === "pdf") setLastItemVisited("Pdf");
        if (lastItem.data.itemType === "quiz") setLastItemVisited("Quiz");
        if (lastItem.data.itemType === "html") setLastItemVisited("html");
        let index = itemsArray.findIndex(
          (ele) => ele.id == lastItem.data.itemId
        );

        if (location.state?.courseStatus) {
          setProgressIndex(itemsArray.length - 1);
          //console.log("lastItem:", lastItem.data);
        } else {
          setProgressIndex(index);
          //console.log("lastItem:", lastItem.data);
        }
      } else {
        //console.log(itemsArray);
        setItemsArray(itemsArray);
        if (progressIndex == null) setProgressIndex(0);
      }
    });
  }, [lastItemVisited]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("Course Content")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("Course Content")} />
      </Box>
      <Container sx={{ py: "10px" }}>
        <Box
          sx={{
            padding: "25px",
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          /> */}
          <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
            <Typography
              sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <span
                style={{
                  color: "#007C84",
                  fontSize: "12px",
                  fontFamily: "Lato",
                }}
              >
                {courseData?.courseName}
              </span>
              <LessonLeftArrowIcons />
            </Typography>
            <Typography
              sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <span style={{ color: "#007C84", fontSize: "12px" }}>
                Lesson 1
              </span>
              <LessonLeftArrowIcons />
            </Typography>
            <Typography
              sx={{
                color: "#007C84",
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: "Lato",
              }}
            >
              {lastItemVisited
                ? lastItemVisited + " Content"
                : "Lesson Content"}
            </Typography>
          </Box>
          <Box sx={{ ...displayOnDesktop, mr: "5%" }}>
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Box
                className={
                  showTab === 1
                    ? "course-content-active"
                    : "course-content-inactive"
                }
                onClick={() => toggleTab(1)}
              >
                <Typography
                  sx={{
                    pb: "4px",
                    fontWeight: 600,
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                >
                  Course Content
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "3px",
                background: "#F2F2F2",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
        {loading && (
          <Box
            container
            spacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 10, alignItems: "center" }}
          >
            <div className="admin-overdue-bottom-table-cnt-c ">
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td"></div>
              <div className="admin-overdue-bottom-table-td">
                <div className="spinner-container">
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      border: "10px solid #f3f3f3" /* Light grey */,
                      borderTop: "10px solid #007C84" /* Black */,
                      borderRadius: "50%",
                      animation: "spinner 1.5s linear infinite",
                      marginBottom: "200px",
                      marginTop: "120px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </Box>
        )}

        {!loading && (
          <ItemContext.Provider
            value={{
              courseId: courseId,
              itemsArray: itemsArray,
              progressIndex: progressIndex,
              setProgressIndex: setProgressIndex,
              sample: sampleLessonId,
              isLinear: isLinear,
              certificateInfo: location.state?.certificateInfo,
              courseStatus: location.state?.courseStatus,
              showTab: showTab,
              setShowTab: setShowTab,
            }}
          >
            <Box
              sx={{
                ...responsiveFlexDirection,
                width: "100%",
                display: "flex",
                mt: "10px",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: showTab ? "65%" : "100%" },
                  backgroundColor: "#FFF",
                }}
              >
                <CourseItem />
                <Box
                  sx={{
                    ...displayOnMobile,
                    padding: "25px",
                    background: "#fff",
                  }}
                >
                  {/* <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          /> */}

                  <Box sx={{ display: "flex", gap: "30px" }}>
                    <Box
                      className={
                        showTab === 1
                          ? "course-content-active"
                          : "course-content-inactive"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      <Typography
                        sx={{
                          pb: "4px",
                          fontWeight: 600,
                          color: (theme) => theme.palette.primary.main,
                          fontSize: "12px",
                        }}
                      >
                        Course Content
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "3px",
                      background: "#F2F2F2",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Box>

              {showTab != 0 && (
                <Box sx={{ minWidth: { xs: "100%", md: "35%" } }}>
                  {showTab === 1 && (
                    <CourseContent
                      setShowTab={setShowTab}
                      lessons={courseData?.extraInfo?.lessons}
                      quizzes={courseData?.extraInfo?.quizzes}
                    />
                  )}
                </Box>
              )}
            </Box>
          </ItemContext.Provider>
        )}
      </Container>
    </Box>
  );
}

export default SingleCourseContentMain;
