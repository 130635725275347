import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteCookie, getCookie, setCookie } from "../../Cookies";
import "../Translater/Translater";
import CardWrapper from "./CardWrapper";
import "./Sidebar.css";
// import { auth } from "../../Firebase";

function Sidebar({ page, hidepage }) {
  const auth = getAuth();
  const role = getCookie("ROLE");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  // useEffect(() => {
  //   //console.log(clrs);
  // }, [clrs]);
  const [svgClr, setSvgClr] = useState([
    // { iconColor: "#188775", cntColor: "#E6FCF5", txtClr: "#188775" },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    {
      iconColor: page === "Dashboard" ? clrs?.SidebariconClr : null,
      cntColor: page === "Dashboard" ? clrs?.SidebarcntClr : null,
      txtClr: page === "Dashboard" ? clrs?.SidebartxtClr : null,
    },
    {
      iconColor: page === "LMSCourseBuilder" ? clrs.SidebariconClr : null,
      cntColor: page === "LMSCourseBuilder" ? clrs.SidebarcntClr : null,
      txtClr: page === "LMSCourseBuilder" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "training" ? clrs.SidebariconClr : null,
      cntColor: page === "training" ? clrs.SidebarcntClr : null,
      txtClr: page === "training" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "user" ? clrs.SidebariconClr : null,
      cntColor: page === "user" ? clrs.SidebarcntClr : null,
      txtClr: page === "user" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "enrollments" ? clrs.SidebariconClr : null,
      cntColor: page === "enrollments" ? clrs.SidebarcntClr : null,
      txtClr: page === "enrollments" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "certificates" ? clrs.SidebariconClr : null,
      cntColor: page === "certificates" ? clrs.SidebarcntClr : null,
      txtClr: page === "certificates" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "reports" ? clrs.SidebariconClr : null,
      cntColor: page === "reports" ? clrs.SidebarcntClr : null,
      txtClr: page === "reports" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "groups" ? clrs.SidebariconClr : null,
      cntColor: page === "groups" ? clrs.SidebarcntClr : null,
      txtClr: page === "groups" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "groupEnrollments" ? clrs.SidebariconClr : null,
      cntColor: page === "groupEnrollments" ? clrs.SidebarcntClr : null,
      txtClr: page === "groupEnrollments" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "logout" ? clrs.SidebariconClr : null,
      cntColor: page === "logout" ? clrs.SidebarcntClr : null,
      txtClr: page === "logout" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "userview" ? clrs.SidebariconClr : null,
      cntColor: page === "userview" ? clrs.SidebarcntClr : null,
      txtClr: page === "userview" ? clrs.SidebartxtClr : null,
    },
  ]);

  const handleclick = (id) => {
    const narr = svgClr.map((item, i) => {
      if (i === id - 1) {
        return {
          iconColor: clrs.SidebariconClr,
          cntColor: clrs.SidebarcntClr,
          txtClr: clrs.SidebartxtClr,
        };
      } else {
        return {
          iconColor: null,
          cntColor: null,
          txtClr: null,
        };
      }
    });
    //console.log(narr);
    setSvgClr(narr);
  };

  const handleLogout = async () => {
    // await signOut(auth);
    // //console.log(auth.currentUser);
    deleteCookie();
    deleteCookie("keepMeLogin");
    deleteCookie("UID");
    navigate("/");
  };
  const handleUserView = () => {
    setCookie("ROLE", "user");
    navigate("/user/home");
  };
  return (
    <div className="sidebar" style={{ width: `${hidepage ? "6vw" : "19vw"}` }}>
      <div className="sidebar-cnt">
        <div className="sidebar-cnt-head">
          {!hidepage && (
            <div className="sidebar-cnt-head-text">{t("MainMenu")}</div>
          )}
        </div>
        <Link to={"/admin"}>
          <CardWrapper
            title={t("Dashboard")}
            id={1}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link>
        {/*  <Link to={"/admin/courselist"}>
          <CardWrapper
            title={t("LMSCourseBuilder")}
            id={2}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        <Link to={"/admin/training"}>
          <CardWrapper
            title={t("Training")}
            id={3}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link>
        <Link to={"/admin/groupEnrollment"}>
          <CardWrapper
            title={t("Enrollments")}
            id={5}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link>
        {/* <Link to={"/admin/individualEnrollment"}>
          <CardWrapper
            title={t("Individual Enrollments")}
            id={11}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        {role == "admin" && (
          <Link to={"/admin/users"}>
            <CardWrapper
              title={t("Users")}
              id={4}
              svgClr={svgClr}
              handleclick={handleclick}
              hidepage={hidepage}
            />
          </Link>
        )}
        {/* <Link to={"/admin/internalOfflineTraining"}>
          <CardWrapper
            title={t("InternalOfflineTraining")}
            id={5}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link>
        <Link to={"c"}>
          <CardWrapper
            title={t("PreviousTraining")}
            id={5}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        <Link to={"/admin/groups"}>
          <CardWrapper
            title={t("Groups")}
            id={8}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link>
        {role == "admin" && (
          <Link to={"/admin/certificate"}>
            <CardWrapper
              title={t("Certificates")}
              id={6}
              svgClr={svgClr}
              handleclick={handleclick}
              hidepage={hidepage}
            />
          </Link>
        )}
        {role == "admin" && (
          <Link to={"/admin/course-reports"}>
            <CardWrapper
              title={t("CourseReports")}
              id={7}
              svgClr={svgClr}
              handleclick={handleclick}
              hidepage={hidepage}
            />
          </Link>
        )}
        {/*    <Link to={"/admin/groupEnrollment"}>
          <CardWrapper
            title={t("GroupEnrollments")}
            id={9}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}

        {/* <Link to={"/enrollments"}>
          <CardWrapper
            title={t("Enrollments")}
            id={7}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        {/* <Link to={"/admin/externalTraining"}>
          <CardWrapper
            title={t("SubmittedAssignment")}
            id={7}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        {/* <Link to={"/admin/settings"}>
          <CardWrapper
            title={t("Settings")}
            id={7}
            svgClr={svgClr}
            handleclick={handleclick}
            hidepage={hidepage}
          />
        </Link> */}
        <CardWrapper
          title={t("Logout")}
          id={10}
          svgClr={svgClr}
          handleclick={handleLogout}
          hidepage={hidepage}
          // distance="50px"
        />
        <CardWrapper
          title={t("UserView")}
          id={11}
          svgClr={svgClr}
          handleclick={handleUserView}
          hidepage={hidepage}
          distance="90px"
        />
        {/* <div className="user">
             <button
               style={{
                 background: `${
                   clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                 }`,
               }}
             >
               <div>
                 <svg
                   width="auto"
                   height="18"
                   viewBox="0 0 16 18"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <path
                     d="M12.8242 5.54297C12.8242 2.86719 10.6563 0.699219 7.9805 0.699219C5.30472 0.699219 3.13676 2.86719 3.13676 5.54297C3.13676 7.16016 3.92972 8.59375 5.14847 9.47266C5.14066 9.47656 5.1348 9.47851 5.1309 9.48047C4.25785 9.84961 3.47465 10.3789 2.80082 11.0547C2.12699 11.7285 1.59965 12.5137 1.23051 13.3887C0.86918 14.2441 0.675821 15.1523 0.65629 16.0859C0.654337 16.1738 0.724649 16.2461 0.81254 16.2461H1.98246C2.06644 16.2461 2.13676 16.1777 2.13871 16.0938C2.17777 14.5859 2.78129 13.1738 3.84965 12.1035C4.95316 10.9961 6.418 10.3867 7.9805 10.3867C10.6563 10.3867 12.8242 8.21875 12.8242 5.54297ZM7.9805 8.90234C6.12504 8.90234 4.62113 7.39844 4.62113 5.54297C4.62113 3.6875 6.12504 2.18359 7.9805 2.18359C9.83597 2.18359 11.3399 3.6875 11.3399 5.54297C11.3399 7.39844 9.83597 8.90234 7.9805 8.90234ZM10.0313 13.2188H15.1875C15.2735 13.2188 15.3438 13.1484 15.3438 13.0625V11.9688C15.3438 11.8828 15.2735 11.8125 15.1875 11.8125H11.7403L12.6621 10.6387C12.6836 10.6113 12.6953 10.5762 12.6953 10.543C12.6953 10.457 12.625 10.3867 12.5391 10.3867H11.1211C11.0254 10.3867 10.9356 10.4316 10.875 10.5059L9.53714 12.207C9.4512 12.3164 9.40433 12.4531 9.40433 12.5938C9.40628 12.9395 9.68558 13.2188 10.0313 13.2188ZM14.7188 14.4688H9.56253C9.47659 14.4688 9.40628 14.5391 9.40628 14.625V15.7188C9.40628 15.8047 9.47659 15.875 9.56253 15.875H13.0098L12.0879 17.0488C12.0664 17.0762 12.0547 17.1113 12.0547 17.1445C12.0547 17.2305 12.125 17.3008 12.211 17.3008H13.6289C13.7246 17.3008 13.8145 17.2559 13.875 17.1816L15.2129 15.4805C15.2989 15.3711 15.3457 15.2344 15.3457 15.0938C15.3438 14.748 15.0645 14.4688 14.7188 14.4688Z"
                     fill="white"
                   />
                 </svg>
               </div>
               <div>{t("UserView")}</div>
             </button>
           </div> */}
      </div>
      {/* <div className="sidebar-footer">
        <button
          style={{
            backgroundColor: `${
              clrs.EnrolBtnClr ? clrs.EnrolBtnClr : "#007848"
            }`,
          }}
        >
          {t("GenerateReport")}
        </button>
      </div> */}
    </div>
  );
}

export default Sidebar;
