import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Certificates/Certificates.css";
import "../../styles/CourseLibrary/CourseLibraryMain.css";
import "../../Translater/Translater";
import CertificateCard from "../Homepage/Cards/CertificateCard";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import classes from "./MyCertificatesViewAll.module.css";
import { IP } from "../../../config";
import axios from "axios";

function MyCertificateViewAll() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  //================View All cources on tab View All====================//
  const [showAll, setShowAll] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
  });

  //================show cources on tab All/Active/Expired ====================//
  const [status, setStatus] = useState("all");
  const [all, setAll] = useState([]);
  const [active, setActive] = useState([]);
  const [expired, setExpired] = useState([]);

  const showStatusWisw = (status) => {
    if (status === "all") {
      setStatus("all");
      setAllCertificates(all);
    } else if (status === "active") {
      setAllCertificates(active);
      setStatus("active");
      //console.log(active);
      // //console.log((new Date().getTime()))
    } else if (status === "expired") {
      setAllCertificates(expired);
      setStatus("expired");
      //console.log(expired);
    }
  };

  //================ training certificates ================//
  const [allCertificates, setAllCertificates] = useState([]);
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");

  useEffect(() => {
    let certificates = {
      method: "get",
      params: { select: 3 },
      url: `${IP}/userCourse/certificates`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(certificates)
      .then((res) => {
        //console.log(res.data);
        if (res.data?.error) {
          setAllCertificates([]); // set all certificates
          setAll([]); // set all certificates for status all
          return;
        }
        setAllCertificates(res?.data); // set all certificates
        setAll([...res?.data]); // set all certificates for status all

        const temp = [];
        res.data?.forEach((certificate) => {
          if (
            new Date(certificate.expiry._seconds * 1000).getTime() >
            new Date().getTime()
          ) {
            temp.push(certificate);
          }
          // temp.push((new Date(certificate.expiry._seconds * 1000)).getTime() )
        });
        setActive([...temp]); // set active certificates for status active

        const temp2 = [];
        res.data?.forEach((certificate) => {
          if (
            new Date(certificate.expiry._seconds * 1000).getTime() <
            new Date().getTime()
          ) {
            temp2.push(certificate);
          }
        });
        setExpired([...temp2]); // set expired certificates for status expired
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("Home")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("Home")} />
      </Box>
      <Container>
        <Box sx={{ width: "100%", mt: "20px", mb: "20px" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Box>
              <input
                className={classes.input}
                type="radio"
                id="rad1"
                name="rads"
                value={"all"}
                checked={status === "all"}
                onClick={(e) => showStatusWisw(e.target.value)}
              />
              <label className={classes.label} for="rad1">
                All
              </label>
            </Box>
            <Box>
              <input
                className={classes.input}
                type="radio"
                id="rad2"
                name="rads"
                value={"active"}
                onClick={(e) => showStatusWisw(e.target.value)}
              />
              <label className={classes.label} for="rad2">
                Active
              </label>
            </Box>
            <Box>
              <input
                className={classes.input}
                type="radio"
                id="rad3"
                name="rads"
                value={"expired"}
                onClick={(e) => showStatusWisw(e.target.value)}
              />
              <label className={classes.label} for="rad3">
                Expired
              </label>
            </Box>
          </Stack>
          <Box sx={{ width: "100%", mt: "25px" }}>
            <Typography
              sx={{ color: "#00495F", fontWeight: 600, fontSize: "22px" }}
            >
              My Certificates
            </Typography>
          </Box>
          <Stack spacing={1}>
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.primary.main,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Internal Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.primary.main,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.primary.main,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, a: !showAll.a });
                    }}
                  >
                    {showAll.a ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>

              {/* ---------------------------------------------------------------------------------------------------------------- */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.a == true ? (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "Internal"
                    )[0] ? (
                      <Grid container spacing={2}>
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Internal"
                          )
                          ?.map((certificate, index) => {
                            //console.log(certificate);
                            return (
                              <Grid key={index} item md={4}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    background: "#fff",
                                    position: "relative",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "30%",
                                      height: "100%",
                                      background: "#fff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          textTransform: "uppercase",
                                          color: "#2767D1",
                                          fontWeight: 600,
                                          fontSize: "20px",
                                        }}
                                      >
                                        ptw
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "70%",
                                      height: "100%",
                                      background: "#2767D1",
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      clipPath:
                                        "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
                                    }}
                                  >
                                    <Box sx={{ textAlign: "center" }}>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {certificate.certificateName}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#fff",
                                          fontSize: "8px",
                                          fontWeight: 400,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: "#fff",
                                            fontSize: "8px",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textTransform: "none",
                                          }}
                                          href={
                                            certificate.certificateDownloadURL
                                          }
                                          target="_blank"
                                        >
                                          View Certificate
                                        </Button>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "Internal"
                    )[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Internal"
                          )
                          ?.map((certs, index) => {
                            if (index > 1) return;
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                              />
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
              {/* =================================================================================================== */}
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.second,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  External Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.second,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.second,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, b: !showAll.b });
                    }}
                  >
                    {showAll.b ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.b == true ? (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "External"
                    )[0] ? (
                      <Grid container spacing={2}>
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "External"
                          )
                          ?.map((certificate, index) => {
                            //console.log(certificate);
                            return (
                              <Grid key={index} item md={4}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    background: "#fff",
                                    position: "relative",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "30%",
                                      height: "100%",
                                      background: "#fff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          textTransform: "uppercase",
                                          color: "#2767D1",
                                          fontWeight: 600,
                                          fontSize: "20px",
                                        }}
                                      >
                                        ptw
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "70%",
                                      height: "100%",
                                      background: "#2767D1",
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      clipPath:
                                        "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
                                    }}
                                  >
                                    <Box sx={{ textAlign: "center" }}>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {certificate.certificateName}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "8px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: "#fff",
                                            fontSize: "8px",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textTransform: "none",
                                          }}
                                          href={
                                            certificate.certificateDownloadURL
                                          }
                                          target="_blank"
                                        >
                                          View Certificate
                                        </Button>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "External"
                    )[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "External"
                          )
                          ?.map((certs, index) => {
                            if (index > 1) return;
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                              />
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.primary.main,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Internal Offline Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.primary.main,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.primary.main,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, c: !showAll.c });
                    }}
                  >
                    {showAll.c ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.c == true ? (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) =>
                        certificate.trainingType == "Internal Offline"
                    )[0] ? (
                      <Grid container spacing={2}>
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Internal Offline"
                          )
                          ?.map((certificate, index) => {
                            //console.log(certificate);
                            return (
                              <Grid key={index} item md={4}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    background: "#fff",
                                    position: "relative",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "30%",
                                      height: "100%",
                                      background: "#fff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          textTransform: "uppercase",
                                          color: "#2767D1",
                                          fontWeight: 600,
                                          fontSize: "20px",
                                        }}
                                      >
                                        ptw
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "70%",
                                      height: "100%",
                                      background: "#2767D1",
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      clipPath:
                                        "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
                                    }}
                                  >
                                    <Box sx={{ textAlign: "center" }}>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {certificate.certificateName}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "8px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: "#fff",
                                            fontSize: "8px",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textTransform: "none",
                                          }}
                                          href={
                                            certificate.certificateDownloadURL
                                          }
                                          target="_blank"
                                        >
                                          View Certificate
                                        </Button>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) =>
                        certificate.trainingType == "Internal Offline"
                    )[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Internal Offline"
                          )
                          ?.map((certs, index) => {
                            if (index > 1) return;
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                              />
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.second,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Previous Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.second,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.second,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, d: !showAll.d });
                    }}
                  >
                    {showAll.d ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.d == true ? (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "Previous"
                    )[0] ? (
                      <Grid container spacing={2}>
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Previous"
                          )
                          ?.map((certificate, index) => {
                            //console.log(certificate);
                            return (
                              <Grid key={index} item md={4}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    background: "#fff",
                                    position: "relative",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "30%",
                                      height: "100%",
                                      background: "#fff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          textTransform: "uppercase",
                                          color: "#2767D1",
                                          fontWeight: 600,
                                          fontSize: "20px",
                                        }}
                                      >
                                        ptw
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "70%",
                                      height: "100%",
                                      background: "#2767D1",
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      clipPath:
                                        "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
                                    }}
                                  >
                                    <Box sx={{ textAlign: "center" }}>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {certificate.certificateName}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "8px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: "#fff",
                                            fontSize: "8px",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textTransform: "none",
                                          }}
                                          href={
                                            certificate.certificateDownloadURL
                                          }
                                          target="_blank"
                                        >
                                          View Certificate
                                        </Button>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {allCertificates?.filter(
                      (certificate) => certificate.trainingType == "Previous"
                    )[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {allCertificates
                          ?.filter(
                            (certificate) =>
                              certificate.trainingType == "Previous"
                          )
                          ?.map((certs, index) => {
                            if (index > 1) return;
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                              />
                            );
                          })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Dont Have Any Certificates
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default MyCertificateViewAll;
