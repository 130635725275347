import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { auth, functions } from "../../../Firebase";
import { NotificationAIcons } from "../../../utilities/Icons/Icons";
import classes from "./Notification.module.css";
import { Markup } from "interweave";
import { httpsCallable } from "firebase/functions";
import NotificationIcon from "../Navbar/NotificationIcon";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

const NotificationPopup = ({ notifications, notificationsCount }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const markReadNotifications = httpsCallable(
    functions,
    "markReadNotifications"
  );
  const markAllAsRead = () => {
    let configCertificate = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/userProfile/userNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({}),
    };
    axios(configCertificate).then((res) => {
      //console.log(res.data);
    });
  };
  // console.log(notifications);
  return (
    <Box>
      <Box onClick={handleOpen} sx={{ cursor: "pointer" }}>
        <NotificationIcon count={notificationsCount} />
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: "66%", xs: "95%" },
            backgroundColor: "#FFF",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Container>
              <Box sx={{ width: "100%", background: "#fff" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(0, 124, 132, 0.5)",
                    py: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "22px", xs: "18px" },
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    Notification
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { md: "10px", xs: "5px" },
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#000",
                        fontSize: { md: "16px", xs: "12px" },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        markAllAsRead();
                      }}
                    >
                      Mark all as read
                    </Typography>
                    <Button
                      onClick={handleClose}
                      sx={{
                        fontWeight: 600,
                        color: "#000",
                        fontSize: { md: "16px", xs: "12px" },
                        cursor: "pointer",
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
                <Box
                  className={classes.example}
                  sx={{
                    py: "10px",
                    height: { md: "66vh", xs: "70vh" },
                    overflowY: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      padding: { md: "0px 20px", xs: "0px 5px" },
                      width: { md: "85%", xs: "100%" },
                    }}
                  >
                    {notifications?.map((item) => (
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px 0px",
                          gap: { md: "20px", xs: "10px" },
                          borderBottom: "1px solid rgba(0, 124, 132, 0.5)",
                        }}
                      >
                        <Box
                          sx={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: (theme) => theme.palette.primary.main,
                          }}
                        >
                          <NotificationAIcons />
                        </Box>
                        <Box sx={{ width: "95%" }}>
                          {item?.read ? (
                            <Typography
                              sx={{
                                fontSize: { md: "16px", xs: "12px" },
                                fontWeight: 500,
                                color: "#000",
                              }}
                            >
                              {}
                              <Markup content={item.title} />
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: { md: "16px", xs: "12px" },
                                fontWeight: 500,
                                color: "#3EC70B",
                              }}
                            >
                              <Markup content={item.title} />
                            </Typography>
                          )}
                          {/* {
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                                color: "#000",
                              }}
                            >
                              {item.message}
                            </Typography>
                          } */}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default NotificationPopup;
