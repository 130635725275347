import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ChemistryIcons,
  EnglishIcons,
  PhysicsIcons,
  PlusIcons,
} from "../../../../utilities/Icons/Icons";

import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../config";
import { getCookie } from "../../../../Cookies";
import axios from "axios";

const TrainingCard = ({ training, typ }) => {
  const { t } = useTranslation();
  const dueDate = Timestamp.fromMillis(training?.dueDate?._seconds * 1000);
  const expiryDate = Timestamp.fromMillis(training?.expiry?._seconds * 1000);
  const fetchCourseCertificate = httpsCallable(
    functions,
    "fetchCourseCertificate"
  );
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const resetItemProgress = httpsCallable(functions, "resetItemProgress");
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (typ == "internal") {
      // fetchCourseCertificate({ courseId: training?.courseId })

      let cirtiCOnfig = {
        method: "get",
        params: { courseId: training?.courseId },
        url: `${IP}/userCourse/courseCertificate`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "",
      };
      axios(cirtiCOnfig).then((res) => {
        if (res.data.error) return;
        if (
          res.data.expiry._seconds < Math.floor(new Date().getTime() / 1000)
        ) {
          setExpired(true);
        } else {
          setExpired(false);
        }
      });
    }
  }, []);

  const renewCourse = (courseId) => {
    // get items array and reset it then goto course

    let ItemsArray = {
      method: "get",
      params: { select: 1, courseId: courseId },
      url: `${IP}/userItemDetails`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: JSON.stringify({
      //   courseId: courseId,
      // }),
    };
    axios(ItemsArray).then((res) => {
      //console.log(res.data);

      let ItemsArray = {
        method: "post",
        params: { select: 1 },
        url: `${IP}/userItemDetails`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemArray: res.data,
        }),
      };
      axios(ItemsArray).then((res) => {
        navigate(
          `/user/course-library/single-course-overview/single-course-content/${courseId}`
        );
      });
      // resetItemProgress({ courseId: courseId, itemArray: res.data });
    });
    // navigate(
    //   `/user/course-library/single-course-overview/single-course-content/${courseId}`
    // );
  };
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          background: "#fff",
          padding: "15px",
          borderRadius: "10px",
          boxShadow: "4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
        }}
      >
        <Box spacing="true" sx={{ display: "flex" }}>
          <Box sx={{ width: "20%" }}>
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: (theme) => theme.palette.primary.main,
              }}
            >
              {typ == "internal" && <EnglishIcons />}
              {typ == "internalOffline" && <ChemistryIcons />}
              {typ == "external" && <PhysicsIcons />}
              {typ == "previous" && <PlusIcons />}
            </Box>
          </Box>
          <Box
            sx={{
              width: "80%",
              marginTop: "10px",
              ml: 1,
            }}
          >
            <Box>
              <Box>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {training?.courseName}
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                  }}
                >
                  {training.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "#007C84",
                    fontSize: "10px",
                    fontFamily: "'Readex Pro', sans-serif",
                  }}
                >
                  {typ != "previous" &&
                    dueDate.toDate().toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  {typ == "previous" &&
                    expiryDate.toDate().toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                </Typography>
                {typ === "internal" && (
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "5px 30px",
                      background: (theme) => theme.palette.primary.main,
                      borderRadius: "5px",
                      fontWeight: 600,
                      fontSize: "12px",
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      if (
                        expired &&
                        training.progress.total == training.progress.completed
                      ) {
                        // training.progress.total == training.progress.completed
                        renewCourse(training.courseId);
                        // expired
                      } else {
                        navigate(
                          `/user/course-library/single-course-overview/single-course-content/${training?.courseId}`
                        );
                      }
                    }}
                  >
                    {expired
                      ? training.progress.total == training.progress.completed
                        ? "renew"
                        : "Continue"
                      : training.progress.total == training.progress.completed
                      ? training.progress.total == 0 &&
                        training.completionDate == null
                        ? "start"
                        : "Completed"
                      : new Date(training?.dueDate?._seconds * 1000) <
                        new Date()
                      ? "overDue"
                      : training.progress.completed == 0
                      ? "Start"
                      : "Continue"}
                    {/* {training.progress.total == training.progress.completed
                      ? t("Completed")
                      : training.progress.completed == 0
                      ? t("Start")
                      : t("Continue")} */}
                  </Button>
                )}
                {(typ == "external" || typ == "internalOffline") && (
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "5px 30px",
                      background: (theme) => theme.palette.primary.main,
                      borderRadius: "5px",
                      fontWeight: 600,
                      fontSize: "12px",
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      if (typ == "internalOffline") {
                        navigate(
                          `/user/training/internal-offline-training/view-materials`,
                          {
                            state: { courseId: training?.courseId },
                          }
                        );
                      } else {
                        navigate(
                          `/user/training/external-training/view-materials`,
                          {
                            state: { courseId: training?.courseId },
                          }
                        );
                      }
                    }}
                  >
                    {t("View")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default TrainingCard;
