export const quizdata = [
  {
    completed: false,
    type: "MCQ",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    options: [
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
    ],
  },
  {
    completed: false,
    type: "MCQ",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    options: [
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
    ],
  },
  {
    completed: false,
    type: "MCQ",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    options: [
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
    ],
  },
  {
    completed: false,
    type: "rearange",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    options: [
      {
        txt: "option 1",
      },
      {
        txt: "option 2",
      },
      {
        txt: "option 3",
      },
      {
        txt: "option 4",
      },
    ],
  },
  {
    completed: false,
    type: "fillblanks",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, ____ consectetur adipiscing elit ?",
  },
  {
    completed: false,
    type: "matchpair",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    optionsleft: [
      {
        txt: "option r1",
      },
      {
        txt: "option r2",
      },
      {
        txt: "option r3",
      },
      {
        txt: "option r4",
      },
    ],
    optionsright: [
      {
        txt: "option l1",
      },
      {
        txt: "option l2",
      },
      {
        txt: "option l3",
      },
      {
        txt: "option l4",
      },
    ],
  },
  {
    completed: false,
    type: "singlepicMCQ",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    url: "https://i.imgur.com/2HeX8GF.jpeg",
    options: [
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
      {
        txt: "option",
      },
    ],
  },
  {
    completed: false,
    type: "MCQpic",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    options: [
      {
        txt: "option",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
    ],
  },
  {
    completed: false,
    type: "matchpic",
    lession: 1,
    question: " Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
    optionsleft: [
      {
        txt: "option 1",
      },
      {
        txt: "option 2",
      },
      {
        txt: "option 3",
      },
      {
        txt: "option 4",
      },
    ],
    optionsright: [
      {
        txt: "option 1",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option 2",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option 3",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
      {
        txt: "option 4",
        url: "https://i.imgur.com/2HeX8GF.jpeg",
      },
    ],
  },
];

export const quizdataLession = {
  course: 1,
  lessionNo: 1,
  quizNo: 1,
  lessionName: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
};
