import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import {
  displayOnDesktop
} from "../../../utilities/commonStyles/commonStyles";
import "../../Translater/Translater";
import SideNavbar from "./SideNavbar";
function Sidebar({ page }) {
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  // useEffect(() => {
  //   //console.log(clrs);
  // }, [clrs]);
  const [svgClr, setSvgClr] = useState([
    // { iconColor: "#188775", cntColor: "#E6FCF5", txtClr: "#188775" },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    {
      iconColor: page === "home" ? clrs.SidebariconClr : null,
      cntColor: page === "home" ? clrs.SidebarcntClr : null,
      txtClr: page === "home" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "courselibrary" ? clrs.SidebariconClr : null,
      cntColor: page === "courselibrary" ? clrs.SidebarcntClr : null,
      txtClr: page === "courselibrary" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "training" ? clrs.SidebariconClr : null,
      cntColor: page === "training" ? clrs.SidebarcntClr : null,
      txtClr: page === "training" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "profile" ? clrs.SidebariconClr : null,
      cntColor: page === "profile" ? clrs.SidebarcntClr : null,
      txtClr: page === "profile" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "yourassignment" ? clrs.SidebariconClr : null,
      cntColor: page === "yourassignment" ? clrs.SidebarcntClr : null,
      txtClr: page === "yourassignment" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "certificate" ? clrs.SidebariconClr : null,
      cntColor: page === "certificate" ? clrs.SidebarcntClr : null,
      txtClr: page === "certificate" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "userreports" ? clrs.SidebariconClr : null,
      cntColor: page === "userreports" ? clrs.SidebarcntClr : null,
      txtClr: page === "userreports" ? clrs.SidebartxtClr : null,
    },
  ]);

  const handleclick = (id) => {
    const narr = svgClr.map((item, i) => {
      if (i === id - 1) {
        return {
          iconColor: clrs.SidebariconClr,
          cntColor: clrs.SidebarcntClr,
          txtClr: clrs.SidebartxtClr,
        };
      } else {
        return {
          iconColor: null,
          cntColor: null,
          txtClr: null,
        };
      }
    });
    //console.log(narr);
    setSvgClr(narr);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          ...displayOnDesktop,
          boxShadow: "10px 0px 30px 0px rgba(229, 229, 229, 1)",
        }}
      >
        <SideNavbar />
      </Box>
      <Box sx={{ width: "100%", background: "#F5FAFA" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Sidebar;

{
  /* import React, { useState } from "react";
import "../../styles/Sidebar/Sidebar.css";
import CardWrapper from "./CardWrapper ";
import { useTranslation } from "react-i18next";
import "../../Translater/Translater";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Sidebar({ page }) {
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  // useEffect(() => {
  //   //console.log(clrs);
  // }, [clrs]);
  const [svgClr, setSvgClr] = useState([
    // { iconColor: "#188775", cntColor: "#E6FCF5", txtClr: "#188775" },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    {
      iconColor: page === "home" ? clrs.SidebariconClr : null,
      cntColor: page === "home" ? clrs.SidebarcntClr : null,
      txtClr: page === "home" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "courselibrary" ? clrs.SidebariconClr : null,
      cntColor: page === "courselibrary" ? clrs.SidebarcntClr : null,
      txtClr: page === "courselibrary" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "training" ? clrs.SidebariconClr : null,
      cntColor: page === "training" ? clrs.SidebarcntClr : null,
      txtClr: page === "training" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "profile" ? clrs.SidebariconClr : null,
      cntColor: page === "profile" ? clrs.SidebarcntClr : null,
      txtClr: page === "profile" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "yourassignment" ? clrs.SidebariconClr : null,
      cntColor: page === "yourassignment" ? clrs.SidebarcntClr : null,
      txtClr: page === "yourassignment" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "certificate" ? clrs.SidebariconClr : null,
      cntColor: page === "certificate" ? clrs.SidebarcntClr : null,
      txtClr: page === "certificate" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "userreports" ? clrs.SidebariconClr : null,
      cntColor: page === "userreports" ? clrs.SidebarcntClr : null,
      txtClr: page === "userreports" ? clrs.SidebartxtClr : null,
    },
  ]);

  const handleclick = (id) => {
    const narr = svgClr.map((item, i) => {
      if (i === id - 1) {
        return {
          iconColor: clrs.SidebariconClr,
          cntColor: clrs.SidebarcntClr,
          txtClr: clrs.SidebartxtClr,
        };
      } else {
        return {
          iconColor: null,
          cntColor: null,
          txtClr: null,
        };
      }
    });
    //console.log(narr);
    setSvgClr(narr);
  };
  return (
    <div className="sidebar">
      <div className="sidebar-cnt">
        <div className="sidebar-cnt-head">
          <div className="sidebar-cnt-head-text">{t("MainMenu")}</div>
        </div>
        <Link to={"/user"}>
          <CardWrapper
            title={t("Home")}
            id={1}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/courselibrary"}>
          <CardWrapper
            title={t("CourseLibrary")}
            id={2}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/training"}>
          <CardWrapper
            title={t("Training")}
            id={3}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/profile"}>
          <CardWrapper
            title={t("Profile")}
            id={4}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/yourassignment"}>
          <CardWrapper
            title={t("YourAssignment")}
            id={5}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/certificates"}>
          <CardWrapper
            title={t("Certificates")}
            id={6}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
        <Link to={"/user/userreports"}>
          <CardWrapper
            title={t("UserReports")}
            id={7}
            svgClr={svgClr}
            handleclick={handleclick}
          />
        </Link>
      </div>
      <div className="sidebar-footer">
        <button
          style={{
            backgroundColor: `${
              clrs.EnrolBtnClr ? clrs.EnrolBtnClr : "#007848"
            }`,
          }}
        >
          {t("GenerateReport")}
        </button>
      </div>
    </div>
  );
}

export default Sidebar; */
}
