import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Homepage/Homepage.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import TrainingCard from "./Cards/TrainingCard";
import { IP } from "../../../config";
import axios from "axios";
function MyCourses() {
  const { t } = useTranslation();

  //================View All cources on tab View All====================//
  const [showAll, setShowAll] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
  });

  //================ training courses ================//
  const [internalTraining, setInternalTraining] = useState([]);
  const [externalTraining, setExternalTraining] = useState([]);
  const [previousTraining, setPreviousTraining] = useState([]);
  const [internalOfflineTraining, setInternalOfflineTraining] = useState([]);
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");

  useEffect(() => {
    let configLesson = {
      method: "get",
      params: { select: 0 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configLesson).then((result) => {
      //console.log(result);
      setExternalTraining([...result.data].sort(() => 0.5 - Math.random()));
    });
    let InternalTraining = {
      method: "get",
      params: { select: 1 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(InternalTraining).then((result) => {
      // //console.log(result);
      setInternalTraining([...result.data].sort(() => 0.5 - Math.random()));
    });
    let InternalOffline = {
      method: "get",
      params: { select: 2 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(InternalOffline).then((result) => {
      // //console.log(result.data);
      setInternalOfflineTraining(
        [...result.data].sort(() => 0.5 - Math.random())
      );
    });
    let certificates = {
      method: "get",
      params: { select: 3 },
      url: `${IP}/userCourse/certificates`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(certificates).then((result) => {
      // //console.log(result);
      setPreviousTraining(
        [...result.data]
          .reverse()
          .filter((ele) => ele.trainingType == "Previous")
          .map((ele) => ({ ...ele, courseName: ele.certificateName }))
      );
    });
  }, []);

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("My Courses")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("My Courses")} />
      </Box>
      <Box sx={{ width: "100%", pb: 4 }}>
        <Container>
          <Stack spacing="40px">
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.main,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Internal Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.main,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.main,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, a: !showAll.a });
                    }}
                  >
                    {showAll.a ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.a === true ? (
                  <React.Fragment>
                    {internalTraining[0]
                      ? internalTraining?.map((course, index) => {
                          const typ = "internal";
                          return (
                            <TrainingCard
                              key={index}
                              training={course}
                              typ={typ}
                            />
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {internalTraining[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {internalTraining?.map((training, index) => {
                          const typ = "internal";
                          if (index > 1) return;
                          return (
                            <TrainingCard
                              key={index}
                              training={training}
                              typ={typ}
                            />
                          );
                        })}
                        :
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Need To Enroll Internal Training First
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.second,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  External Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.second,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.second,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, b: !showAll.b });
                    }}
                  >
                    {showAll.b ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.b === true ? (
                  <React.Fragment>
                    {externalTraining[0]
                      ? externalTraining?.map((course, index) => {
                          const typ = "external";
                          return (
                            <TrainingCard
                              key={index}
                              training={course}
                              typ={typ}
                            />
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {externalTraining[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {externalTraining?.map((training, index) => {
                          if (index > 1) return;
                          const typ = "external";
                          return (
                            <TrainingCard
                              key={index}
                              training={training}
                              typ={typ}
                            />
                          );
                        })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Need To Enroll Internal Training First
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.main,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Internal Offline Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.main,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.main,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, c: !showAll.c });
                    }}
                  >
                    {showAll.c ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.c === true ? (
                  <React.Fragment>
                    {internalOfflineTraining[0]
                      ? internalOfflineTraining?.map((course, index) => {
                          const typ = "internalOffline";
                          return (
                            <TrainingCard
                              key={index}
                              training={course}
                              typ={typ}
                            />
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {internalOfflineTraining[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {internalOfflineTraining?.map((training, index) => {
                          if (index > 1) return;
                          const typ = "internalOffline";
                          return (
                            <TrainingCard
                              key={index}
                              training={training}
                              typ={typ}
                            />
                          );
                        })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Need To Enroll Internal Training First
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>

            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: "30px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: { xs: "120px", md: "220px" },
                    textAlign: "center",
                    fontWeight: 600,
                    background: (theme) => theme.palette.secondary.second,
                    padding: "6px",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: { xs: "8px", md: "16px" },
                  }}
                >
                  Previous Training
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    background: (theme) => theme.palette.secondary.second,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.secondary.second,
                      position: "absolute",
                      top: "-4px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontWeight: 500,
                      color: "#007C84",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAll({ ...showAll, d: !showAll.d });
                    }}
                  >
                    {showAll.d ? t("Show Less") : t("View All")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {showAll.d === true ? (
                  <React.Fragment>
                    {previousTraining[0]
                      ? previousTraining?.map((course, index) => {
                          const typ = "previous";
                          return (
                            <TrainingCard
                              key={index}
                              training={course}
                              typ={typ}
                            />
                          );
                        })
                      : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {previousTraining[0] ? (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {previousTraining?.map((training, index) => {
                          if (index > 1) return;
                          const typ = "previous";
                          return (
                            <TrainingCard
                              key={index}
                              training={training}
                              typ={typ}
                            />
                          );
                        })}
                      </Grid>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                          borderRadius: "10px",
                          color: "#007848",
                          fontFamily: "'Readex Pro', sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        You Need To Enroll Internal Training First
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default MyCourses;
