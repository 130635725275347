import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createClrsReducer } from "./Admin/Redux/Reducers";
import { userScoreReducer } from "./Admin/Redux/score/Score";
import { userProgressReducer } from "./Admin/Redux/progress/progrees";
import {
  blue,
  darkblue,
  newGreen,
  defultGreen,
} from "../src/Admin/Redux/Colors";
const reducer = combineReducers({
  createClr: createClrsReducer,
  userScore: userScoreReducer,
  userProgress: userProgressReducer,
});

const newClrs = newGreen;

const userClrFromStorage = localStorage.getItem("clrs")
  ? JSON.parse(localStorage.getItem("clrs"))
  : localStorage.setItem("clrs", JSON.stringify(newClrs));

let sco = {
  score: "90",
  pass: "80",
};

let prog = {
  total: 10,
  complied: 10,
};
const intitialState = {
  //   createClr: {
  //     clrs: {
  //       SidebariconClr: "#D7D7D7",
  //       SidebarcntClr: "#ffffff",
  //       SidebartxtClr: "#717579",
  //     },
  //   },
  createClr: { clrs: userClrFromStorage },
  userScore: { userscore: sco },
  userProgress: { progress: prog },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  intitialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
