import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { functions } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./StyleAdminComponents/GroupList.css";
import Notify from "./Dashboard/Notify";
import "./Dashboard/OverdueEnrollments.css";
import GenerateReport from "./Dashboard/popUpForReports";
import { create } from "sortablejs";
import GenerateReportForLocal from "./Dashboard/popUpForLocalReport";

import "jspdf-autotable";
import jsPDF from "jspdf";
import goss from "./goss.png";
import moraikh from "./moraikh.png";
import alghat from "./alghat.png";
import ocasco from "./ocasco.png";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import GenerateReportForEmployee from "./report/employeeReport";
import GenerateReportGroup from "./report/groupReport";
import GenerateReportCompany from "./report/companyReport";
const storage = getStorage();
function GenerateReportPage() {
  const { clrs } = useSelector((state) => state.createClr);

  const [selectDefault, setselectDefault] = useState("default");
  const [downloadReportType, setdownloadReportType] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {/* {displayNotify && <Notify NotifyClick={NotifyClick} />} */}
      <Navbar page={t("reports")} />
      <div className="createcourse-main">
        <Sidebar page={"reports"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("Generate Reports")}
            </div>
          </div>

          <div
            style={{
              marginLeft: "72.5vw",
              marginTop: "-4vw",
              marginBottom: "2vw",
              display: "flex",
              gap: "20px",
            }}
            className="admin-dashboard-main-top-filter"
          >
            {/* <button
              
            > */}
            {/* <div>{t("Download")}</div> */}
            <select
              style={{
                height: "30px",
                width: "50%",
              }}
              value={downloadReportType}
              onChange={(e) => {
                // generateReportsFunction("");
                if (e.target.value) {
                  // setdownloadReportType("local");
                  setdownloadReportType(e.target.value);
                }
              }}
            >
              {/* Your select options here */}
              <option value="">Download report</option>

              {selectDefault != "default" && (
                <>
                  {selectDefault != "Employee" && (
                    <option value="currentpage">Current Page</option>
                  )}
                  <option value="allpage">All page</option>
                </>
              )}
            </select>
            {/* </button> */}
          </div>

          <div className="admin-overdue-mid" style={{}}>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Reports Type")}
              </div>

              <div className="admin-overdue-mid-left-input">
                <select
                  name="courseName"
                  value={selectDefault}
                  onChange={(e) => {
                    setselectDefault(e.target.value);
                    // handleStatusChange(e.target.value);
                  }}
                  id="company"
                >
                  <option value="">Select Report Type</option>
                  <option value="Employee">Employee</option>
                  <option value="Group">Group</option>
                  <option value="Company">Company</option>
                </select>
              </div>
            </div>
          </div>

          <div>
            {selectDefault == "Employee" && (
              <GenerateReportForEmployee
                reportType={downloadReportType}
                setdownloadReportType={setdownloadReportType}
              />
            )}

            {selectDefault == "Group" && (
              <GenerateReportGroup
                reportType={downloadReportType}
                setdownloadReportType={setdownloadReportType}
              />
            )}
            {selectDefault == "Company" && (
              <GenerateReportCompany
                reportType={downloadReportType}
                setdownloadReportType={setdownloadReportType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateReportPage;
