import React from "react";
import { useState, setState, useEffect } from "react";
import { setCookie, getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import {
  useNavigate,
  Link,
  Navigate,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/GroupList.css";
import { render } from "@testing-library/react";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { t } from "i18next";
import "./Groups.css";
import { useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { IP } from "../../../config";
import axios from "axios";

function Groups() {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const archiveGroup = httpsCallable(functions, "archiveGroup");
  const unarchiveGroup = httpsCallable(functions, "unarchiveGroup");

  const deleteGroup = httpsCallable(functions, "deleteGroup");
  const [groups, setGroups] = useState(null);
  const [showLoading, setLoading] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [extraArrForGroupUser, setExtraArrForGroupUser] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  let stateDataFromGroupEdit = useLocation();
  // if(stateDataFromGroupEdit.state.formGroup==true)
  // useEffect(()=>{

  // },[stateDataFromGroupEdit.state])
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const goToEditPage = (state, e, id) => {
    if (e.target.value == "archive") {
      setTemploading(id);

      let configgroupEnrollment = {
        method: "patch",
        // params: { tempuid: data.id },
        url: `${IP}/group/archive`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          groupId: state.groupId,
        }),
      };
      axios(configgroupEnrollment)
        .then((res) => {
          //console.log(res);
          setTemploading("test");
          let archivedData = { ...state, archived: true };

          let dataddd = extraArrForGroupUser.filter((el, index) => index != id);
          setGroups(dataddd);

          setArchivedDataArr((prev) => prev.concat(archivedData));
          setExtraArrForGroupUser(dataddd);

          setCurrentPage(0);
          setArrLength(dataddd.length);
          // setHandelUseEffectTogal(!handelUseEffectTogal);
        })
        .catch((err) => {
          setTemploading("test");
        });

      return;
    }

    if (e.target.value == "unarchive") {
      setTemploading(id);

      let configgroupEnrollment = {
        method: "post",
        // params: { tempuid: data.id },
        url: `${IP}/group/archive`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          groupId: state.groupId,
        }),
      };
      axios(configgroupEnrollment)
        .then((res) => {
          //console.log(res);
          setTemploading("test");
          // setHandelUseEffectTogal(!handelUseEffectTogal);
          let archivedData = { ...state, archived: false };

          let dataddd = archiveDataArr.filter((el, index) => index != id);
          setGroups(dataddd);

          setExtraArrForGroupUser((prev) => prev.concat(archivedData));
          setArchivedDataArr(dataddd);

          setCurrentPage(0);
          setArrLength(dataddd.length);

          alert(res.data.message);

          let configgroupEnrollment = {
            method: "post",
            // params: { tempuid: data.id },
            url: `${IP}/mail/sendMailGroupActivated`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
            data: JSON.stringify({
              groupName: state.groupName,
            }),
          };
          axios(configgroupEnrollment);
        })
        .catch((err) => {
          setTemploading("test");
        });

      return;
    }
    console.log(state);

    if (e.target.value == "delete") {
      let configgroupEnrollment = {
        method: "delete",
        // params: { tempuid: data.id },
        url: `${IP}/group`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          groupId: state.groupId,
        }),
      };
      axios(configgroupEnrollment).then((res) => {
        setHandelUseEffectTogal(!handelUseEffectTogal);
        alert("deleted");
      });
      return;
    }
    navigate("/admin/groupEdits", { state: { ...state } });
  };

  const handleOnChange = (e) => {
    var url = `/admin/groupEdits/${e}`;
    if (e) {
      //console.log(url);
      navigate(url);
    }
  };

  useEffect(() => {
    let configgroupEnrollment = {
      method: "get",
      // params: { tempuid: data.id },
      url: `${IP}/group/groupList`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configgroupEnrollment).then((result) => {
      if (result.data?.error) {
        //console.log(result.data?.error);
        setGroups([]);
        setExtraArrForGroupUser([]);
        setButtonTogalForarchived(true);
        setArrLength(0);
        setLoading(false);
        return;
      }

      setButtonTogalForarchived(true);
      setExtraArrForGroupUser(
        result.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setGroups(
        result.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setArchivedDataArr(
        result.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived == true)
      );
      setArrLength(result?.data.length);
      setLoading(false);
    });
  }, [handelUseEffectTogal]);

  // const archiveRows = (e) => {
  //   e.preventDefault();
  //   var inputs = document.querySelectorAll(".row_select");

  //   for (var i = 0; i < inputs.length; i++) {
  //     if (inputs[i].checked) //console.log(inputs[i].value);
  //   }
  // };

  function createdDate(createdOn) {
    let displayDate = Timestamp.fromMillis(createdOn._seconds * 1000)
      .toDate()
      .toDateString();
    return displayDate;
  }

  useEffect(() => {}, []);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const buttonForArchivedFun = (type) => {
    if (type == "user") {
      setGroups(extraArrForGroupUser);
      setCurrentPage(0);
      setArrLength(extraArrForGroupUser.length);
    } else if (type == "archive") {
      setGroups(archiveDataArr);
      setCurrentPage(0);
      setArrLength(archiveDataArr.length);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  return (
    <div className="admin-groups">
      <Navbar page={t("Groups")} />
      <div
        className="admin-groups-main"
        style={{ gridTemplateColumns: "19vw 1fr" }}
      >
        <Sidebar page={"groups"} hidepage={false} />
        <div className="admin-overdue">
          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: " 3vh",
              width: "10vh",
              marginTop: "1vw",
              marginLeft: "67.5vw",
            }}
          >
            {buttonTogalForChangeState ? (
              <button
                style={{
                  width: "10vw",

                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  buttonForArchivedFun("archive");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Archive ")}</div>
              </button>
            ) : (
              <button
                style={{
                  width: "10vw",

                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  buttonForArchivedFun("user");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Groups")}</div>
              </button>
            )}
          </div>

          <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}

            <div
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                marginTop: "6vw",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("GroupList")}
            </div>
            <div style={{ display: "flex", hight: "25vh" }}>
              <div>
                <Link to={"/admin/groupEdits/"}>
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      width: "13vw",
                      fontWeight: "500",
                      height: "5vh",
                    }}
                  >
                    {t("AddGroup")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div className="admin-overdue-bottom-table-cnt-grp">
                <div className="admin-overdue-bottom-table-head">
                  {t("SRNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Group ID")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("NumberOfMember")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("NoOfCourse")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CreatedOn")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {groups?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;
                return (
                  <div className="admin-overdue-bottom-table-cnt-grp" key={id}>
                    <div className="admin-overdue-bottom-table-td">
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.groupId}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.groupName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item["groupMembers"] == undefined
                        ? "0"
                        : item.groupMembers.length}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item["courseList"] == undefined
                        ? "0"
                        : item.courseList.length}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {createdDate(item.createdOn)}
                    </div>

                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <div>
                        {item.archived ? (
                          tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(e) => {
                                goToEditPage(item, e, id);
                              }}
                            >
                              <option>{t("Action")}</option>
                              <option value={"unarchive"} id={item.groupName}>
                                {" "}
                                {t("Unarchived")}
                              </option>
                            </select>
                          )
                        ) : tempLoading == id ? (
                          <p>Loading...</p>
                        ) : (
                          <select
                            style={{
                              border: "none",
                              backgroundColor: "#F2F2F2",
                              height: "4vh",
                              width: "7vw",
                              padding: "0 1vh",
                              fontWeight: "700",
                              color: "#717579",
                              borderRadius: "6px",
                            }}
                            onChange={(e) => {
                              goToEditPage(item, e, id);
                            }}
                          >
                            <option>{t("Action")}</option>
                            <option value={item.groupName} id={item.groupName}>
                              {" "}
                              {t("Edit")}
                            </option>
                            <option value={"delete"}>{t("Delete")}</option>

                            <option value={"archive"}>{t("Archive")}</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>

                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Groups;
