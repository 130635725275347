import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import { ReactComponent as EndQuizPass } from "./icons/endQuizPass.svg";
import { ItemContext } from "../Context/ItemContext";
import { useSelector, useDispatch } from "react-redux";
import { userProgress } from "../../../../../Admin/Redux/progress/action";
import { getCookie } from "../../../../../Cookies";
import { Box, Button } from "@mui/material";
import { IP } from "../../../../../config";
import axios from "axios";
import LoadingScreen from "../../../loadingScreen";
import { toast } from "react-toastify";

const Node = ({
  node,
  setCanNavigate,
  courseStatus,
  certificateInfo,
  itemsArray,
}) => {
  const { score } = useSelector((state) => state.userScore);
  const { courseId, isLinear, progressIndex, setProgressIndex } =
    useContext(ItemContext);
  const setTopicComplete = httpsCallable(functions, "setTopicComplete");
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const setLessonComplete = httpsCallable(functions, "setLessonComplete");
  const setCourseComplete = httpsCallable(functions, "setCourseComplete");
  const generateCertificate = httpsCallable(functions, "generateCertificate");
  const [certificateLink, setCertificateLink] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const mainItem = itemsArray[progressIndex];
  const lessonCompletedUserNotification = httpsCallable(
    functions,
    "lessonCompletedUserNotification"
  );
  const [progress, setProgress] = useState(null);
  const [isNodeComplete, setIsNotComplete] = useState(false);
  console.log("itemsArrayNode", itemsArray);
  console.log("progressIdx", progressIndex);

  const callApiInOne = async () => {
    let timesNow = new Date();
    // sendMailLessonCompleted
    let sendMailLessonCompleted = {
      method: "post",
      // params: { courseId: courseId, lessonId: node.id },
      url: `${IP}/mail/sendMailLessonCompleted`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        lessonName: mainItem.name,
        lessonId: mainItem.id,
      }),
    };
    // await axios(sendMailLessonCompleted);
    //  nodeProgress
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userItemDetails/nodeProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => {
      setProgress(result.data);
    });
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });
    //userProgress/Lessons
    let lessonProgress = {
      method: "patch",
      // params: { courseId: courseId, topicId: node.id },
      url: `${IP}/userProgress/lessons`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        lessonId: mainItem.id,
        lastVisited: timesNow.toString(),
      }),
    };
    // axios(lessonProgress);

    //userProgess
    let courseProgress = {
      method: "patch",
      // params: { courseId: courseId },
      url: `${IP}/userProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        completionDate: timesNow.toString(),
        courseId: courseId,
        passingScore: score?.passingScore ? score?.passingScore : 80,
        userScore: score?.userScore ? score?.userScore : 90,
      }),
    };
    // axios(courseProgress);

    // certificate/certificate
    let certificate = {
      method: "post",
      // params: { courseId: courseId },
      url: `${IP}/certificate/certificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        completionDate: timesNow.toString(),
        passingScore: score?.passingScore ? score?.passingScore : 80,
        userScore: score?.userScore ? score?.userScore : 90,
      }),
    };
    await axios(certificate).then((res) => {
      setLoading(false);
      setCertificateLink(res.data.link);
    });
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });
  };

  const checkForLast = () => {
    let finalIdx = 0;

    for (let i = itemsArray.length - 1; i >= 0; i--) {
      const val = itemsArray[i];
      if (val.type === "quiz") {
        finalIdx = i;
        break;
      } else if (val.type === "item") {
        finalIdx = i;
        break;
      } else if (val.type === "node" && val.nodeType === "topic") {
        finalIdx = i;
        break;
      }
    }
    return finalIdx;
  };

  const [cirtificateLoading, setCirtificateLoading] = useState(false);
  let dispatch = useDispatch();
  useEffect(async () => {
    let timesNow = new Date();
    console.log(checkForLast());
    if (progressIndex >= checkForLast()) {
      setLoading(true);
      callApiInOne();
    }
    setCanNavigate(true);
    //console.log(isLinear);
    if (!isLinear) {
      if (node.nodeType == "topic") {
        // getNodeProgress({
        //   courseId: courseId,
        //   topicId: node.id,
        // })

        let topicProgress = {
          method: "get",
          params: { courseId: courseId, topicId: node.id },
          url: `${IP}/userProgress/topic`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "",
        };
        axios(topicProgress).then((result) => {
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            setIsNotComplete(true);

            let topicProgress = {
              method: "patch",
              // params: { courseId: courseId, topicId: node.id },
              url: `${IP}/userProgress/topic`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                topicId: node.id,
                lastVisited: timesNow.toString(),
              }),
            };
            axios(topicProgress);
            // .then((res) => {})
            // .catch((e) => {
            //   setLoading(false);
            //   setError(e.error ? e.error : "something wrong happened");
            // });
            // setTopicComplete({

            // });
            let sendMailTopicCompleted = {
              method: "post",
              // params: { courseId: courseId, lessonId: node.id },
              url: `${IP}/mail/sendMailTopicCompleted`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                topicName: node.name,
              }),
            };
            axios(sendMailTopicCompleted);
            // .then((res) => {})
            // .catch((e) => {
            //   setLoading(false);
            //   setError(e.error ? e.error : "something wrong happened");
            // });
          }
        });
      } else if (node.nodeType == "lesson") {
        // getNodeProgress({

        // });

        let lessonProgress = {
          method: "get",
          params: { courseId: courseId, lessonId: node.id },
          url: `${IP}/userProgress/lessons`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "",
        };
        axios(lessonProgress).then(async (result) => {
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            setIsNotComplete(true);
            //alert("lesson sent")

            // setLessonComplete({
            //   courseId: courseId,
            //   lessonId: node.id,
            //   lastVisited: timesNow.toString(),
            // });
            // alert("lesson sent")

            let sendMailLessonCompleted = {
              method: "post",
              // params: { courseId: courseId, lessonId: node.id },
              url: `${IP}/mail/sendMailLessonCompleted`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                lessonName: node.name,
                lessonId: node.id,
              }),
            };
            await axios(sendMailLessonCompleted);
            // .then((res) => {})
            // .catch((e) => {
            //   setLoading(false);
            //   setError(e.error ? e.error : "something wrong happened");
            // });

            let lessonProgress = {
              method: "patch",
              // params: { courseId: courseId, lessonId: node.id },
              url: `${IP}/userProgress/lessons`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                lessonId: node.id,
                lastVisited: timesNow.toString(),
              }),
            };
            axios(lessonProgress);
            // .then((res) => {})
            // .catch((e) => {
            //   setLoading(false);
            //   setError(e.error ? e.error : "something wrong happened");
            // });
            // console.log(node);
            // lessonCompletedUserNotification({
            //   id: node.id,
            //   uid: getCookie("UID"),
            // }).then((res) => {
            //   //alert("lesson mail sent")
            // });
          }
        });
        // .then((res) => {})
        // .catch((e) => {
        //   setLoading(false);
        //   setError(e.error ? e.error : "something wrong happened");
        // });
      } else if (node.nodeType == "course") {
        //console.log("calling getNodeProgress");
        // getNodeProgress({
        //   courseId: courseId,
        // });
        let courseProgress = {
          method: "get",
          params: { courseId: courseId },
          url: `${IP}/userProgress`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "",
        };
        axios(courseProgress).then(async (result) => {
          //console.log(result.data.completed, result.data.total);
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            //console.log(result.data.completed, result.data.total);
            setIsNotComplete(true);
            console.log("i amm called again in line 352");
            let courseProgress = {
              method: "patch",
              // params: { courseId: courseId },
              url: `${IP}/userProgress`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                completionDate: timesNow.toString(),
                courseId: courseId,
                passingScore: score?.passingScore ? score?.passingScore : 80,
                userScore: score?.userScore ? score?.userScore : 90,
              }),
            };
            axios(courseProgress);
            // setCourseComplete({

            // });

            dispatch(
              userProgress({
                total: result.data.total,

                completed: result.data.total,
              })
            );

            // certificate/certificate
            // setCirtificateLoading(true);
            let certificate = {
              method: "post",
              // params: { courseId: courseId },
              url: `${IP}/certificate/certificate`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                completionDate: timesNow.toString(),
                passingScore: score?.passingScore ? score?.passingScore : 80,
                userScore: score?.userScore ? score?.userScore : 90,
              }),
            };
            if (!certificateInfo) {
              setCirtificateLoading(true);

              axios(certificate)
                .then((res) => {
                  setCirtificateLoading(false);
                })
                .catch((err) => {
                  setCirtificateLoading(false);
                  setLoading(false);
                  // setError(
                  //   err.error ? err.error : "something wrong happened"
                  // );
                  toast.warning("something went wrong");
                });
            }
            // generateCertificate({

            // });
          }
        });
        // .then((res) => {})
        // .catch((e) => {
        //   setLoading(false);
        //   setError(e.error ? e.error : "something wrong happened");
        // });
      }
      return;
    }
    if (node.nodeType == "topic") {
      // setTopicComplete({
      //   courseId: courseId,
      //   topicId: node.id,
      //   lastVisited: timesNow.toString(),
      // });
      let sendMailTopicCompleted = {
        method: "post",
        // params: { courseId: courseId, lessonId: node.id },
        url: `${IP}/mail/sendMailTopicCompleted`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          topicName: node.name,
        }),
      };
      axios(sendMailTopicCompleted);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });
      let topicProgress = {
        method: "patch",
        // params: { courseId: courseId, topicId: node.id },
        url: `${IP}/userProgress/topic`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          topicId: node.id,
          lastVisited: timesNow.toString(),
        }),
      };
      axios(topicProgress);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });
    } else if (node.nodeType == "lesson") {
      // setLessonComplete({
      //   courseId: courseId,
      //   lessonId: node.id,
      //   lastVisited: timesNow.toString(),
      // });

      // console.log(node);

      let sendMailLessonCompleted = {
        method: "post",
        // params: { courseId: courseId, lessonId: node.id },
        url: `${IP}/mail/sendMailLessonCompleted`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          lessonName: node.name,
          lessonId: node.id,
        }),
      };
      await axios(sendMailLessonCompleted);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });

      let lessonProgress = {
        method: "patch",
        // params: { courseId: courseId, topicId: node.id },
        url: `${IP}/userProgress/lessons`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          lessonId: node.id,
          lastVisited: timesNow.toString(),
        }),
      };
      axios(lessonProgress);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });
      // lessonCompletedUserNotification({
      //   id: node.id,
      //   uid: getCookie("UID"),
      // }).then((res) => {
      //   // alert("lesson mail sent")
      // });
    } else if (node.nodeType == "course") {
      // setCourseComplete({
      //   completionDate: timesNow.toString(),
      //   courseId: courseId,
      // });
      let courseProgress = {
        method: "patch",
        // params: { courseId: courseId },
        url: `${IP}/userProgress`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          completionDate: timesNow.toString(),
          courseId: courseId,
          passingScore: score?.passingScore ? score?.passingScore : 80,
          userScore: score?.userScore ? score?.userScore : 90,
        }),
      };
      axios(courseProgress);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });
      // dispatch(
      //   userProgress({
      //     total: 5,
      //     completed: 5,
      //   })
      // );
      // let certificate = {
      //   method: "post",
      //   // params: { courseId: courseId },
      //   url: `${IP}/certificate/certificate`,

      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${getCookie("bearer")}`,
      //   },
      //   data: JSON.stringify({
      //     courseId: courseId,
      //     completionDate: timesNow.toString(),
      //     passingScore: score?.passingScore ? score?.passingScore : 80,
      //     userScore: score?.userScore ? score?.userScore : 90,
      //   }),
      // };
      // if (!certificateInfo) {
      //   setCirtificateLoading(true);

      //   axios(certificate)
      //     .then((res) => {
      //       setCirtificateLoading(false);
      //     })
      //     .catch((err) => {
      //       setCirtificateLoading(false);
      //       toast.warning("something went wrong");
      //     });
      // }

      // generateCertificate({
      //   courseId: courseId,
      //   completionDate: timesNow.toString(),
      //   passingScore: score?.passingScore ? score?.passingScore : 80,
      //   userScore: score?.userScore ? score?.userScore : 90,
      // });
    }
  }, [progressIndex]);

  return (
    <>
      <LoadingScreen open={cirtificateLoading} />
      {isLinear || isNodeComplete ? (
        <div className="node-container">
          <EndQuizPass />
          <p>
            Congratulations! You have completed a {node.nodeType.toUpperCase()}
          </p>
          <h3>{node.name}</h3>
          {loading && (
            <Box
              container
              spacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ mt: 10, alignItems: "center" }}
            >
              <div className="admin-overdue-bottom-table-cnt-c ">
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td"></div>
                <div className="admin-overdue-bottom-table-td">
                  <div className="spinner-container">
                    <div
                      style={{
                        width: "70px",
                        height: "70px",
                        border: "10px solid #f3f3f3" /* Light grey */,
                        borderTop: "10px solid #007C84" /* Black */,
                        borderRadius: "50%",
                        animation: "spinner 1.5s linear infinite",
                        marginBottom: "200px",
                        marginTop: "120px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Box>
          )}
          <p style={{ color: "red" }}>{error}</p>
          {certificateInfo ||
            (certificateLink && (
              <p
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  marginTop: "50px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  certificateInfo
                    ? window.open(certificateInfo, "_blank")
                    : window.open(certificateLink, "_blank");
                }}
              >
                {" "}
                Download certificate
              </p>
            ))}
        </div>
      ) : progress ? (
        <div className="node-container">
          {/* <EndQuizPass /> */}
          <p>
            You have completed {progress.completed} out of {progress.total} in{" "}
            {node.nodeType.toUpperCase()}
          </p>
          <h3>{node.name}</h3>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Node;
