import { getAuth, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
//import 'rsuite/dist/styles/rsuite-default.css';

/* import { DateRangePicker } from 'rsuite'; */
//import 'rsuite/dist/styles/rsuite-default.css';
/* import Navbar from "../Component/Navbar"; */
import "./StyleAdminComponents/GroupList.css";
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
import { functions } from "../../Firebase";
import NavbarTop from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Dashboard.css";
import "./StyleAdminComponents/Dashboard.css";
import { IP } from "../../config";
import axios from "axios";

const Dashboard = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const newEmployeeInWeek = httpsCallable(functions, "newEmployeeInWeek");
  const allExpireCertificate = httpsCallable(functions, "allExpireCertificate");
  const overdueData = httpsCallable(functions, "overdueData");
  const userPassedThisMonth = httpsCallable(functions, "userPassedThisMonth");
  const userFailedThisMonth = httpsCallable(functions, "userFailedThisMonth");

  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchCompany = httpsCallable(functions, "fetchCompany");

  const [userName, setUserName] = useState("");

  const [traningProvider, setTraningProvider] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [overDueInfo, setOverDueInfo] = useState(null);
  const [userPassedInfo, setUserPassedInfo] = useState(null);
  const [userFailedInfo, setUserFailedInfo] = useState(null);

  const [department, setDepartment] = useState([]);
  const [company, setCompany] = useState([]);

  const [extraArrEmploy, setExtraArrExploy] = useState([]);
  const [extraArrExpireCertificate, setExtraArrExpireCertificate] = useState(
    []
  );

  const [extraArrDueDate, setExtraArrDueDate] = useState([]);
  const [extraArrPassedThisMonth, setExtraArrPassedThisMonth] = useState([]);
  const [extraArrFailedThisMonth, setExtraArrFailedThisMonth] = useState([]);

  const [typeOfTraining, setTypeOfTraining] = useState("demo");

  const [companyNameex, setCompanyNameEx] = useState("");

  const [departmentEx, setDepartmentEx] = useState("");

  let initFilterState = {
    companyId: "",
    departmentId: "",
    courseType: "",
  };
  const [filterState, setFilterState] = useState(initFilterState);

  useEffect(() => {
    let configdepartment = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/common/department
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configdepartment).then((res) => {
      console.log(res.data);
      setDepartment(res.data);
    });

    let configcompany = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/common/company
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configcompany).then((res) => {
      // console.log(res.data);
      setCompany(res.data);
    });

    let configOver = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/dashboard/newEmployeeInWeek
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configOver).then((result) => {
      // //console.log("Employee ", result);
      setEmployeeInfo(
        result.data?.data.filter((ele) => ele.info?.archived !== true)
      );
      setExtraArrExploy(
        result.data?.data.filter((ele) => ele.info?.archived !== true)
      );
    });

    let configExpireCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/dashboard/geAllExpireCertificate
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configExpireCertificate).then((result) => {
      // //console.log("Certificate ", result.data);
      if (result.data === null) {
        setCertificateInfo([]);
      } else {
        setCertificateInfo(result.data?.data);
        setExtraArrExpireCertificate(result.data?.data);
      }
    });

    let configoverdueData = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/dashboard/overdueData
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configoverdueData).then((result) => {
      // //console.log("Overdue ", result.data);
      setOverDueInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrDueDate(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });

    let configPassedThisMonth = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/dashboard/usersPassedThisMonth
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configPassedThisMonth).then((result) => {
      ////console.log("Passed user ", result.data);
      setUserPassedInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrPassedThisMonth(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });

    let configFailedThisMonth = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/dashboard/usersFailedThisMonth
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configFailedThisMonth).then((result) => {
      // //console.log("failed user ", result.data);
      setUserFailedInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrFailedThisMonth(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });
  }, []);

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const filterDataFromApi = (companyId, departmentId, courseType) => {
    let params = {};
    if (companyId) {
      params["companyId"] = companyId;
    }
    if (departmentId) {
      params["departmentId"] = departmentId;
    }
    if (courseType) {
      params["courseType"] = courseType;
    }

    let configOver = {
      method: "get",
      params: params,

      url: `${IP}/dashboard/newEmployeeInWeek`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configOver).then((result) => {
      // //console.log("Employee ", result);
      setEmployeeInfo(
        result.data?.data.filter((ele) => ele.info?.archived !== true)
      );
      setExtraArrExploy(
        result.data?.data.filter((ele) => ele.info?.archived !== true)
      );
    });

    let configExpireCertificate = {
      method: "get",
      params: params,

      url: `${IP}/dashboard/geAllExpireCertificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configExpireCertificate).then((result) => {
      // //console.log("Certificate ", result.data);
      if (result.data === null) {
        setCertificateInfo([]);
      } else {
        setCertificateInfo(result.data?.data);
        setExtraArrExpireCertificate(result.data?.data);
      }
    });

    let configoverdueData = {
      method: "get",
      params: params,

      url: `${IP}/dashboard/overdueData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configoverdueData).then((result) => {
      // //console.log("Overdue ", result.data);
      setOverDueInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrDueDate(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });

    let configPassedThisMonth = {
      method: "get",
      params: params,

      url: `${IP}/dashboard/usersPassedThisMonth`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configPassedThisMonth).then((result) => {
      ////console.log("Passed user ", result.data);
      setUserPassedInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrPassedThisMonth(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });

    let configFailedThisMonth = {
      method: "get",
      params: params,

      url: `${IP}/dashboard/usersFailedThisMonth`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configFailedThisMonth).then((result) => {
      // //console.log("failed user ", result.data);
      setUserFailedInfo(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setExtraArrFailedThisMonth(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
    });
  };

  return (
    <section className="dashboard">
      <NavbarTop page={t("Dashboard")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div
            className="admin-dashboard-main-mid"
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("CompanyName")}
              </div>
              <div>
                <select
                  name="company"
                  id="company"
                  onChange={(e) => {
                    filterDataFromApi(
                      e.target.value,
                      filterState.departmentId,
                      filterState.courseType
                    );

                    setFilterState((prev) => ({
                      ...prev,
                      companyId: e.target.value,
                    }));
                  }}
                  value={filterState.companyId}
                >
                  <option value="">Select Company </option>
                  {company?.map((e) => (
                    <option value={e.companyId}> {e.companyName}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Department")}
              </div>
              <div>
                <select
                  name="SelectGroup"
                  id="SelectGroup"
                  onChange={(e) => {
                    filterDataFromApi(
                      filterState.companyId,
                      e.target.value,

                      filterState.courseType
                    );

                    setFilterState((prev) => ({
                      ...prev,
                      departmentId: e.target.value,
                    }));
                  }}
                  value={filterState.departmentId}
                >
                  <option value="">Select Department</option>
                  {department?.map((e) => (
                    <option value={e.departmentId}> {e.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("TypeOfTraining")}
              </div>
              <div>
                <select
                  name="SelectGroup"
                  id="SelectGroup"
                  onChange={(e) => {
                    // fulterdata(e.target.value, "trainingType");

                    filterDataFromApi(
                      filterState.companyId,
                      filterState.departmentId,
                      e.target.value
                    );

                    setFilterState((prev) => ({
                      ...prev,
                      courseType: e.target.value,
                    }));
                  }}
                  value={filterState.courseType}
                >
                  <option value="demo">Select Type Of Training</option>
                  <option value="course">Internal Training LMS</option>

                  <option value="externalTraining">External Training </option>
                  <option value="internalOffline">
                    Internal Offline Training{" "}
                  </option>

                  <option value="Previous">Previous</option>
                </select>
              </div>
            </div>
            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("TrainingProvider")}</div>
              <div>
              
                <input type="text" />
              
              </div>
            </div> */}

            {/* <div
              className="admin-dashboard-main-mid-item"
              style={{ display: "grid", gridTemplateColumns: "2fr 1fr" }}
            >
              <div>
                <div className="admin-dashboard-main-mid-item-txt">
                  {t("TrainingProvider")}
                </div>
                <div>
                  <input
                    type="text"
                    style={{ width: "80%" }}
                    value={traningProvider}
                    onChange={(e) => {
                      setTraningProvider(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <div
                  className="admin-dashboard-main-top-last"
                  style={{ paddingTop: "2.7vw" }}
                >
                  <button
                    onClick={(e) => {
                      fulterdata(traningProvider, "trainingProvider");
                    }}
                    style={{
                      background: `${
                        clrs?.CourseListBtn
                          ? clrs?.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.8vh",
                    }}
                  >
                    <div>
                      <svg
                        width="auto"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div>

            <div
              className="admin-dashboard-main-mid-item"
              style={{ display: "grid", gridTemplateColumns: "2fr 1fr" }}
            >
              <div>
                <div className="admin-dashboard-main-mid-item-txt">
                  {t("EmployeeName")}
                </div>
                <div>
                  <input
                    type="text"
                    style={{ width: "80%" }}
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <div
                  className="admin-dashboard-main-top-last"
                  style={{ paddingTop: "2.7vw" }}
                >
                  <button
                    onClick={(e) => {
                      fulterdata(userName, "userName");
                    }}
                    style={{
                      background: `${
                        clrs?.CourseListBtn
                          ? clrs?.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.8vh",
                    }}
                  >
                    <div>
                      <svg
                        width="auto"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div> */}
            <div
              className="admin-dashboard-main-mid-item-last"
              style={{ display: "flex", gap: "10px" }}
            >
              <div
                className="admin-dashboard-main-top-last"
                style={{ paddingTop: "1.5vw" }}
              >
                <button
                  onClick={() => {
                    filterDataFromApi("", "", "");

                    setFilterState(initFilterState);
                  }}
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "9vw",
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("ClearFilter")}</div>
                </button>
              </div>

              {/* <div className="admin-dashboard-main-top-last" style={{ paddingTop: "1.5vw" }}>
                <button
                  style={{
                    background: `${clrs?.CourseListBtn ? clrs?.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("Filter")}</div>
                </button>
              </div> */}
            </div>
          </div>
          <div style={{ height: "3vw" }} className="admin-overdue-top">
            <div className="admin-overdue-top-item">{t("Statistics")}</div>
          </div>
          {/*  <DateRangePicker/> */}
          <div className="admin-dashboard-main-bottom">
            <div
              style={{ width: "24vw" }}
              className="admin-dashboard-main-bottom-item"
            >
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("OverdueEnrollments")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfOverdueEnrollments")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/overdueenrollments"} state={overDueInfo}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {overDueInfo ? (
                    overDueInfo.length
                  ) : (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      style={{ width: "100%", textAlign: "center" }}
                      key={0}
                    >
                      <div
                        className="spinner-container"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <div
                          className="loading-spinner"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: " 5px solid #f3f3f3",
                            borderTop: "5px solid #06FF00",
                            margin: "auto auto auto 8px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{ width: "24vw" }}
              className="admin-dashboard-main-bottom-item"
            >
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("UserPassedInThisMonth")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfUserPassed")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/userpassed"} state={userPassedInfo}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {userPassedInfo ? (
                    userPassedInfo.length
                  ) : (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      style={{ width: "100%", textAlign: "center" }}
                      key={0}
                    >
                      <div
                        className="spinner-container"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <div
                          className="loading-spinner"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: " 5px solid #f3f3f3",
                            borderTop: "5px solid #06FF00",
                            margin: "auto auto auto 8px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ width: "24vw" }}
              className="admin-dashboard-main-bottom-item"
            >
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("UserFailedInThisMonth")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfUserFailed")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/userfailed"} state={userFailedInfo}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {userFailedInfo ? (
                    userFailedInfo.length
                  ) : (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      style={{ width: "100%", textAlign: "center" }}
                      key={0}
                    >
                      <div
                        className="spinner-container"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <div
                          className="loading-spinner"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: " 5px solid #f3f3f3",
                            borderTop: "5px solid #06FF00",
                            margin: "auto auto auto 8px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ height: "8.5vw", width: "24vw" }}
              className="admin-dashboard-main-bottom-item"
            >
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("NewEmployees")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfNewEmployes")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/newemployees"} state={employeeInfo}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {employeeInfo ? (
                    employeeInfo.length
                  ) : (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      style={{ width: "100%", textAlign: "center" }}
                      key={0}
                    >
                      <div
                        className="spinner-container"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <div
                          className="loading-spinner"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: " 5px solid #f3f3f3",
                            borderTop: "5px solid #06FF00",
                            margin: "auto auto auto 8px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ width: "24vw" }}
              className="admin-dashboard-main-bottom-item"
            >
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("ExpiredTrainingCertificates")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfTrainingCertificates")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/expiringtraining"} state={certificateInfo}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {certificateInfo ? (
                    certificateInfo.length
                  ) : (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      style={{ width: "100%", textAlign: "center" }}
                      key={0}
                    >
                      <div
                        className="spinner-container"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <div
                          className="loading-spinner"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: " 5px solid #f3f3f3",
                            borderTop: "5px solid #06FF00",
                            margin: "auto auto auto 8px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
