import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const CertificateCard = ({ certificate }) => {
  return (
    <Grid item md={6} xs={12}>
      <Box
        sx={{
          width: "100%",
          height: "100px",
          background: "#fff",
          position: "relative",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "100%",
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                textTransform: "uppercase",
                color: (theme) => theme.palette.primary.btn,
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              PTW
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "70%",
            height: "100%",
            background: (theme) => theme.palette.secondary.main,
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            clipPath: "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              {certificate?.certificateName}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "8px",
                fontWeight: 400,
              }}
            >
              <Button
                style={{
                  color: "#fff",
                  fontSize: "8px",
                  fontWeight: 400,
                  cursor: "pointer",
                  textTransform: "none",
                }}
                href={certificate.certificateDownloadURL}
                target="_blank"
              >
                View Certificate
              </Button>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CertificateCard;
