import { Box, Button, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { deleteCookie, getCookie, setCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import { flexBetweenWithItemsCenter } from "../../../utilities/commonStyles/commonStyles";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import {
  CertificatesIcons,
  CourseLibraryIcons,
  HomeIcons,
  LeftArrowIcons,
  MenuIcons,
  ProfileIcons,
  TrainingIcons,
} from "../../../utilities/Icons/Icons";
import logo from "../img/logo/logo.png";
import classes from "./Sidebar.module.css";
import { IP } from "../../../config";
import axios from "axios";

function SideNavbar({ page }) {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  const { hidepage, setHidepage } = useContext(DrawerContext);
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [extraStateForRoll, setExtraStateForRoll] = useState("");
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");

  // let window=Window.close()
  // if(window){
  //   deleteCookie()
  //   window.location.href = "/";

  // }

  if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content"
  ) {
    setHidepage(false);
  } else if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content/course-content"
  ) {
    setHidepage(false);
  } else if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content/course-materials"
  ) {
    setHidepage(false);
  } else {
    setHidepage(true);
  }
  setHidepage(false);
  useEffect(() => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };

    axios(configProfile).then((res) => {
      //console.log(res.data.role)
      let role = res.data?.role?.toLowerCase();
      if (role == "admin" || role == "instructor") {
        setExtraStateForRoll(role);
        setIsAdmin(true);
      }
    });
  }, [page]);

  // useEffect(() => {
  //   //console.log(clrs);
  // }, [clrs]);
  const [svgClr, setSvgClr] = useState([
    // { iconColor: "#188775", cntColor: "#E6FCF5", txtClr: "#188775" },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    {
      iconColor: page === "home" ? clrs.SidebariconClr : null,
      cntColor: page === "home" ? clrs.SidebarcntClr : null,
      txtClr: page === "home" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "courselibrary" ? clrs.SidebariconClr : null,
      cntColor: page === "courselibrary" ? clrs.SidebarcntClr : null,
      txtClr: page === "courselibrary" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "training" ? clrs.SidebariconClr : null,
      cntColor: page === "training" ? clrs.SidebarcntClr : null,
      txtClr: page === "training" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "profile" ? clrs.SidebariconClr : null,
      cntColor: page === "profile" ? clrs.SidebarcntClr : null,
      txtClr: page === "profile" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "yourassignment" ? clrs.SidebariconClr : null,
      cntColor: page === "yourassignment" ? clrs.SidebarcntClr : null,
      txtClr: page === "yourassignment" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "certificate" ? clrs.SidebariconClr : null,
      cntColor: page === "certificate" ? clrs.SidebarcntClr : null,
      txtClr: page === "certificate" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "userreports" ? clrs.SidebariconClr : null,
      cntColor: page === "userreports" ? clrs.SidebarcntClr : null,
      txtClr: page === "userreports" ? clrs.SidebartxtClr : null,
    },
  ]);

  const handleclick = (id) => {
    const narr = svgClr.map((item, i) => {
      if (i === id - 1) {
        return {
          iconColor: clrs.SidebariconClr,
          cntColor: clrs.SidebarcntClr,
          txtClr: clrs.SidebartxtClr,
        };
      } else {
        return {
          iconColor: null,
          cntColor: null,
          txtClr: null,
        };
      }
    });
    //console.log(narr);
    setSvgClr(narr);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <Box
      sx={{
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <motion.div
        animate={{
          width: isOpen ? "270px" : "105px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 20,
          },
        }}
      >
        <Box className={classes.sidebar}>
          <Box
            sx={{
              height: "100vh",
              padding: "0px 16px",
              boxShadow:
                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
            }}
          >
            <AnimatePresence>
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={logo}
                    style={{
                      width: isOpen ? "200px" : "70px",
                      margin: "10px 0px",
                    }}
                    alt=""
                  />
                </Box>
              </motion.div>
            </AnimatePresence>

            <Box sx={{ position: "relative" }}>
              <AnimatePresence>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 300,
                    padding: isOpen ? "0px 15px" : "0px 5px",
                    marginTop: "10px",
                    fontSize: isOpen ? "16px" : "10px",
                  }}
                >
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  >
                    {t("DASHBOARD")}
                  </motion.div>
                </Typography>
              </AnimatePresence>
              <Box
                onClick={toggle}
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <MenuIcons />
              </Box>
            </Box>
            <Box>
              <NavLink
                to="home"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <HomeIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Home")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="course-library"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CourseLibraryIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("CourseLibrary")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="training"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <TrainingIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Training")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="profile"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <ProfileIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Profile")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>
              <NavLink
                to="certificates"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CertificatesIcons />
                  </Box>

                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        <Box
                          sx={{
                            ...flexBetweenWithItemsCenter,
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Certificates")}
                          </Typography>
                          <LeftArrowIcons />
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </NavLink>

              <Box>{/* <logOut icon /> */}</Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                  color: "#171a1a",
                  fill: "#003a69",
                  background: "#d9f0fa",
                  padding: "15px 20px",
                  borderRadius: "5px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  deleteCookie("UID");
                  deleteCookie("keepMeLogin");
                  window.location.href = "/";
                }}
                title="Logout"
              >
                <Box>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 27C6.04395 27 0 20.9561 0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27ZM20.25 18.9L27 13.5L20.25 8.1V12.15H9.45V14.85H20.25V18.9Z"
                      fill="#003A69"
                    />
                  </svg>
                </Box>

                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      <Box
                        sx={{
                          ...flexBetweenWithItemsCenter,
                          width: "100%",
                          gap: "12px",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Logout")}
                        </Typography>
                        <LeftArrowIcons />
                      </Box>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "3%",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                mt: "50px",
              }}
            >
              {/* <NavLink to="settings">
                <Box
                  sx={{
                    background: "#F5FAFA",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcons />
                </Box>
              </NavLink> */}
              {isAdmin && (
                <Box>
                  <Button
                    sx={{
                      background: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      padding: isOpen ? "8px 50px" : "8px 15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      fontSize: "12px",
                      fontWeight: 600,
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      setCookie("ROLE", extraStateForRoll);
                      navigate("/admin");
                    }}
                  >
                    {t("Admin")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
}

export default SideNavbar;
