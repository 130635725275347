import { Box, Container, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { auth, functions } from "../../Firebase";
import "./NavbarTop.css";

// import { useTranslation } from "react-i18next";/styles/Navbar/Navbar.css
import "../Translater/Translater";
import notify from "./img/notify.svg";
import pp from "./img/profilePicture.svg";
import settings from "./img/settings.svg";
import NotificationPopup from "./NavbarTopPOPUp";
import NotificationIcon from "./NotificationIcon";
import { useSelector } from "react-redux";
import logo from "./img/logo.png";
import {
  deleteCookie,
  getCookie,
  getCookieForRfToken,
  setCookie,
} from "../../Cookies";
import { IP } from "../../config";
import axios from "axios";
function Navbar({ page }) {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();
  const fetchNotification = httpsCallable(functions, "fetchNotification");
  const [showPopUp, setShowPopUp] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [notificationCount, setNotificationsCount] = useState("");
  //================user details====================//
  const { clrs } = useSelector((state) => state.createClr);
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const [imgshow, setImgshow] = useState(false);
  const [name, setName] = useState("");
  const [empId, setempId] = useState("");
  // Function to create an array without duplicates based on the "title" property
  const removeDuplicates = (arr, prop) => {
    const uniqueArray = [];
    const seenValues = new Set();

    for (const obj of arr) {
      const value = obj[prop];

      if (!seenValues.has(value)) {
        seenValues.add(value);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  };
  useEffect(() => {
    let rf = getCookieForRfToken("rfToken");
    // console.log(rf);
    if (!rf) {
      let rrf = localStorage.getItem("rfToken");
      let tf = typeof rrf;
      if (tf != String && tf != "") {
        // console.log("rf", rf);
        // console.log("rrf", rrf);

        setCookie("rfToken", rrf);
      }
    }
    setName(getCookie("NAME")); //setting name of user
    setempId(getCookie("EMPID"));
    // //console.log(auth._delegate.lastNotifiedUid)

    // userProfile/userNotification
    let configCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile/userNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configCertificate).then((res) => {
      if (res.error) {
        return;
      }
      // //console.log(res.data)
      const tempArrInitial = res.data;
      const withoutDuplicateArray = removeDuplicates(tempArrInitial, "title");

      setNotifications(
        withoutDuplicateArray.sort(
          (a, b) => b.timestamp._seconds - a.timestamp._seconds
        )
        // res.data.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)
      );
      console.log("withoutDuplicateArray", withoutDuplicateArray);
      const temparr = withoutDuplicateArray.filter((ele) => ele.read === false);
      // const temparr = res.data.filter((ele) => ele.read === false);

      setNotificationsCount(temparr.length > 0 ? temparr.length : "");
    });
  }, []);

  useEffect(() => {
    if (
      notifications &&
      notifications[0]?.title == "You are archived from the portal"
    ) {
      deleteCookie();

      window.open(`${window.location.origin}`, "_self");
    }
  }, [notifications]);
  return (
    <Box
      style={{ zIndex: 99 }}
      sx={{
        height: "80px",
        py: 1,
        background: "#fff",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <img
              className="nav-cnt-left-img"
              style={{ width: "12vw" }}
              src={logo}
              alt="loading"
            />
          </Box>
          <div className="nav-cnt-center">
            <div
              style={{
                color: `${clrs?.NavbarTxtClr ? clrs?.NavbarTxtClr : "black"}`,
              }}
            >
              {page}
            </div>
          </div>

          <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box sx={{ width: "40px", height: "40px" }}>
                {/* <img src={pp} alt="loading" /> */}
                <img
                  src={imgshow ? imgshow : pp}
                  alt="loading"
                  style={{ width: "100%", height: "100%", borderRadius: "20%" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: "22px", fontWeight: "600", color: "#00495F" }}
                >
                  {/* {getCookie("NAME")} */}
                  {name}
                </Typography>
                <Typography>
                  {/* {getCookie("EMPID")} */}
                  {empId}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ cursor: "pointer" }}>
              <Box onClick={() => setShowPopUp((prev) => !prev)}>
                <NotificationIcon count={notificationCount} />
              </Box>
              {showPopUp && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "0px",
                    top: "100px",
                    right: "0px",
                    bottom: "0px",
                    zIndex: 9999,
                    width: "100%",
                  }}
                >
                  <NotificationPopup
                    onClose={() => setShowPopUp((prev) => !prev)}
                    notifications={notifications?.map((ele) => {
                      return ele;
                    })}
                  />
                </Box>
              )}
            </Box>
            <Box className="nav-cnt-right-settings">
              <Link to="/admin/settings">
                <img src={settings} alt="loading" />{" "}
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Navbar;
