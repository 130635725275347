import { getDocs, Timestamp } from "firebase/firestore";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "./Certificates.css";
import "../StyleAdminComponents/GroupList.css";
import { httpsCallable } from "firebase/functions";
import { IP } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

function Certificates() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const getCertificateList = httpsCallable(functions, "getCertificateList");
  const [certificates, setCertificates] = useState([]);
  const [details, setDetails] = useState([]);
  const [showLoading, setLoading] = useState(true);
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentDataUrl, setCurrentDataUrl] = useState({});
  const { clrs } = useSelector((state) => state.createClr);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const initState = {
    employeeId: "",
    employeeName: "",
    trainingProvider: "",
    training: "",
    certificateName: "",
    status: "default",
    companyId: "",
  };
  const [query, setQuery] = useState(initState);
  const [pageSize, setPageSize] = useState(50);
  const [companyData, setCompanyData] = useState([]);
  const [companyObj, setCompanyObj] = useState({});

  // //console.log("certificates present are", certificates);
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  // //console.log(query);
  const setDefault = () => {
    getReportData();
    setQuery(initState);
    setDetails(certificates);
    setArrLength(certificates.length);
    return;
  };

  const getReportData = (employeeId, employeeName, companyId, trainingType) => {
    setLoading(true);

    let query = {};

    if (employeeId) {
      query["employeeId"] = employeeId;
    }
    if (employeeName) {
      query["employeeName"] = employeeName;
    }
    if (companyId) {
      query["companyId"] = companyId;
    }
    if (trainingType) {
      query["trainingType"] = trainingType;
    }
    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/certificate/certificate?limit=${50}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson)
      .then((res) => {
        let data = res.data.data;
        setCurrentUrl(`${IP}/certificate/certificate`);
        setCurrentDataUrl(res.data);
        setLoading(false);
        setCertificates(data);
        setArrLength(res.data.t_count);

        setDetails(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(certificates);
      setArrLength(certificates.length);
      return;
    }
    console.log(value, name);
    let temp = [];
    console.log(certificates);
    if (name == "company") {
      let arr = [];

      for (let i = 0; i < certificates.length; i++) {
        if (certificates[i].company !== null) {
          if (
            certificates[i].company.toLowerCase().includes(value.toLowerCase())
          ) {
            temp.push(certificates[i]);
          }
        }
      }
      console.log(arr);
    } else {
      temp = certificates.filter((item) =>
        item[name]?.toLowerCase().includes(value.toLowerCase())
      );
    }
    console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    //console.log(temp);
    setCurrentPage(0);
  };
  const handleOnChange = (data) => {
    const info = data.split(",");

    if (info[0] === "download") {
      window.open(info[1], "_blank");
    } else if (info[0] === "renew") {
      navigate(`/admin/certificateRenewal/${info[1]}`);
    } else if (info[0] === "notify") {
      navigate(`/admin/notifications/${[info[1], info[2]]}`);
    }
  };

  useEffect(() => {
    getReportData();

    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setCompanyData(res.data);
      res.data.map((el) => {
        setCompanyObj((prev) => ({ ...prev, [el.companyId]: el.companyName }));
      });
    });
  }, []);
  const handleStatusChange = (e) => {
    console.log(e);
    setQuery({
      ...query,
      status: e.target.value,
    });
    //console.log(e.target.name);
    //console.log(e.target.value);
    if (e.target.value == "default") {
      setDetails(certificates);
      setArrLength(certificates.length);
      return;
    }
    const temp = certificates.filter((item) => item.status == e.target.value);
    //console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    //console.log(temp);
    setCurrentPage(0);
  };
  useEffect(() => {
    certificates?.map((item) => {
      const now = new Date().getTime();
      let expiry = item.expiry?._seconds * 1000;
      //console.log(now,expiry)
      if (expiry < now) {
        item.status = "Expired";
        // //console.log(`${item.employeeName} Expired`);
      } else {
        // //console.log(`${item.employeeName} Acitve`);
        item.status = "Active";
      }
    });
    setDetails(certificates);
  }, [certificates]);

  const handleAction = (e, item) => {
    //console.log("id", id);
    //console.log(e);
    //console.log(details);
    if (e.target.value == "Download") {
      //console.log(details[id].downloadUrl);
      window.open(`${item.certificateDownload}`, "_blank");
    }
  };
  const changePageLimit = (pageLimit) => {
    setLoading(true);

    let URL = currentUrl;

    if (currentUrl.split("?")[1]) {
      URL = `${URL}&limit=${pageLimit}`;
    } else {
      URL = `${URL}?limit=${pageLimit}`;
    }
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);
        setDetails(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  const clickhandler = (url) => {
    setLoading(true);
    setCurrentUrl(`${url}`);

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${url}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setCurrentUrl(`${url}`);
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);
        setDetails(data);
      })
      .catch((err) => {
        setLoading(false);
        toast.warning("something went wrong");
      });
  };

  return (
    <div className="admin-certificate">
      <Navbar page={t("Certificates")} />
      <div className="admin-certificate-main">
        <Sidebar page={"certificates"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{ display: "grid", height: "45vh" }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("EmployeeID")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{
                    width: "12vw",
                    borderRadius: "5px 0px 0px 5px",
                    marginRight: "0vw",
                    height: "5vh",
                    color: " rgb(107, 105, 105)",
                  }}
                  type="text"
                  name="employeeId"
                  value={query.employeeId}
                  onChange={handleChange}
                  placeholder="999999999999"
                />
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    borderRadius: "0px 5px 5px 0px",
                  }}
                  onClick={() => {
                    getReportData(
                      query.employeeId,
                      query.employeeName,
                      query.companyId,
                      query.trainingType
                    );
                  }}
                  className="search"
                >
                  <div className="searchDiv">{t("Search")}</div>
                </button>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("EmployeeName")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{
                    width: "12vw",
                    borderRadius: "5px 0px 0px 5px",
                    marginRight: "0vw",
                    height: "5vh",
                    color: "rgb(107, 105, 105)",
                  }}
                  type="text"
                  name="employeeName"
                  value={query.employeeName}
                  onChange={handleChange}
                  placeholder="Enter Employee Name"
                />
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    borderRadius: "0px 5px 5px 0px",
                  }}
                  // onClick={() => getReportData("employeeID")}

                  onClick={() => {
                    getReportData(
                      query.employeeId,
                      query.employeeName,
                      query.companyId,
                      query.trainingType
                    );
                  }}
                  className="search"
                >
                  <div className="searchDiv">{t("Search")}</div>
                </button>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Company Name")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "16vw", marginRight: "0vw", height: "5vh" }}
                  name="companyId"
                  // defaultValue={"Expired"}
                  value={query.companyId}
                  onChange={(e) => {
                    handleChange(e);
                    getReportData(
                      query.employeeId,
                      query.employeeName,
                      e.target.value,
                      query.trainingType
                    );
                  }}
                  id="status"
                >
                  <option
                    value="default"
                    selected={query.status == "default" ? true : false}
                  >
                    Select company
                  </option>
                  {companyData?.map((el) => (
                    <option value={el.companyId}>{el.companyName}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Type Of Traning")}
              </div>
              <div>
                <select
                  style={{ width: "16vw", marginRight: "0vw", height: "5vh" }}
                  name="training"
                  // defaultValue={"Expired"}
                  value={query.training}
                  onChange={(e) => {
                    handleChange(e);
                    getReportData(
                      query.employeeId,
                      query.employeeName,

                      query.companyId,
                      e.target.value
                    );
                  }}
                  id="status"
                >
                  <option
                    value="default"
                    selected={query.status == "default" ? true : false}
                  >
                    Select training type
                  </option>

                  <option value={"Internal"}>{"Internal LMS"}</option>
                  <option value={"External"}>{"External Training"}</option>
                  <option value={"Internal Offline"}>
                    {"Internal Offline Training"}
                  </option>
                  <option value={"Previous"}>{"Previous"}</option>
                </select>
              </div>
            </div>
            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Status")}
              </div>
              <div>
                {/*  <input style={{width:'14vw',marginRight:"0vw",height:"5vh"}} type="text" placeholder="Select Status" /> */}
            {/* <select
                  style={{ width: "16vw", marginRight: "0vw", height: "5vh" }}
                  name="status"
                  // defaultValue={"Expired"}
                  onChange={handleStatusChange}
                  id="status"
                >
                  <option
                    value="default"
                    selected={query.status == "default" ? true : false}
                  >
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Expired">Expired</option>
                </select>
              </div>
            </div>  */}

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Clear Filters")}
              </div>
              <div style={{ position: "relative" }}>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,

                    width: "10vw",
                    borderRadius: "5px 5px 5px 5px",
                    marginLeft: "1vw",
                    height: "5vh",
                    marginTop: "3px",
                  }}
                  onClick={setDefault}
                  className="filter"
                >
                  <div className="filterDiv">
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="filterDiv">{t("Clear Filters")}</div>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              background: `${
                clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
              }`,
              width: "18vw",
            }}
            className="head"
          >
            {t("ListOfCertificate")}
          </div>
          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-certi"
                style={{
                  gridTemplateColumns:
                    "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="admin-overdue-bottom-table-head">
                  {t("Employee No")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("EmployeeName")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("TrainingProvider")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CertificateName")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("TypeOfTraining")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Company")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("IssuedOn")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("ExpiryDate")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Status")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}

              {details?.map((item, id) => {
                const now = Date.now();

                let issuedOn = Timestamp.fromMillis(
                  item.issuedOn?._seconds * 1000
                )
                  .toDate()
                  .toDateString();
                let expiry = Timestamp.fromMillis(item.expiry?._seconds * 1000)
                  .toDate()
                  .toDateString();
                return (
                  <div
                    className="admin-overdue-bottom-table-cnt-certi"
                    key={id}
                    style={{
                      gridTemplateColumns:
                        "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      {item.employeeId ? item.employeeId : "NA"}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.employeeName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.trainingProvider}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.certificateName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.trainingType}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {companyObj[item?.company]}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {issuedOn}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {expiry}
                    </div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{
                        color: `${
                          item.Status != "Valid" ? "#FD5353" : "#00AE47"
                        }`,
                      }}
                    >
                      {item.status}
                    </div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <div>
                        <select
                          style={{
                            border: "none",
                            backgroundColor: "#F2F2F2",
                            height: "4vh",
                            width: "7vw",
                            padding: "0 1vh",
                            fontWeight: "700",
                            color: "#717579",
                            borderRadius: "6px",
                          }}
                          onChange={(e) => {
                            handleAction(e, item);
                          }}
                        >
                          <option selected>{t("Action")}</option>
                          <option value="Download">{t("Download")}</option>
                          {/* <option value="Remind">{t("Remind")}</option>
                          <option value="Review">{t("Review")}</option>
                          <option value="Retake">{t("Retake")}</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => {
                      if (currentDataUrl.prev) {
                        clickhandler(currentDataUrl.prev);
                      }
                    }}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentDataUrl?.c_page} of {currentDataUrl?.t_page}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => {
                      if (currentDataUrl.next) {
                        clickhandler(currentDataUrl.next);
                      }
                    }}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      // changePageLimit(e.target.value);

                      setPageSize(e.target.value);
                    }}
                  >
                    {/* <option value={10}>10</option>
                    <option value={25}>25</option> */}
                    <option value={50}>50</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="admin-certificate-main-cnt">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Employee name</th>
                <th>Training Provider</th>
                <th>Certificate name</th>
                <th>Type of Training</th>
                <th>Issued On</th>
                <th>Expiry</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Details.map((details, i) => {
                return (
                  <tr key={i}>
                    <td>{details.employeeID}</td>
                    <td>{details.employeeName}</td>
                    <td>{details.trainingProvider}</td>
                    <td>{details.certificateName}</td>
                    <td>{details.trainingType}</td>
                    <td>{details.issuedOn}</td>
                    <td>{details.expiry}</td>
                    <td>{properStatus(details.expiry)}</td>
                    <td>
                      <select onChange={(e) => handleOnChange(e.target.value)}>
                        <option>Actions</option>
                        <option value={["renew", details.id]}>Renew</option>
                        <option
                          value={["download", details.certificateDownload]}
                        >
                          Download Certificate
                        </option>
                        <option
                          value={[
                            "notify",
                            details.employeeID,
                            details.certificateName,
                          ]}
                        >
                          Notify
                        </option>
                        <option>Archive</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  );
}

export default Certificates;
