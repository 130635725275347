import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Papa, { parse } from "papaparse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../config";
import { getCookie } from "../../../../Cookies";
import axios from "axios";

const BulkDialog = ({ showBulk, handleClose }) => {
  const bulkUserCreation = httpsCallable(functions, "bulkUserCreation");
  const [showHistory, setShowHistory] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setJsonData([]);
  }, [showBulk]);
  const uploadBulkUser = () => {
    setLoading(true);

    let configLesson = {
      method: "post",
      // params: { uid: item.userId },
      url: `${IP}/adminUserProfile/bulkUser`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        csvArray: jsonData,
      }),
    };
    axios(configLesson)
      .then((res) => {
        setLoading(false);
        setJsonData(res.data);
        //console.log(res.data);
      })
      .catch((err) => {
        // console.log(err.response.data.error);
        if (err.response.data.message) {
          alert(err.response.data.message);
        }
        setLoading(false);
      });
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];
    //console.log(file);
    const name = file.name;
    if (name.split(".")[1] != "csv") {
      alert("Not proper format");
      e.target.value = "";
      return;
    }
    let temp = [];
    let text = await file.text();
    const parsedData = Papa.parse(text);
    //console.log(text, parsedData.data);
    for (let i = 1; i < parsedData.data.length; i++) {
      //console.log(i)
      let data = {
        email: "",
      };
      parsedData.data[0].forEach((item, id) => {
        data[item] = parsedData.data[i][id];
      });

      temp.push({
        ...data,

        status: "TBD",
        role: "USER",
      }); // default role by bulk add is user
    }
    //console.log(temp);
    setJsonData(temp);
  };

  return (
    <Dialog
      open={showBulk}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Bulk User Creation"}
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">Bulk User Create</DialogContentText> */}
        <div>
          <p>Upload CSV File</p> <br></br>
          <input type={"file"} accept={"text/csv"} onChange={handleFile} />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {jsonData?.length != 0 && (
            <div className="bulkUser-row">
              <div>{"Name"}</div>
              <div>{"Email"}</div>
              <div>{"Company"}</div>
              <div>{"EmployeeId"}</div>
              <div>{"JoiningDate"}</div>
              <div>{"JobTitle"}</div>
              <div>{"Department"}</div>
              <div>{"Status"}</div>
            </div>
          )}
          {jsonData.map((item) => {
            return (
              <div className="bulkUser-row">
                <div>{item.name}</div>
                <div>{item.email}</div>
                <div>{item.companyId}</div>
                <div>{item.employeeId}</div>
                <div>{item.joiningDate}</div>
                <div>{item.jobTitle}</div>
                <div>{item.departmentId}</div>
                <div>{item.error ? item.error : item.status}</div>
              </div>
            );
          })}
        </div>
        <History
          showHistory={showHistory}
          handleClose={() => setShowHistory(false)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/alghaith-account-production.appspot.com/o/Book2(1)(1).csv?alt=media&token=fe4d14f4-8b13-4a4a-b640-c0c5826a83d6&_gl=1*1ndd4nv*_ga*MTM5NzIwMjI0NC4xNjc0MTIwNjQz*_ga_CW55HF8NVT*MTY5OTM1OTI4Ni4xNzYuMS4xNjk5MzU5NDA4LjI3LjAuMA..",
              "_blank"
            );
          }}
        >
          download_sample_csv file
        </Button>
        <Button disabled={loading} onClick={() => setShowHistory(true)}>
          {"Show History"}
        </Button>
        <Button
          disabled={loading ? true : jsonData.length == 0 ? true : false}
          onClick={uploadBulkUser}
        >
          {loading ? "Loading..." : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>

    // <Modal show={showDialog}>
    //   <Modal.Header>
    //     <Modal.Title>Warning</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <b>There are some changes?</b>
    //     <br /> Are you sure you want to navigate!!!!
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="primary" onClick={cancelNavigation}>
    //       No
    //     </Button>
    //     <Button variant="danger" onClick={confirmNavigation}>
    //       Yes
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};
export default BulkDialog;

const History = ({ showHistory, handleClose }) => {
  const fetchBulkHistory = httpsCallable(functions, "fetchBulkHistory");
  const [histortData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);

  const styleObj = { display: "grid", gridTemplateColumns: "2fr 1fr 1fr" };
  useEffect(() => {
    if (!showHistory) return;
    setLoading(true);
    let configLesson = {
      method: "get",
      // params: { uid: item.userId },
      url: `${IP}/adminUserProfile/bulkUser`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configLesson).then((res) => {
      setHistoryData(res.data);
      setLoading(false);
    });
  }, [showHistory]);
  return (
    <Dialog
      open={showHistory}
      onClose={() => {
        // if (loading) return;
        handleClose();
      }}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Bulk User Creation History"}
      </DialogTitle>
      <DialogContent>
        {loading && "loading..."}
        {!loading && (
          <div style={styleObj}>
            <div>Date</div>
            <div>Created</div>
            <div>Errors</div>
          </div>
        )}
        {!loading &&
          histortData?.map((ele) => {
            let createdOn = new Date(ele.createdOn?._seconds * 1000)
              .toISOString()
              .split("T")[0];
            return (
              <div style={styleObj}>
                <div>{createdOn}</div>
                <div>{ele.created}</div>
                <div>{ele.failed}</div>
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};
