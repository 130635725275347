import { React, useEffect, useState } from "react";
import $ from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ExpandMoreSharp } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { DeleteSVg, ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

const Item = ({ itemsData, topicId = "", courseId = "", lessonId = "" }) => {
  const storage = getStorage();
  var docURL = [];
  var files = [];
  const [itemName, setItemName] = useState("");
  const initialList = [];
  const [list, setList] = useState(initialList);
  const [editable, setEditable] = useState(false);
  const createItem = httpsCallable(functions, "createItem");
  const uploadPdf = httpsCallable(functions, "uploadPdf");
  const addLessonItemOrder = httpsCallable(functions, "addLessonItemOrder");
  const addTopicItemOrder = httpsCallable(functions, "addTopicItemOrder");
  const deleteItem = httpsCallable(functions, "deleteItem");

  const [deleteItemId, setDeleteItemId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  // deleteItem
  const [itemDetails, setItemDetails] = useState({
    itemName: "",
    pdfBase64: "",
    videoLink: "",
    htmlLink: "",
    externalLink: "",
    audioLink: "",
  });

  const [itemId, setItemId] = useState("");
  const [itemType, setItemType] = useState("pdf");

  const [pdfLink, setPDFLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [htmlLink, setHTMLLink] = useState("");
  const [extLink, setExtLink] = useState("");
  const [audioLink, setAudioLink] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    itemName: "",
    pdfBase64: "",
    videoLink: "",

    htmlLink: "",
  });
  const getItemInfo = httpsCallable(functions, "getItemInfo");

  useEffect(() => {
    if (!itemsData) return;
    (async () => {
      for (let i = 0; i < itemsData.length; i++) {
        let configCourse = {
          method: "get",
          params: { itemId: itemsData[i] },
          url: `${IP}/userCourseDetails/item`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataCourse",
        };
        let itemInfo = await axios(configCourse);

        //console.log("item", itemInfo);
        setList((prev) =>
          prev.concat({
            id: itemInfo.data?.itemId,
            name: itemInfo.data?.itemName,
            type: itemInfo.data?.itemType,
            videoURL: itemInfo.data?.videoURL,
            pdfURL: itemInfo.data?.pdfUrl,
            htmlURL: itemInfo.data?.htmlURL,
            externalURL: itemInfo.data?.externalURL,
            audioURL: itemInfo.data?.audioURL,
          })
        );
      }
    })();
  }, [itemsData]);

  const handleInput = (event) => {
    const { value, name } = event.target;
    setItemDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fileHandler = (event) => {
    const { files } = event.target;

    let filenameType = event.target.value.split(".");

    //console.log( filenameType[filenameType.length-1])

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "pdf") {
    } else {
      alert("Only PDF File accepted");
      event.target.value = null;
      return;
    }

    let rfilesize = Number(event.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log( rfilesize)
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      //  setImgFileName("")
      event.target.value = null;
      return;
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        setItemDetails((prev) => ({ ...prev, pdfBase64: reader.result }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  function checkForm() {
    if (
      document.getElementById(topicId + courseId + lessonId + "itemName")
        .value === ""
    ) {
      setCheckErrorInForm((prev) => ({ ...prev, itemName: "error" }));
      return "itemName";
    }
    if (itemType === "pdf" && itemDetails.pdfBase64 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, pdfBase64: "error" }));
      return "pdfLink";
    }
    if (itemType === "video" && itemDetails.videoLink === "") {
      setCheckErrorInForm((prev) => ({ ...prev, videoLink: "error" }));
      return "videoLink";
    }
    if (itemType === "html" && itemDetails.htmlLink === "") {
      setCheckErrorInForm((prev) => ({ ...prev, htmlLink: "error" }));
      return "htmlLink";
    }
    // if (itemType === "external" && itemDetails.externalLink === "") return "externalLink";
    return "allIsWell";
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (topicId != "") {
      let configCourse = {
        method: "post",
        // params: { itemId: itemsData[i] },
        url: `${IP}/courseBuilder/addTopicItemOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          topicId: topicId,
          items: orderList,
          edit: true,
        }),
      };
      let itemInfo = await axios(configCourse);
    } else if (lessonId != "") {
      let configCourse = {
        method: "post",
        // params: { itemId: itemsData[i] },
        url: `${IP}/courseBuilder/addLessonItemOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          lessonId: lessonId,
          items: orderList,
          edit: true,
        }),
      };
      let itemInfo = await axios(configCourse);
    }
  }
  const toggleForm = (e) => {
    document.getElementById(
      topicId + courseId + lessonId + "itemForm"
    ).style.display =
      document.getElementById(topicId + courseId + lessonId + "itemForm").style
        .display == "none"
        ? "block"
        : "none";
  };
  const addItem = async () => {
    let check = checkForm();

    let nsn = itemDetails.itemName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }

    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);
    //console.log("called Add");
    docURL = [];
    files = [];
    setCheckErrorInForm({
      itemName: "",
      pdfBase64: "",
      videoLink: "",

      htmlLink: "",
    });
    const newList = list.concat({
      id: itemId,
      name: itemDetails.itemName,
      type: itemType,
      videoURL: itemDetails.videoLink,
      pdfURL: itemDetails.pdfLink,
      htmlURL: itemDetails.htmlLink,
      externalURL: itemDetails.externalLink,
      audioURL: itemDetails.audioLink,
    });
    setList(newList);
    saveOrder(newList);

    let configCourse = {
      method: "post",
      // params: { itemId: itemsData[i] },
      url: `${IP}/courseBuilder/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        itemId: itemId,
        courseAssociated: courseId,
        lessonAssociated: lessonId,
        topicAssociated: topicId,
        audioURL: itemDetails.audioLink,
        externalURL: itemDetails.externalLink,
        htmlURL: itemDetails.htmlLink,
        pdfURL: itemDetails.pdfLink,
        videoURL: itemDetails.videoLink,
        itemName: itemDetails.itemName,
        itemType: itemType,
        created: new Date().toString(),
      }),
    };
    let itemInfo = await axios(configCourse);

    if (itemType == "pdf") {
      let configCourse = {
        method: "patch",
        // params: { itemId: itemsData[i] },
        url: `${IP}/courseBuilder/item`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          itemId: itemId,
          pdf: itemDetails.pdfBase64,
        }),
      };
      let itemInfo = await axios(configCourse);
    }

    setItemDetails({
      itemName: "",
      pdfBase64: "",
      videoLink: "",
      htmlLink: "",
      externalLink: "",
      audioLink: "",
    });
    setItemId("");
    setItemType("pdf");

    toggleForm();
  };

  const upload = (pdf, i, link, referDoc) => {
    const metadata = {
      contentType: pdf.type,
    };
    const fileName = pdf.name;
    const storageRef = ref(
      storage,
      link + i + fileName.substring(fileName.lastIndexOf("."))
    );
    const uploadTask = uploadBytesResumable(storageRef, pdf, metadata);
    files.push(lessonId + fileName.substring(fileName.lastIndexOf(".")));
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            //console.log("Upload is paused");
            break;
          case "running":
            //console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);
          docURL.push(downloadURL);
          referDoc.update({ documentURL: docURL });
        });
      }
    );
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  const itemToggle = () => {
    var type = document.getElementById(
      topicId + courseId + lessonId + "itemType"
    ).value;
    setItemType(type);
    if (type === "pdf") {
      document.getElementById(
        topicId + courseId + lessonId + "videoLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "pdfLinkBlock"
      ).style.display = "";
      document.getElementById(
        topicId + courseId + lessonId + "extLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "htmlLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "audioLinkBlock"
      ).style.display = "";
    } else if (type === "video") {
      document.getElementById(
        topicId + courseId + lessonId + "videoLinkBlock"
      ).style.display = "";
      document.getElementById(
        topicId + courseId + lessonId + "pdfLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "extLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "htmlLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "audioLinkBlock"
      ).style.display = "none";
    } else if (type === "external") {
      document.getElementById(
        topicId + courseId + lessonId + "videoLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "pdfLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "extLinkBlock"
      ).style.display = "";
      document.getElementById(
        topicId + courseId + lessonId + "htmlLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "audioLinkBlock"
      ).style.display = "";
    } else if (type === "html") {
      document.getElementById(
        topicId + courseId + lessonId + "videoLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "pdfLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "extLinkBlock"
      ).style.display = "none";
      document.getElementById(
        topicId + courseId + lessonId + "htmlLinkBlock"
      ).style.display = "";
      document.getElementById(
        topicId + courseId + lessonId + "audioLinkBlock"
      ).style.display = "";
    }
  };
  function edit(item) {
    setEditable(true);
    setItemType(item.type);
    setItemName(item.name);
    setItemId(item.id);
    setVideoLink(item.videoURL);
    setExtLink(item.externalURL);
    setHTMLLink(item.htmlURL);
    setAudioLink(item.audioURL);
    setPDFLink(item.pdfURL);
    document.getElementById(
      topicId + courseId + lessonId + "itemForm"
    ).style.display = "";
    document.getElementById(topicId + courseId + lessonId + "itemType").value =
      item.type;
    document.getElementById(topicId + courseId + lessonId + "itemName").value =
      item.name;
    document.getElementById(topicId + courseId + lessonId + "videoLink").value =
      item.videoURL;
    document.getElementById(topicId + courseId + lessonId + "htmlLink").value =
      item.htmlURL;
    document.getElementById(topicId + courseId + lessonId + "extLink").value =
      item.externalURL;
    document.getElementById(topicId + courseId + lessonId + "pdfLink").value =
      item.pdfURL;
    document.getElementById(topicId + courseId + lessonId + "audioLink").value =
      item.audioURL;
  }

  const updateItem = () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    docURL = [];
    files = [];

    for (let i = 0; i < list.length; i++) {
      if (itemId === list[i].id) {
        list[i] = {
          id: itemId,
          name: itemName,
          type: itemType,
          videoURL: videoLink,
          pdfURL: pdfLink,
          htmlURL: htmlLink,
          externalURL: extLink,
          audioURL: audioLink,
        };
      }
    }
    setList(list);

    document.getElementById(
      topicId + courseId + lessonId + "itemForm"
    ).style.display = "none";
    setEditable(false);
  };

  async function del(item) {
    setopenLessonPopUp(true);
    setDeleteItemId(item);
  }

  const delfunction = async (item) => {
    let configCourse = {
      method: "delete",
      // params: { itemId: itemsData[i] },
      url: `${IP}/courseBuilder/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        itemId: item.id,
      }),
    };
    let itemInfo = await axios(configCourse);

    alert("Deleted " + item.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (item.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteItemId}
        itemName={"Item"}
      />
      <section className="section" style={{ margin: "0px", width: "100%" }}>
        <button
          className="addItem-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add Item
        </button>
        <div
          className="addItem-container"
          id={topicId + courseId + lessonId + "itemForm"}
          style={{ display: "none" }}
        >
          <div className="addItem-heading">Item</div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Item Name *</div>
            <input
              type="text"
              className="addItem-inputBar"
              id={topicId + courseId + lessonId + "itemName"}
              name="itemName"
              value={itemDetails.itemName}
              style={{
                border: `${
                  checkErrorInForm.itemName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, itemName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setItemId(value + "_" + time);
                }
              }}
            />
            {checkErrorInForm.itemName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Item Name
              </span>
            )}
          </div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Type *</div>
            <select
              className="addItem-inputSelect"
              id={topicId + courseId + lessonId + "itemType"}
              value={itemType}
              name="itemType"
              onChange={(e) => setItemType(e.target.value)}
            >
              <option value="pdf">PDF</option>
              <option value="video">Video</option>
              <option value="html">HTML</option>
              {/* <option value="external">External</option> */}
            </select>
          </div>
          {/* {itemType} */}
          {itemType == "video" && (
            <div
              className="addItem-row"
              id={topicId + courseId + lessonId + "videoLinkBlock"}
            >
              <div className="addItem-inputLabel">Video Link *</div>
              <textarea
                name="videoLink"
                value={itemDetails.videoLink}
                style={{
                  border: `${
                    checkErrorInForm.videoLink == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                type="text"
                className="addItem-textarea"
                id={topicId + courseId + lessonId + "videoLink"}
                onChange={(e) => {
                  handleInput(e);
                  setCheckErrorInForm((prev) => ({ ...prev, videoLink: "" }));
                }}
              />
              {checkErrorInForm.videoLink == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Video Link
                </span>
              )}
            </div>
          )}
          {itemType == "pdf" && (
            <div
              className="addItem-row"
              id={topicId + courseId + lessonId + "pdfLinkBlock"}
            >
              <div className="addItem-inputLabel">PDF File *</div>
              <input
                className="addItem-inputLabel"
                type={"file"}
                style={{
                  border: `${
                    checkErrorInForm.pdfBase64 == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                accept="application/pdf"
                onChange={(event) => {
                  fileHandler(event);
                  setCheckErrorInForm((prev) => ({ ...prev, pdfBase64: "" }));
                }}
              />
              {checkErrorInForm.pdfBase64 == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  Upload PDF File
                </span>
              )}
            </div>
          )}
          {/* {itemType == "external" && (
            <div className="addItem-row" id={topicId + courseId + lessonId + "extLinkBlock"}>
              <div className="addItem-inputLabel">External Link</div>
              <textarea
                type="text"
                className="addItem-inputBar"
                value={itemDetails.externalLink}
                name="externalLink"
                id={topicId + courseId + lessonId + "extLink"}
                onChange={handleInput}
              />
            </div>
          )} */}
          {itemType == "html" && (
            <div
              className="addItem-row"
              id={topicId + courseId + lessonId + "htmlLinkBlock"}
            >
              <div className="addItem-inputLabel">HTML Link *</div>
              <textarea
                type="text"
                className="addItem-inputBar"
                value={itemDetails.htmlLink}
                style={{
                  border: `${
                    checkErrorInForm.htmlLink == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                name="htmlLink"
                id={topicId + courseId + lessonId + "htmlLink"}
                onChange={(e) => {
                  handleInput(e);
                  setCheckErrorInForm((prev) => ({ ...prev, htmlLink: "" }));
                }}
              />
              {checkErrorInForm.htmlLink == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  Upload Html Link
                </span>
              )}
            </div>
          )}
          {itemType != "video" && (
            <div
              className="addItem-row"
              id={topicId + courseId + lessonId + "audioLinkBlock"}
            >
              <div className="addItem-inputLabel">Audio Link</div>
              <textarea
                type="text"
                className="addItem-inputBar"
                value={itemDetails.audioLink}
                name="audioLink"
                id={topicId + courseId + lessonId + "audioLink"}
                onChange={handleInput}
              />
            </div>
          )}
          {checkFailed && <p style={{ color: "ref" }}>Fill all the fields.</p>}
          <div className="addItem-row">
            <button
              className="addItem-btn"
              style={{ marginLeft: "auto" }}
              onClick={addItem}
            >
              Save Item
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="itemList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="draggableItem-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#004577",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              style={{ float: "right", margin: "20px" }}
                              onClick={() => del(item)}
                            >
                              <DeleteSVg size={28} />
                            </div>
                            {/* <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            /> */}
                            <p>Id- {item.id}</p>
                            {topicId != "" ? (
                              <p>Associated Topic- {topicId}</p>
                            ) : (
                              <></>
                            )}
                            {courseId != "" ? (
                              <p>Associated Course- {courseId}</p>
                            ) : (
                              <></>
                            )}
                            {lessonId != "" ? (
                              <p>Associated Lesson- {lessonId}</p>
                            ) : (
                              <></>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default Item;
